/* eslint-disable jsx-a11y/anchor-is-valid */
import {Tabs, TabsProps} from 'antd'
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useListLanguage from '../../../../helpers/hooks/language/useListLanguage'
import useActionPartner from '../../../../helpers/hooks/partner/useActionPartner'
import {useLang} from '../../../../i18n/MetaCrewi18n'
import {ButtonEdit} from '../../buttons/edit/ButtonEdit'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../form/CustomSelect/CustomSelect'
import {Switch} from '../../form/Switch/Switch'
import {Tables} from '../../tables/Tables'
import {ConfirmModal} from '../ConfirmModal/ConfirmModal'
import {IPartner} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'
import {PartnerCategoryFormModal} from './PartnerCategoryFormModal'
import {AdsTab} from './components/AdsTab'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  handleClose: () => void
  handleSubmit?: (values: IPartner, id: string | undefined) => void
  refreshData?: () => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}
const OPTIONS_TYPE: Option[] = [
  {
    value: 'firebase',
    label: 'Firebase',
  },
  {
    value: 'onesignal',
    label: 'Onesignal',
  },
]

const OPTIONS_ADS_TYPE: Option[] = [
  {
    value: 'admob',
    label: 'admob',
  },
  {
    value: 'meta',
    label: 'meta',
  },
  {
    value: 'unity',
    label: 'unity',
  },
]

const PartnerFormModal: React.FC<Props> = ({
  show = false,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault,
  handleClose,
  handleSubmit,
  refreshData,
}) => {
  const intl = useIntl()
  const lang = useLang()
  const {listOptionLanguage} = useListLanguage({get_all: 1})
  const {
    updatePartnerCategory,
    deletePartnerCategory,
    updatePartnerCategoryStatus,
    updatePartnerCategoryDefault,
    updatePartnerPressStatus,
  } = useActionPartner()
  const [isLoadingUpdateCategory, setIsLoadingUpdateCategory] = useState<boolean>(false)
  const [isShowPartnerCategoryFormModal, setIsShowPartnerCategoryFormModal] = useState(false)
  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [idKeyToDelete, setIdKeyToDelete] = useState<string>('')
  const [categoryFocus, setCategoryFocus] = useState<any>()
  const animatedComponents = makeAnimated()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    application_id: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    app_id: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    app_token: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    // type: Yup.object({
    //   label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    //   value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    // })
    //   .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
    //   .test(
    //     'validate-select-option',
    //     `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
    //     (value: any) => {
    //       if (typeof value !== 'object' || !value.label || !value.value) {
    //         return false
    //       }
    //       return true
    //     }
    //   ),
    // status: Yup.string(),
  })

  const type = initialValuesDefault?.type
    ? OPTIONS_TYPE?.filter((item) => item?.value === initialValuesDefault?.type)[0]
    : OPTIONS_TYPE[0]

  const settings = initialValuesDefault?.settings
    ? JSON.parse(initialValuesDefault?.settings?.settings)
    : []

  const ads_type = settings?.ads_type || ''
  // eslint-disable-next-line
  const hide_channel_select = settings?.hide_channel_select == '1' ? 'true' : 'false'
  const initialValues: IPartner = {
    name: initialValuesDefault?.name,
    application_id: initialValuesDefault?.application_id,
    app_id: initialValuesDefault?.app_id,
    app_token: initialValuesDefault?.app_token,
    type: type,
    manager: initialValuesDefault?.manager,
    contact: initialValuesDefault?.contact,
    email: initialValuesDefault?.email,
    ads_type: ads_type,
    hide_channel_select: hide_channel_select,
    // status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true'
  }

  const onSubmit = (values: IPartner) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }

  const updateStatus = async (id: string, status: boolean) => {
    const {code, error} = await updatePartnerCategoryStatus({
      category_id: id,
      partner_id: initialValuesDefault?.id,
      status: status ? 1 : 0,
    })

    if (code === 1) {
      refreshData && refreshData()
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const updateStatusPress = async (id: string, status: boolean) => {
    const {code, error} = await updatePartnerPressStatus({
      channel_id: id,
      partner_id: initialValuesDefault?.id,
      status: status ? 1 : 0,
    })

    if (code === 1) {
      refreshData && refreshData()
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const updateDefault = async (id: string, status: boolean) => {
    const {code, error} = await updatePartnerCategoryDefault({
      category_id: id,
      partner_id: initialValuesDefault?.id,
      default: status ? 1 : 0,
    })

    if (code === 1) {
      refreshData && refreshData()
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const columns = [
    {
      label: 'status',
      title: intl.formatMessage({id: 'COL_CATEGORY_STATUS'}),
      width: '100px',
      className: 'min-w-100px text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        return (
          <Switch
            id={row?.id}
            isActive={row?.status}
            onChangeActive={(idItem, isActive) => updateStatus(idItem, isActive)}
          />
        )
      },
    },
    {
      label: 'default',
      title: intl.formatMessage({id: 'DEFAULT'}),
      width: '100px',
      className: 'min-w-100px text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        return (
          <Switch
            id={row?.id}
            isActive={row?.default}
            onChangeActive={(idItem, isActive) => updateDefault(idItem, isActive)}
          />
        )
      },
    },
    {
      label: 'name',
      title: intl.formatMessage({id: 'COL_CATEGORY_TITLE'}),
      className: 'min-w-100px  text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        const text = lang === 'en' ? row?.translations?.en : row?.translations?.ko
        return <div>{text}</div>
      },
    },
    {
      label: 'type',
      title: intl.formatMessage({id: 'COL_CATEGORY_TYPE_TITLE'}),
      className: 'min-w-200px  text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        const text = row?.type
        return <div>{text ? intl.formatMessage({id: row?.type?.toUpperCase()}) : ''}</div>
      },
    },
    {
      label: 'custom_name',
      title: intl.formatMessage({id: 'COL_CATEGORY_DISPLAY_TITLE'}),
      className: 'min-w-200px  text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        const text = lang === 'en' ? row?.custom_name?.en : row?.custom_name?.ko
        return <div>{text}</div>
      },
      // click: (row: any) => handleOpenCategoryFormModal(row),
    },

    {
      label: '',
      title: '',
      width: '50px',
      className: 'min-w-50px text-center',
      classNameComponent: 'text-center',
      component: (row: any) => (
        <ButtonEdit
          data={row}
          onClickButton={() => {
            setIsShowPartnerCategoryFormModal(true)
            setCategoryFocus(row)
          }}
        />
      ),
    },
  ]

  const columnsPress = [
    {
      label: 'status',
      title: intl.formatMessage({id: 'COL_CATEGORY_STATUS'}),
      className: 'min-w-100px text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        return (
          <Switch
            id={row?.id}
            isActive={row?.status}
            onChangeActive={(idItem, isActive) => updateStatusPress(idItem, isActive)}
          />
        )
      },
    },

    {
      label: 'name',
      title: intl.formatMessage({id: 'PRESS'}),
      className: 'text-center',
      classNameComponent: 'text-center',
      component: (row: any) => {
        const text = row?.name
        return <div>{text}</div>
      },
    },
  ]
  // eslint-disable-next-line

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className='fs-5 fw-semibold'>{intl.formatMessage({id: 'INFORMATION'})}</div>,
      children: (
        <div className='px-4'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IPartner>) => (
              <Form className='p-6'>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'MEDIA_ID'})}
                    name='application_id'
                    type='text'
                    placeholder={intl.formatMessage({id: 'MEDIA_ID'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'MEDIA'})}
                    name='name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'MEDIA'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>

                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'MANAGER'})}
                    name='manager'
                    type='text'
                    placeholder={intl.formatMessage({id: 'MANAGER'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'CONTACT'})}
                    name='contact'
                    type='text'
                    placeholder={intl.formatMessage({id: 'CONTACT'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'EMAIL'})}
                    name='email'
                    type='text'
                    placeholder={intl.formatMessage({id: 'EMAIL'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_APP_ID'})}
                    name='app_id'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_APP_ID'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_APP_TOKEN'})}
                    name='app_token'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_APP_TOKEN'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_TYPE'})}
                    name='type'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={type || ''}
                    options={OPTIONS_TYPE}
                    component={CustomSelect}
                    isMulti={false}
                    isFullWidth={true}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'ADS_TYPE'})}
                    placeholder={intl.formatMessage({id: 'ADS_TYPE'})}
                    name='ads_type'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={ads_type || ''}
                    options={OPTIONS_ADS_TYPE}
                    component={CustomSelect}
                    isMulti={false}
                    isFullWidth={true}
                    isObjOption
                    isRequired={false}
                  />
                </div>

                <div
                  className='row mb-7'
                  style={{minHeight: 44, display: 'flex', alignItems: 'center'}}
                >
                  <RadioGroup
                    id='hide_channel_select'
                    label={intl.formatMessage({id: 'FORM_HIDE_CHANNEL_SELECT'})}
                    value={values.hide_channel_select}
                    error={errors.hide_channel_select}
                    touched={touched.hide_channel_select}
                  >
                    <Field
                      component={Radio}
                      name='hide_channel_select'
                      id='hide_channel_select0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='hide_channel_select'
                      id='hide_channel_select1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>

                <div>
                  <div className='text-end'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ),
    },
    {
      key: '2',
      label: <div className='fs-5 fw-semibold'>{intl.formatMessage({id: 'PRESS'})}</div>,
      children: (
        <div className='px-4'>
          <Tables
            className='table-background-gray'
            columns={columnsPress}
            isLoading={false}
            data={initialValuesDefault?.channels || []}
            // pagination={data?.meta}
            isShowPagination={false}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: <div className='fs-5 fw-semibold'>{intl.formatMessage({id: 'CATEGORIES'})}</div>,
      children: (
        <div className='px-4'>
          <Tables
            className='table-background-gray'
            columns={columns}
            isLoading={false}
            data={initialValuesDefault?.categories || []}
            // pagination={data?.meta}
            isShowPagination={false}
          />
        </div>
      ),
    },
    {
      key: '4',
      label: <div className='fs-5 fw-semibold'>{intl.formatMessage({id: 'ADS_SETTINGS'})}</div>,
      children: <AdsTab initialValuesDefault={initialValuesDefault} />,
    },
  ]

  const handleUpdateMediaCategory = async (params: any) => {
    setIsLoadingUpdateCategory((_prevState: boolean) => true)

    const {code, error} = await updatePartnerCategory(params)
    if (code === 1) {
      refreshData && refreshData()
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)

      setIsShowPartnerCategoryFormModal((_prevState: boolean) => false)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
    setIsLoadingUpdateCategory((_prevState: boolean) => false)
  }

  const handleShowConfirmDelete = (idKey: string) => {
    setIdKeyToDelete((_prevState: string) => idKey)
    setIsShowConfirm((_prevState: boolean) => true)
  }
  const handleConfirmDelete = async () => {
    const params = {category_id: idKeyToDelete, partner_id: initialValuesDefault?.id}

    if (idKeyToDelete) {
      setIsShowConfirm((_prevState: boolean) => false)
      setIsLoadingConfirm((_prevState: boolean) => true)
      const {code, error} = await deletePartnerCategory(params)
      if (code === 1) {
        toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
        refreshData && refreshData()
        setIdKeyToDelete((_prevState: string) => '')
        setIsShowConfirm((_prevState: boolean) => false)
        setIsShowPartnerCategoryFormModal((_prevState: boolean) => false)
      } else {
        for (const element of error || []) {
          toast(element?.message || '')
        }
      }
      setIsLoadingConfirm((_prevState: boolean) => false)
    }
  }
  return (
    <ModalWrapper show={show} dialogClassName='modal-dialog-centered mw-900px'>
      <div className='container-xxl'>
        <div className='modal-header z-index-1 row position-sticky top-0 bg-white'>
          <div className='col-10 col-lg-10 col-md-10'>
            <h1 className='mt-2'>
              {intl.formatMessage({
                id: initialValuesDefault?.id ? 'TITLE_UPDATE_MEDIA' : 'TITLE_ADD_MEDIA',
              })}
              {initialValuesDefault?.name && (
                <span className='fs-8 text-primary ms-2'>{`( ${initialValuesDefault?.name} )`}</span>
              )}
            </h1>
          </div>
          <div className='col-2 col-lg-2 col-md-2 text-end'>
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            {/* end::Close */}
          </div>
        </div>
        <div className='modal-body pt-0 px-0'>
          {/* begin::Search */}
          {initialValuesDefault?.id ? (
            <Tabs
              defaultActiveKey={activeTab}
              onChange={() => setActiveTab(activeTab === '1' ? '2' : '1')}
              items={items}
              className='padding-list-tab'
            />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, values}: FormikProps<IPartner>) => (
                <Form className='p-6'>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'MEDIA_ID'})}
                      name='application_id'
                      type='text'
                      placeholder={intl.formatMessage({id: 'MEDIA_ID'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'MEDIA'})}
                      name='name'
                      type='text'
                      placeholder={intl.formatMessage({id: 'MEDIA'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                    />
                  </div>

                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'MANAGER'})}
                      name='manager'
                      type='text'
                      placeholder={intl.formatMessage({id: 'MANAGER'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                      isRequired={false}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'CONTACT'})}
                      name='contact'
                      type='text'
                      placeholder={intl.formatMessage({id: 'CONTACT'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                      isRequired={false}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'EMAIL'})}
                      name='email'
                      type='text'
                      placeholder={intl.formatMessage({id: 'EMAIL'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                      isRequired={false}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_APP_ID'})}
                      name='app_id'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_APP_ID'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_APP_TOKEN'})}
                      name='app_token'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_APP_TOKEN'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      isFullWidth={true}
                      component={CustomInput}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_TYPE'})}
                      name='type'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={type || ''}
                      options={OPTIONS_TYPE}
                      component={CustomSelect}
                      isMulti={false}
                      isFullWidth={true}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'ADS_TYPE'})}
                      placeholder={intl.formatMessage({id: 'ADS_TYPE'})}
                      name='ads_type'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={ads_type || ''}
                      options={OPTIONS_ADS_TYPE}
                      component={CustomSelect}
                      isMulti={false}
                      isFullWidth={true}
                      isObjOption
                      isRequired={false}
                    />
                  </div>

                  <div
                    className='row mb-7'
                    style={{minHeight: 44, display: 'flex', alignItems: 'center'}}
                  >
                    <RadioGroup
                      id='hide_channel_select'
                      label={intl.formatMessage({id: 'FORM_HIDE_CHANNEL_SELECT'})}
                      value={values.hide_channel_select}
                      error={errors.hide_channel_select}
                      touched={touched.hide_channel_select}
                    >
                      <Field
                        component={Radio}
                        name='hide_channel_select'
                        id='hide_channel_select0'
                        valueSet='false'
                        label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                      />
                      <Field
                        component={Radio}
                        name='hide_channel_select'
                        id='hide_channel_select1'
                        valueSet='true'
                        label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                      />
                    </RadioGroup>
                  </div>

                  <div>
                    <div className='text-end'>
                      {isShowButton && (
                        <ButtonPrimary
                          text={intl.formatMessage({
                            id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                          })}
                          type='submit'
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <PartnerCategoryFormModal
          show={isShowPartnerCategoryFormModal}
          handleClose={() => setIsShowPartnerCategoryFormModal(false)}
          listOptionLanguage={listOptionLanguage}
          initialValuesDefault={initialValuesDefault}
          handleSubmit={handleUpdateMediaCategory}
          categoryFocus={categoryFocus}
          isLoading={isLoadingUpdateCategory}
          handleShowConfirmDelete={handleShowConfirmDelete}
          isLoadingReset={isLoadingConfirm}
        />
      </div>
      <ConfirmModal
        isLoading={isLoadingConfirm}
        show={isShowConfirm}
        title={intl.formatMessage({id: 'TEXT_RESET'})}
        content={intl.formatMessage({id: 'TEXT_CONFIRM_RESET'})}
        handleClose={() => setIsShowConfirm(false)}
        handleYes={handleConfirmDelete}
        textButtonAction={intl.formatMessage({id: 'BUTTON_RESET'})}
      />
    </ModalWrapper>
  )
}

export {PartnerFormModal}
