import { apiService } from '../../helpers/axios';
import { CS, PostCS } from '../models/_cs';
import { PaginationResponse, ParamsRequestGetList, ResponseApi, ResponseData } from '../models/_public';

const BASE_URL_UPLOAD_FILE = process.env.REACT_APP_BASE_URL_UPLOAD_FILE || ''

export function fetchCS(params: ParamsRequestGetList): Promise<ResponseApi<ResponseData<CS[]> & PaginationResponse>> {
  return apiService.get('/inquiries', { params })
}

export function fetchDetailCS(id: string, params: any = {}): Promise<ResponseApi<CS>> {
  return apiService.get(`/inquiries/${id}`, { params })
}

export function postCreateCS(dataForm: Partial<PostCS>): Promise<ResponseApi<CS>> {
  const formData = new FormData();
  formData.append('email_respond', dataForm?.email_respond || '');
  formData.append('response', dataForm?.response ?? '');
  // formData.append('status', String(Number(dataForm?.status)));
  formData.append('is_user_request', String(0));
  return apiService.post(`/inquiries`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': "BlockBlob"
    }
  });
}

export function postUpdateCS(idCS: string, dataForm: Partial<PostCS>): Promise<ResponseApi<CS>> {
  const formData = new FormData();
  formData.append('email_respond', dataForm?.email_respond || '');
  formData.append('response', dataForm?.response ?? '');
  // formData.append('status', String(Number(dataForm?.status )));
  formData.append('is_user_request', String(0));
  return apiService.post(`/inquiries/${idCS}`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': "BlockBlob"
    }
  });
}

export function postSendEmailCS(idCS: string, dataForm: Partial<PostCS>): Promise<ResponseApi<CS>> {
  const formData = new FormData();
  formData.append('email_respond', dataForm?.email_respond || '');
  formData.append('response', dataForm?.response ?? '');
  // formData.append('status', String(Number(dataForm?.status )));
  formData.append('is_user_request', String(0));
  return apiService.post(`/inquiries/${idCS}`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': "BlockBlob"
    }
  });
}

export function postUpdateStatusCS(idCS: string, status: boolean | number): Promise<ResponseApi<CS>> {
  return apiService.post(`/inquiries/${idCS}`, { status: Number(status) })
}

export function postDeleteCS(idCS: string, dataForm: Partial<PostCS> | any = {}): Promise<ResponseApi<CS>> {
  return apiService.delete(`/inquiries/${idCS}`, { data: dataForm });
}