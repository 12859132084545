import {FieldProps} from 'formik'
import Select, {components} from 'react-select'
import './style.scss'
import clsx from 'clsx'
import {useIntl} from 'react-intl'

interface Option {
  label: string | number
  value: string | number
}

interface CustomSelectProps extends FieldProps {
  label?: string
  options: Option[]
  isMulti?: boolean
  className?: string
  placeholder?: string
  isFullWidth?: boolean
  isRequired?: boolean
  colLeft?: number
  colRight?: number
  isDisabled?: boolean
  isObjOption?: boolean
  handleOnChange?: (value: Option | Option[]) => void
  isLabelCenter?: boolean
}

export const CustomSelect = ({
  label,
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  isFullWidth = false,
  isRequired = true,
  colLeft = 3,
  colRight = 6,
  isDisabled = false,
  isObjOption = false,
  isLabelCenter = false,
  handleOnChange,
  ...props
}: CustomSelectProps) => {
  const intl = useIntl()
  const onChange = (option: any | Option[]) => {
    if (isObjOption) {
      form.setFieldValue(
        field?.name,
        isMulti ? (option as Option[]).map((item: Option) => item) : option.value
      )
    } else {
      form.setFieldValue(
        field.name,
        isMulti ? (option as Option[]).map((item: Option) => item) : (option as Option)
      )
    }

    handleOnChange && handleOnChange(option)
  }

  // const onChangeOne = (event: any) => {
  //   form.setFieldValue(
  //     field.name,
  //     options.find((option: Option) => String(option.value) === String(event.target.value))
  //   )
  // }

  // const getValueOne = () => {
  //   if (options) {
  //     return options.find((option: Option) => String(option.value) === String(field.value))
  //   } else {
  //     return isMulti ? [] : ('' as any)
  //   }
  // }
  const getValue = () => {
    if (options) {
      if (isMulti) {
        const values = (field?.value || []).map((option: Option) => option.value)
        return options.filter((option: Option) => values.indexOf(option.value) >= 0)
      }
      return options.find((option: Option) => option.value === field.value)
    } else {
      return isMulti ? [] : ('' as any)
    }
  }

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className='fas fa-chevron-down' />
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <>
      <div
        className={`col-lg-${isFullWidth ? 12 : colLeft} col-md-${
          isFullWidth ? 12 : colLeft
        } mb-md-${isFullWidth ? 1 : 0} fs-mc-16 ${
          isLabelCenter ? 'd-flex align-items-center' : ''
        }`}
      >
        {label && (
          <label className={clsx('col-form-label p-0 form-custom-label', {required: isRequired})}>
            {label}
          </label>
        )}
      </div>
      <div
        className={`col-lg-${isFullWidth ? 12 : colRight} col-md-${isFullWidth ? 12 : colRight}`}
      >
        <Select
          {...props}
          styles={{
            menu: (base: any) => ({
              ...base,
              zIndex: 100,
            }),
          }}
          className={className}
          name={field.name}
          isDisabled={isDisabled}
          value={getValue()}
          //@ts-ignore

          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          components={{DropdownIndicator}}
          loadingMessage={() => <div>{intl.formatMessage({id: 'TEXT_LOADING'})}...</div>}
          noOptionsMessage={() => <div>{intl.formatMessage({id: 'TEXT_NO_OPTIONS'})}</div>}
        />
        {/* <select
          className={`form-select form-select-solid form-select-md select2-hidden-accessible`}
          {...props}
          name={field.name}
          value={getValueOne()}
          onChange={onChangeOne}
          placeholder={placeholder}
        >
           {
            options?.map((option) => (<option value={option?.value} key={option?.value}>{option?.label}</option>))
          }
        </select> */}
        {form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomSelect
