import clsx from 'clsx'
import React from 'react'
import {IconSpinner} from '../../../loading/IconSpinner/IconSpinner'
import './style.scss'

type Props = {
  text: string
  isLoading?: boolean
  className?: string
  type?: 'submit' | 'button'
  width?: string
  onClick?: () => void
  disabled?: boolean
}

const ButtonPrimary: React.FC<Props> = ({
  text = '',
  disabled = false,
  className,
  isLoading = false,
  width = 'auto',
  type = 'button',
  onClick,
}) => {
  const handleOnClick = (event: any) => {
    if (onClick) {
      event.preventDefault()
      onClick()
    }
  }

  return (
    <>
      <button
        disabled={isLoading || disabled}
        className={clsx(
          'position-relative btn width-auto btn-primary h-40px px-4 mb-8 d-flex align-items-center float-end fw-bold justify-content-center btn-primary-mc',
          {'btn-loading': isLoading},
          className
        )}
        style={{width: width}}
        type={type}
        onClick={handleOnClick}
      >
        <IconSpinner className='icon-loading position-absolute' /> {text}
      </button>
    </>
  )
}

export {ButtonPrimary}
