import clsx from "clsx";
import { FieldProps } from "formik";
import { ConfigProvider, DatePicker } from 'antd';
import localeKo from "antd/es/locale/ko_KR";
import localeEn from "antd/es/locale/en_US";
import React from 'react';
import dayjs from 'dayjs';
import './style.scss';
import { DATE_FORMAT } from "../../../../helpers";
import { useLang } from "../../../../i18n/MetaCrewi18n";
interface CustomInputRangePickerProps extends FieldProps {
  label?: string;
  className?: string;
  placeholder?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  numberRow?: number;
  isEditor?: boolean,
  defaultValue?: [any, any],
  dateFormat?: string,
}

const { RangePicker } = DatePicker;

export const CustomInputRangePicker = ({
  label,
  className,
  placeholder,
  isRequired = true,
  isFullWidth = false,
  numberRow = 1,
  isEditor = false,
  defaultValue = ['', ''],
  dateFormat = DATE_FORMAT,
  field,
  form,
  ...props
}: CustomInputRangePickerProps) => {
  const lang = useLang();
  const defaultValueFormat: any = [(defaultValue[0] ? dayjs(defaultValue[0], dateFormat) : ''), (defaultValue[1] ? dayjs(defaultValue[1], dateFormat) : '')];

  const onChange = (_dates: any, dateStrings: [string, string]) => {
    const _value = dateStrings
    form.setFieldValue(
      field.name,
      _value
    );
  };

  return (
    <>
      <div className={`col-lg-${isFullWidth ? 12 : 3} col-md-${isFullWidth ? 12 : 3} mb-md-${isFullWidth ? 1 : 0} fs-mc-16`}>
        {label && (<label className={clsx('col-form-label p-0 form-custom-label', { 'required': isRequired })}>{label}</label>)}
      </div>
      <div className={`form-range-picker-custom col-lg-${isFullWidth ? 12 : 6} col-md-${isFullWidth ? 12 : 6}`}>
        <ConfigProvider locale={lang === 'ko' ? localeKo : localeEn}>
          <RangePicker
            {...props}
            defaultValue={defaultValueFormat}
            onChange={onChange}
          />
        </ConfigProvider>
        {form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomInputRangePicker;   
