import {postUpdateAdMedia} from '../../../api/requests/_adMedia'
import {postUpdateSettingAdsMedia} from '../../../api/requests/_adsSettingMedia'

const useActionMedia = () => {
  const updateSettingAdsMedia = async (formData: any) => {
    const params = {...formData}
    const res = await postUpdateSettingAdsMedia(params)
    return res
  }

  const updateSettingItemAdsMedia = async (formData: any, id: any) => {
    const params = {...formData}
    const res = await postUpdateAdMedia(params, id)
    return res
  }

  return {
    updateSettingAdsMedia,
    updateSettingItemAdsMedia,
  }
}

export default useActionMedia
