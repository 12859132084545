import {
  postCancelNotification,
  postCreateNotification,
  postDeleteNotification,
  postUpdateNotification,
  postUpdateStatusNotification,
} from '../../../api/requests/_notification'
import {
  fetchDetailNotification,
  postMarkAllAsRead,
} from '../../../api/requests/crm-notification/_notification'

const useActionNotification = () => {
  const getDetailNotification = async (id: any) => {
    const res = await fetchDetailNotification(id)
    return res
  }
  const createNotification = async (formData: any) => {
    const params = {...formData}
    const res = await postCreateNotification(params)
    return res
  }

  const updateNotification = async (idNotification: string, formData: any) => {
    const params = {...formData}
    const res = await postUpdateNotification(idNotification, params)
    return res
  }

  const updateStatusNotification = async (
    idNotification: string,
    status: boolean | number = false
  ) => {
    const res = await postUpdateStatusNotification(idNotification, status)
    return res
  }

  const postCancelPushNotification = async (idNotification: string) => {
    const res = await postCancelNotification(idNotification)
    return res
  }
  const markAllAsRead = async () => {
    const res = await postMarkAllAsRead({is_read_all: '1'})
    return res
  }

  const deleteNotification = async (idNotification: string) => {
    const params = {}
    const res = await postDeleteNotification(idNotification, params)
    return res
  }

  return {
    createNotification,
    updateNotification,
    updateStatusNotification,
    deleteNotification,
    postCancelPushNotification,
    getDetailNotification,
    markAllAsRead,
  }
}

export default useActionNotification
