import clsx from 'clsx'
import {useLayout} from '../../core'
import {Toolbar} from './Toolbar'

const ToolbarWrapper = () => {
  const {config, classes} = useLayout()
  if (!config.app?.toolbar?.display) {
    return null
  }

  return (
    <div
      id='kt_app_toolbar'
      style={{zIndex: 9, boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)'}}
      className={clsx(
        'app-toolbar position-sticky top-0',
        classes.toolbar.join(' '),
        config?.app?.toolbar?.class
      )}
    >
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {/* {isPageTitleVisible && <PageTitleWrapper />} */}
        <Toolbar />
      </div>
    </div>
  )
}

export {ToolbarWrapper}
