/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, {ApexOptions} from 'apexcharts'
import React, {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {getCSS} from '../../../../_metacrew/assets/ts/_utils'
import {useThemeMode} from '../../../../_metacrew/partials'
import NumberDelay from '../../../../_metacrew/partials/tdi-mc/common/NumberDelay'
import Loading from './Loading/Loading'

const colors = [
  '#5ea2c3',
  '#20b2aa',
  '#8ecae6',
  '#1c788e',
  '#6a89cc',
  '#636e72',
  '#2d3436',
  '#00b894',
  '#fd79a8',
]

const ChannelChart: React.FC<any> = ({className, data, total = 0, isLoading = false}: any) => {
  const sortedData = data && data.sort((a: any, b: any) => b.news_views - a.news_views)
  const intl = useIntl()

  // const FLATFORM = [
  //   {title: 'google', label: intl.formatMessage({id: 'PLATFORM-GOOGLE'})},
  //   {title: 'kakao', label: intl.formatMessage({id: 'PLATFORM-KAKAO'})},

  //   {
  //     title: 'wishnote',
  //     label: intl.formatMessage({id: 'PLATFORM-WISHNOTE'}),
  //   },
  //   {title: 'amazon', label: intl.formatMessage({id: 'PLATFORM-AMAZON'})},
  //   {title: 'apple', label: intl.formatMessage({id: 'PLATFORM-APPLE'})},
  //   {title: 'naver', label: intl.formatMessage({id: 'PLATFORM-NAVER'})},
  //   {
  //     title: 'facebook',
  //     label: intl.formatMessage({id: 'PLATFORM-FACEBOOK'}),
  //   },
  // ]

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const labels = sortedData && sortedData.map((item: any) => item.name)
  const dataLabels = sortedData && sortedData.map((item: any) => parseInt(item.news_views))

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, labels, dataLabels))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    let chart: any = null
    if (sortedData) {
      chart = refreshMode()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, sortedData])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'CHART.TITLE_NEW_VIEWS_CHANNEL'})}
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {intl.formatMessage({id: 'CHART.DESCRIPTION_NEW_VIEWS_CHANNEL'})}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
          height: '100%',
        }}
        //className="row"
      >
        <div className='mx-auto' style={{minHeight: 180, width: '100%'}}>
          {isLoading && (
            <Loading
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30px',
                height: '30px',
                zIndex: 10,
              }}
            />
          )}
          <div className='mx-auto' ref={chartRef} id='kt_charts_widget_3_chart'></div>
        </div>
        {/* end::Body */}

        <div
          style={{
            paddingLeft: '2.25rem',
            marginRight: 50,
            display: 'flex',
            gap: 30,
          }}
          //className="col-4 px-10 px-md-10"
        >
          <div className='d-flex flex-column col-3 flex-wrap text-center justify-content-center align-items-center'>
            <h1 style={{fontSize: 40, color: '#0c6cff'}} className='mb-0'>
              {total}
            </h1>
            <div className='text-muted fs-8'>{intl.formatMessage({id: 'TOTAL_CHANNEL'})}</div>
          </div>
          <div className='d-flex col-9 flex-wrap'>
            {data &&
              data.map((item: any, index: number) => (
                <div
                  className={`fw-bold col-6`}
                  style={{color: colors[index], display: 'flex'}}
                  key={index}
                >
                  <div className='text-muted fw-semibold fs-7 ' style={{width: 80}}>
                    {item.name}:
                  </div>
                  <div style={{marginRight: 8}}>
                    <NumberDelay number={item.news_views} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChannelChart}

function getChartOptions(
  height: number,
  labels: string[] | undefined,
  dataLabel: number[] | undefined
): ApexOptions {
  return {
    series: dataLabel,

    chart: {
      // width: "100%",
      height: 200,
      type: 'pie',
    },
    labels: labels,

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    // theme: {
    //   mode: "dark",
    //   monochrome: {
    //     enabled: true,
    //   },
    // },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    colors: [
      '#5ea2c3',
      '#20b2aa',
      '#8ecae6',
      '#1c788e',
      '#6a89cc',
      '#636e72',
      '#2d3436',
      '#00b894',
      '#fd79a8',
    ],
    legend: {
      show: false,
    },
  }
}
