import clsx from 'clsx';
import React from 'react';
import { IconSpinner } from '../../../loading/IconSpinner/IconSpinner';
import './style.scss';

type Props = {
  text: string,
  isLoading?: boolean,
  className?: string,
  type?: 'submit' | 'button',
  onClick?: () => void
}

const ButtonCancel: React.FC<Props> = ({ text = '', className, isLoading = false, type = 'button', onClick }) => {

  const handleOnClick = (event: any) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <>
      <button className={clsx('position-relative btn btn-light width-auto fw-bold justify-content-center btn-cancel-mc', { 'btn-loading': isLoading}, className)} type={type} onClick={handleOnClick}>
         <IconSpinner className="icon-loading position-absolute" /> {text}
      </button>
    </>
  )
}

export { ButtonCancel };

