import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metacrew/layout/core'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metacrew/helpers'
import {IUser} from '../../../../_metacrew/api/models/_user'
import {postLogout, getVerifyUserByAccessToken} from '../../../../_metacrew/api/requests/_auth'
import {AuthModel} from '../../../../_metacrew/api/models/_auth'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  saveCurrentApp: (appId: string | undefined) => void
  currentUser: IUser | undefined
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
  currentApp: string | undefined
  setCurrentApp: Dispatch<SetStateAction<string | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  saveCurrentApp: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentApp: authHelper.getCurrentApp(),
  setCurrentApp: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()
  const [currentApp, setCurrentApp] = useState<string | undefined>(authHelper.getCurrentApp())

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    saveCurrentApp('')
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
      authHelper.removeCurrentApp()
    }
  }

  const saveCurrentApp = (appId: string | undefined) => {
    setCurrentApp(appId || '')
    authHelper.setCurrentApp(appId || '')
  }

  const logout = async () => {
    const {code} = await postLogout()
    if (code === 1) {
      saveAuth(undefined)
      setCurrentUser(undefined)
      setCurrentApp(undefined)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        saveCurrentApp,
        currentUser,
        setCurrentUser,
        currentApp,
        setCurrentApp,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        const {data} = await getVerifyUserByAccessToken()
        if (data) {
          setCurrentUser(data)
          // saveCurrentApp(authHelper.getCurrentApp() || data?.apps?.[0] || '');
        }
      } catch (error) {
        console.error(error)
        logout()
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
