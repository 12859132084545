import clsx from 'clsx'
import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {IconSpinner} from '../../loading/IconSpinner/IconSpinner'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'
import './style.scss'

type Props = {
  isLoading?: boolean
  title?: string
  content?: string
  show: boolean
  textButtonAction?: string
  handleClose?: () => void
  handleYes?: () => void
}

const ConfirmModal: React.FC<Props> = ({
  show = true,
  title = '',
  content = '',
  isLoading = false,
  textButtonAction = '',
  handleClose,
  handleYes,
}) => {
  const intl = useIntl()
  const onNo = useCallback((event: any) => {
    event.preventDefault()
    handleClose && handleClose()
    // eslint-disable-next-line
  }, [])
  const onYes = (event: any) => {
    event.preventDefault()
    handleYes && handleYes()
  }
  return (
    <ModalWrapper
      show={show}
      className='noti-modal'
      dialogClassName='h-auto modal-dialog-centered mw-400px'
    >
      <div className='container-md w-lg-400px p-0'>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='mt-2 text-dark'>{title}</h2>
            </div>
          </div>
          {/* begin::Search */}
          <div className='d-flex flex-column justify-content-center align-items-left'>
            <div className='mb-3'></div>
            <div className='modal-content-text text-dark'>{content}</div>
            <div className='w-100 d-flex justify-content-end align-items-center'>
              <button className='btn btn-light fw-bold me-3' type='button' onClick={onNo}>
                {intl.formatMessage({id: 'BUTTON_CANCEL'})}
              </button>
              <button
                className={clsx(
                  'position-relative btn width-auto btn-danger fw-bold justify-content-center btn-delete-mc',
                  {'btn-loading': isLoading}
                )}
                type='button'
                onClick={onYes}
              >
                <IconSpinner className='icon-loading position-absolute' />{' '}
                {textButtonAction || intl.formatMessage({id: 'BUTTON_DELETE'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {ConfirmModal}
