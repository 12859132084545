import {ConfigProvider, DatePicker} from 'antd'
import localeKo from 'antd/es/locale/ko_KR'
import localeEn from 'antd/es/locale/en_US'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import React, {useMemo} from 'react'
import {IParamsSearch} from '../../../api/models/_public'
import './style.scss'
import {useIntl} from 'react-intl'
import {useLang} from '../../../i18n/MetaCrewi18n'

const {RangePicker} = DatePicker

type Props = {
  handleSearch?: (s: IParamsSearch) => void
  fromDate?: string | null
  endDate?: string | null
  value?: [string, string]
  isBgWhite?: boolean
  isLabel?: boolean
  label?: string
  isHiddenLine?: boolean
}

const dateFormat = 'YYYY-MM-DD'

const RangePickerCustom: React.FC<Props> = ({
  fromDate = null,
  endDate = null,
  value = ['', ''],
  handleSearch,
  isBgWhite = false,
  isLabel = true,
  label = '',
  isHiddenLine = false,
}) => {
  const intl = useIntl()
  const lang = useLang()
  const defaultValue: any = [
    value[0] ? dayjs(value[0], dateFormat) : '',
    value[1] ? dayjs(value[1], dateFormat) : '',
  ]
  const handleChange = (_dates: any, dateStrings: [string, string]) => {
    if (dateStrings?.length === 2 && handleSearch) {
      handleSearch({start_date: dateStrings[0], end_date: dateStrings[1]})
    }
  }
  const rangePresets: any[] = useMemo(
    () => [
      {
        label: `${intl.formatMessage({id: 'TEXT_TODAY'})}`,
        value: [dayjs(), dayjs()],
      },
      {
        label: `${intl.formatMessage({id: '7_DAYS_AGO'})}`,
        value: [dayjs().add(-6, 'd'), dayjs()],
      },
      {
        label: `${intl.formatMessage({id: '14_DAYS_AGO'})}`,
        value: [dayjs().add(-13, 'd'), dayjs()],
      },
      {
        label: `${intl.formatMessage({id: '30_DAYS_AGO'})}`,
        value: [dayjs().add(-29, 'd'), dayjs()],
      },
      {
        label: `${intl.formatMessage({id: '90_DAYS_AGO'})}`,
        value: [dayjs().add(-89, 'd'), dayjs()],
      },
    ],
    // eslint-disable-next-line
    []
  )

  return (
    <ConfigProvider locale={lang === 'ko' ? localeKo : localeEn}>
      <div className='card-toolbar align-items-center d-flex min-w-100 min-w-sm-250px'>
        {!isHiddenLine && (
          <div className='bullet bg-secondary h-35px w-1px me-5 d-none d-md-block'></div>
        )}
        {isLabel && (
          <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>
            <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none text-nowrap d-md-block'>
              {' '}
              {label || intl.formatMessage({id: 'SEARCH_DATE'})}:
            </span>
          </span>
        )}

        {/* begin::Search */}
        <RangePicker onChange={handleChange} value={defaultValue} presets={rangePresets} />
      </div>
    </ConfigProvider>
  )
}

export {RangePickerCustom}
