/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../form/CustomSelect/CustomSelect'
import CustomTagInput from '../../form/CustomTagInput/CustomTagInput'
import {INewsFilter} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionPartner: Option[]
  handleClose: () => void
  handleSubmit?: (values: INewsFilter, id: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const NewsFilterFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  initialValuesDefault = {},
  isShowButton = false,
  listOptionPartner = [],
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()
  const animatedComponents = makeAnimated()
  const validationSchema = Yup.object().shape({
    partner: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    keywords: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          text: Yup.string(),
        })
      )
      .min(1, `${intl.formatMessage({id: 'VALIDATION_MIN_1_KEYWORD'})}`)
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    status: Yup.string(),
  })
  const keywords: any = (initialValuesDefault?.keywords || [])?.map((item: any) => ({
    id: item,
    text: item,
  }))
  const partner = initialValuesDefault?.partner
    ? {value: initialValuesDefault?.partner?.id, label: initialValuesDefault?.partner?.name}
    : listOptionPartner[0]
  const initialValues: INewsFilter = {
    partner: partner,
    keywords: keywords,
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }

  const onSubmit = (values: INewsFilter) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl'>
        <div className='modal-body pt-0'>
          <div className='row modal-header'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>
                {intl.formatMessage({
                  id: initialValuesDefault?.id
                    ? 'TITLE_UPDATE_NEWS_FILTER'
                    : 'TITLE_ADD_NEWS_FILTER',
                })}
                {initialValuesDefault?.partner?.name && (
                  <span className='fs-8 text-primary ms-2'>{`( ${initialValuesDefault?.partner?.name} )`}</span>
                )}
              </h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<INewsFilter>) => (
              <Form className='p-6'>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'MEDIA'})}
                    name='partner'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={partner}
                    options={listOptionPartner}
                    component={CustomSelect}
                    isMulti={false}
                    isFullWidth={true}
                    isDisabled={!!initialValuesDefault?.id}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_KEYWORDS'})}
                    name='keywords'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_KEYWORDS'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomTagInput}
                  />
                </div>
                <div className='row mb-7'>
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div>
                  <div className='text-end'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {NewsFilterFormModal}
