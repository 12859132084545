import {apiService} from '../../helpers/axios'
import {
  ParamsRequestGetList,
  ResponseApi,
  ResponseData,
  PaginationResponse,
} from '../models/_public'
import {OverallStatistics} from '../models/_overallStatistics'

export function fetchOverallStatistics(
  params: ParamsRequestGetList
): Promise<ResponseApi<ResponseData<OverallStatistics[]> & PaginationResponse>> {
  return apiService.get('/reports/push/overall', {params})
}
