/* eslint-disable react-hooks/exhaustive-deps */
import {useIntl} from 'react-intl'
import './style.scss'

type Props = {
  handleOnClick?: () => void
}
const ButtonLight: React.FC<Props> = ({handleOnClick}) => {
  const intl = useIntl()
  const handleClick = () => {
    handleOnClick && handleOnClick()
  }

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative'>
        <button type='button' className='btn btn-light fw-bold px-6' onClick={handleClick}>
          {intl.formatMessage({id: 'BUTTON_RESET'})}
        </button>
        {/* end::Menu */}
      </div>
      {/* end::Search */}
    </div>
  )
}

export {ButtonLight}
