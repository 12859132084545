import clsx from 'clsx'
import {FieldProps} from 'formik'
import {useCallback} from 'react'
import {WithContext as ReactTags} from 'react-tag-input'
import './style.scss'

interface Option {
  label: string | number
  value: string | number
}

export interface Tag {
  id: string
  text: string
}

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

interface CustomTagInputProps extends FieldProps {
  label?: string
  options: Option[]
  isMulti?: boolean
  className?: string
  defaultValue?: string[] | string
  initialValue?: Tag[]
  suggestions?: Tag[]
  placeholder?: string
  isRequired?: boolean
  isFullWidth?: boolean
  numberRow?: number
  isEditor?: boolean
  colLeft?: number
  colRight?: number
  onChangeTag?: (params: Tag[]) => void
}

export const CustomTagInput = ({
  label,
  className = '',
  placeholder,
  field,
  form,
  options,
  defaultValue,
  isRequired = true,
  isFullWidth = false,
  numberRow = 1,
  isEditor = false,
  colLeft = 3,
  colRight = 6,
  ...props
}: CustomTagInputProps) => {
  const onChange = useCallback(
    (valueArr: any) => {
      form.setFieldValue(field.name, valueArr)
    },
    // eslint-disable-next-line
    []
  )
  const getValue = () => {
    return field.value || []
  }

  const handleDelete = (i: number) => {
    onChange((field.value || []).filter((tag: Tag, index: number) => index !== i))
  }

  const handleAddition = (tag: any) => {
    onChange([...field.value, tag])
  }

  return (
    <>
      <div
        className={`col-lg-${isFullWidth ? 12 : colLeft} col-md-${
          isFullWidth ? 12 : colLeft
        } mb-md-${isFullWidth ? 1 : 0} fs-mc-16`}
      >
        {label && (
          <label
            className={clsx('col-form-label p-0 form-custom-label form-control-solid', {
              required: isRequired,
            })}
          >
            {label}
          </label>
        )}
      </div>
      <div
        className={`col-lg-${isFullWidth ? 12 : colRight} col-md-${isFullWidth ? 12 : colRight}`}
      >
        <>
          <ReactTags
            {...props}
            name={field.name}
            tags={getValue()}
            suggestions={[]}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition='top'
            autocomplete={true}
            allowDeleteFromEmptyInput={true}
            placeholder={placeholder}
            classNames={{
              tags: 'hashtags-custom',
              tagInputField: 'form-control form-control-solid',
            }}
          />
        </>
        {form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomTagInput
