/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback} from 'react'
import './style.scss'

type Props = {
  id?: string
  className?: string
  isActive?: boolean
  item?: any
  onChangeActive?: (idItem: string, isActive: boolean, item?: any) => void
  disabled?: boolean
}

const Switch: React.FC<Props> = ({
  className = 'justify-content-center',
  disabled = false,
  id = '',
  isActive = false,
  item = null,
  onChangeActive,
}) => {
  const handleChangeActive = useCallback(
    (e: any) => {
      onChangeActive && onChangeActive(id, !isActive, item)
    },
    [onChangeActive, isActive, id, item]
  )
  return (
    <div
      className={`form-check form-check-custom form-check-solid form-switch form-switch-sm form-switch-mc ${className}`}
    >
      <input
        disabled={disabled}
        className='form-check-input cursor-pointer'
        type='checkbox'
        checked={isActive}
        onChange={handleChangeActive}
        name='model.app.sidebar.default.fixed.desktop'
      />
    </div>
  )
}

export {Switch}
