/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import {IChangePassword} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  handleClose: () => void
  handleSubmit?: (values: IChangePassword) => void
}

const ChangePasswordFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .min(6, `${intl.formatMessage({id: 'VALIDATION_MIN'})}`)
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`),
    re_password: Yup.string().oneOf([Yup.ref('password')], '비밀번호가 일치하지 않습니다'),
  })

  const initialValues: IChangePassword = {
    password: '',
    re_password: '',
  }

  const onSubmit = (values: IChangePassword) => {
    handleSubmit && handleSubmit(values)
  }

  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className='row mb-15 mt-8'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>{intl.formatMessage({id: 'FORM_CHANGE_PASSWORD'})}</h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IChangePassword>) => (
              <Form className='pb-10'>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CHANGE_PASSWORD'})}
                    name='password'
                    type='password'
                    placeholder={intl.formatMessage({id: 'FORM_CHANGE_PASSWORD'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CONFIRM_CHANGE_PASSWORD'})}
                    name='re_password'
                    type='password'
                    placeholder={intl.formatMessage({id: 'FORM_CONFIRM_CHANGE_PASSWORD'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <div className='col-lg-3 col-md-3  fs-mc-16'></div>
                  <div className='col-lg-6 col-md-6 text-center'>
                    <ButtonPrimary
                      text={intl.formatMessage({id: 'BUTTON_CHANGE_PASSWORD'})}
                      type='submit'
                      className='me-6'
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {ChangePasswordFormModal}
