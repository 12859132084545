/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {AdvertiserGroup} from '../../../../../api/models/_advertiserGroup'
import {Channel} from '../../../../../api/models/_channel'
import {MEMBERSHIP_TYPE, MEMBER_TYPE_OPTIONS} from '../../../../../constants/type'
import useActionMembership from '../../../../../helpers/hooks/membership/useActionMembership'
import {ButtonCancel} from '../../../form/Buttons/ButtonCancel/ButtonCancel'
import {ButtonPrimary} from '../../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../../form/CustomInput/CustomInput'
import Radio from '../../../form/CustomRadio/Radio'
import RadioGroup from '../../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../../form/CustomSelect/CustomSelect'
import {ChangePasswordFormModal} from '../../ChangePasswordFormModal/ChangePasswordFormModal'
import {IMembership} from '../../ModalModel'

type Props = {
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listChannel?: Channel[]
  listOptionRole?: Option[]
  listAdvertiserGroup?: AdvertiserGroup[]
  handleSubmit?: (values: IMembership, id: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const MembershipDetailModal: React.FC<Props> = ({
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  listChannel = [],
  listAdvertiserGroup = [],
  listOptionRole = [],
  handleSubmit,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showChangePasswordFormModal, setShowChangePasswordFormModal] = useState<boolean>(false)
  const animatedComponents = makeAnimated()
  const {updatePasswordMembership} = useActionMembership()

  const MEMBERSHIP_TYPE_OPTIONS_LANGUAGE = MEMBER_TYPE_OPTIONS?.map((item) => ({
    ...item,
    label: intl.formatMessage({id: `MEMBERSHIP_TYPE_${item?.label || ''}`}),
  }))

  const listOptionChannel = listChannel?.map((item: any) => ({label: item?.name, value: item?.id}))
  const listOptionAdvertiserGroup = listAdvertiserGroup?.map((item: any) => ({
    label: item?.business_name,
    value: item?.id,
  }))

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    // password: Yup.string().required(`${intl.formatMessage({ id: 'VALIDATION_REQUIRED' })}`).min(6, `${intl.formatMessage({ id: 'VALIDATION_MIN' })}`).max(50, `${intl.formatMessage({ id: 'VALIDATION_MAX' })}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
      .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
      .required('이메일은 필수입니다'),
    member_type: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    advertiser_group: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    broadcasting_channel: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    name_of_person_in_charge: Yup.string().required(
      `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`
    ),
    contact_person_in_charge: Yup.string().required(
      `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`
    ),

    representative_name: Yup.string(),
    company_registration_number: Yup.string(),
    business_type: Yup.string(),
    sectors: Yup.string(),
    zip_code: Yup.string(),
    address: Yup.string(),
    detailed_address: Yup.string(),
    tax_invoice_email: Yup.string(),

    status: Yup.string(),
  })

  const roles: any =
    listOptionRole?.filter((item) => item?.value === initialValuesDefault?.roles[0]?.id)[0] ??
    listOptionRole[0]
  const member_type =
    MEMBERSHIP_TYPE_OPTIONS_LANGUAGE?.filter(
      (item) => item?.value === initialValuesDefault?.type
    )[0] ?? MEMBERSHIP_TYPE_OPTIONS_LANGUAGE[0]
  const advertiser_group =
    listOptionAdvertiserGroup?.filter(
      (item) => item?.value === initialValuesDefault?.advertiser?.id
    )[0] ?? listOptionAdvertiserGroup[0]
  const broadcasting_channel =
    listOptionChannel?.filter((item) => item?.value === initialValuesDefault?.channel?.id)[0] ??
    listOptionChannel[0]

  const company =
    listAdvertiserGroup?.filter(
      (item) => String(item?.id) === String(initialValuesDefault?.advertiser?.id)
    )[0] ?? listAdvertiserGroup[0]
  const channel =
    listChannel?.filter(
      (item) => String(item?.id) === String(initialValuesDefault?.channel?.id)
    )[0] ?? listChannel[0]

  const initialValues: IMembership = {
    name: initialValuesDefault?.name,
    // password: initialValuesDefault?.name,
    email: initialValuesDefault?.email,
    member_type: member_type,
    advertiser_group: advertiser_group,
    broadcasting_channel: broadcasting_channel,
    name_of_person_in_charge: initialValuesDefault?.manager_name,
    contact_person_in_charge: initialValuesDefault?.manager_contact,
    roles: roles,
    join_date: initialValuesDefault?.created_at,

    representative_name: company?.representative_name || '',
    company_registration_number: company?.business_name || '',
    business_type: company?.business_type || '',
    sectors: company?.business_sector || '',
    zip_code: company?.postal_code || '',
    address: `[${company?.postal_code || ''}] - ${company?.address || ''}`,
    tax_invoice_email: company?.email || '',

    channel_name: channel?.name || '',
    channel_manager: channel?.manager || '',
    channel_description: channel?.description || '',
    channel_contact: channel?.contact || '',

    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }

  const onSubmit = (values: IMembership) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }

  const setValuesCompany = (company: any, setFieldValue: any) => {
    setFieldValue('representative_name', company?.representative_name || '')
    setFieldValue('company_registration_number', company?.business_name || '')
    setFieldValue('business_type', company?.business_type || '')
    setFieldValue('sectors', company?.business_sector || '')
    setFieldValue('zip_code', company?.postal_code || '')
    setFieldValue('address', `[${company?.postal_code || ''}] - ${company?.address || ''}`)
    setFieldValue('tax_invoice_email', company?.email || '')
  }

  const setValuesChannel = (channel: any, setFieldValue: any) => {
    setFieldValue('channel_name', channel?.name || '')
    setFieldValue('channel_manager', channel?.manager || '')
    setFieldValue('channel_description', channel?.description || '')
    setFieldValue('channel_contact', channel?.contact || '')
  }

  const handleChangeMemberType = (val: any, values: any, setFieldValue: any) => {
    if (MEMBERSHIP_TYPE[val?.value] === 'ADVERTISER') {
      const company = listAdvertiserGroup[0]
      setValuesCompany(company, setFieldValue)
    } else if (MEMBERSHIP_TYPE[val?.value] === 'CHANNEL') {
      const channel = listChannel[0]
      setValuesChannel(channel, setFieldValue)
    }
  }

  const handleChangeAdvertiserGroup = (val: any, values: any, setFieldValue: any) => {
    const company =
      listAdvertiserGroup?.filter((item) => String(item?.id) === String(val?.value))[0] ??
      listAdvertiserGroup[0]
    setValuesCompany(company, setFieldValue)
  }

  const handleChangeChannel = (val: any, values: any, setFieldValue: any) => {
    const channel =
      listChannel?.filter((item) => String(item?.id) === String(val?.value))[0] ?? listChannel[0]
    setValuesChannel(channel, setFieldValue)
  }

  const handleCloseChangePasswordFormModal = () => {
    setShowChangePasswordFormModal(false)
  }

  const handleOpenChangePasswordFormModal = () => {
    setShowChangePasswordFormModal((_prev: any) => true)
  }

  const handleSubmitFormChangePassword = async (dataForm: any) => {
    setLoading((_prevState: boolean) => true)
    const params = {
      password: dataForm?.password,
    }
    const {code, error} = await updatePasswordMembership(initialValuesDefault?.id || '', params)
    if (code === 1) {
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
    setLoading((_prevState: boolean) => false)
  }

  return (
    <div className='container-xxl w-lg-1100px'>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, values, setFieldValue}: FormikProps<IMembership>) => {
          return (
            <Form>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='row mb-15'>
                    <div className='col-md-3'>
                      <h2 style={{display: 'contents'}}>
                        {intl.formatMessage({id: 'FORM_BASIC_INFO'})}
                      </h2>
                    </div>
                    <div className='col-md-8 text-end'>
                      <ButtonPrimary
                        text={intl.formatMessage({id: 'BUTTON_CHANGE_PASSWORD'})}
                        type='button'
                        onClick={handleOpenChangePasswordFormModal}
                      />
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_USERNAME'})}
                      name='name'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_USERNAME'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <RadioGroup
                      id='status'
                      label={intl.formatMessage({id: 'FORM_ACTIVATE_STATUS'})}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    >
                      <Field
                        component={Radio}
                        name='status'
                        id='status0'
                        valueSet='false'
                        label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                      />
                      <Field
                        component={Radio}
                        name='status'
                        id='status1'
                        valueSet='true'
                        label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                      />
                    </RadioGroup>
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_EMAIL'})}
                      name='email'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_EMAIL'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MEMBER_TYPE'})}
                      name='member_type'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={member_type}
                      options={MEMBERSHIP_TYPE_OPTIONS_LANGUAGE}
                      component={CustomSelect}
                      isMulti={false}
                      colRight={8}
                      handleOnChange={(_val: any) =>
                        handleChangeMemberType(_val, values, setFieldValue)
                      }
                    />
                  </div>
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'ADVERTISER' && (
                    <div className='row mb-7'>
                      <Field
                        label={intl.formatMessage({id: 'FORM_ADVERTISER'})}
                        name='advertiser_group'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={true}
                        components={{...animatedComponents, DropdownIndicator}}
                        defaultValue={advertiser_group}
                        options={listOptionAdvertiserGroup}
                        component={CustomSelect}
                        handleOnChange={(_val: any) =>
                          handleChangeAdvertiserGroup(_val, values, setFieldValue)
                        }
                        isMulti={false}
                        colRight={8}
                      />
                    </div>
                  )}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'CHANNEL' && (
                    <div className='row mb-7'>
                      <Field
                        label={intl.formatMessage({id: 'FORM_BROADCASTING'})}
                        name='broadcasting_channel'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={true}
                        components={{...animatedComponents, DropdownIndicator}}
                        defaultValue={broadcasting_channel}
                        options={listOptionChannel}
                        component={CustomSelect}
                        handleOnChange={(_val: any) =>
                          handleChangeChannel(_val, values, setFieldValue)
                        }
                        isMulti={false}
                        colRight={8}
                      />
                    </div>
                  )}
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MANAGER_NAME'})}
                      name='name_of_person_in_charge'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_MANAGER_NAME'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>

                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MANAGER_INFO'})}
                      name='contact_person_in_charge'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_MANAGER_INFO'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_ROLE_PERMISSION'})}
                      name='roles'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={roles}
                      options={listOptionRole}
                      component={CustomSelect}
                      colRight={8}
                      isMulti={false}
                      isFullWidth={false}
                      isRequired={true}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_JOINING_DATE'})}
                      name='join_date'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_JOINING_DATE'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      disabled={true}
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row mb-15'>
                    <h2>{intl.formatMessage({id: 'FORM_COMPANY_INFO'})}</h2>
                  </div>
                  {/* Begin::Company */}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'ADVERTISER' && (
                    <div className='row'>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                          name='representative_name'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                          name='company_registration_number'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                          name='business_type'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                          name='sectors'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_BUSINESS_ADDRESS'})}
                          name='address'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_BUSINESS_ADDRESS'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>

                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                          name='tax_invoice_email'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  )}
                  {/* End::Company */}

                  {/* Begin::Channel */}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'CHANNEL' && (
                    <div className='row'>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_NAME'})}
                          name='channel_name'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_NAME'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_MANAGER'})}
                          name='channel_manager'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_MANAGER'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                          name='channel_description'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_CONTACT'})}
                          name='channel_contact'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_CONTACT'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  )}
                  {/* End::Channel */}
                </div>
                <div className='row mb-7 mt-7'>
                  <div className='col-12 text-center'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        className='me-6'
                        isLoading={isLoading}
                      />
                    )}
                    <ButtonCancel
                      text={intl.formatMessage({id: 'BUTTON_CANCEL'})}
                      type='button'
                      onClick={() => navigate('/membership')}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      <ChangePasswordFormModal
        isLoading={loading}
        show={showChangePasswordFormModal}
        handleClose={handleCloseChangePasswordFormModal}
        handleSubmit={handleSubmitFormChangePassword}
      />
    </div>
  )
}

export {MembershipDetailModal}
