import { apiService } from '../../helpers/axios';
import { Language, PostLanguage } from '../models/_language';
import { PaginationResponse, ParamsRequestGetList, ResponseApi, ResponseData } from '../models/_public';

const BASE_URL_UPLOAD_FILE = process.env.REACT_APP_BASE_URL_UPLOAD_FILE || ''

export function fetchLanguage(params:ParamsRequestGetList): Promise<ResponseApi<ResponseData<Language[]> & PaginationResponse>> {
  return apiService.get('/languages', { params })
}

export function postCreateLanguage(dataForm: Partial<PostLanguage>): Promise<ResponseApi<Language>> {
    const formData = new FormData();
    formData.append('code', dataForm?.code ?? '');
    formData.append('name', dataForm?.name ?? '');
    formData.append('status', String(Number(dataForm?.status )));
    formData.append('is_user_request', String(0));
    return apiService.post(`/languages`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdateLanguage(idLanguage:string, dataForm: Partial<PostLanguage>): Promise<ResponseApi<Language>> {
  const formData = new FormData();
    formData.append('code', dataForm?.code ?? '');
    formData.append('name', dataForm?.name ?? '');
    formData.append('status', String(Number(dataForm?.status )));
    formData.append('is_user_request', String(0));
    return apiService.post(`/languages/${idLanguage}`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdateStatusLanguage(idLanguage:string, status: boolean | number): Promise<ResponseApi<Language>> {
  return apiService.post(`/languages/${idLanguage}`, {status: Number(status)})
}

export function postDeleteLanguage(idLanguage:string, dataForm: Partial<PostLanguage> | any = {}): Promise<ResponseApi<Language>> {
  return apiService.delete(`/languages/${idLanguage}`, { data: dataForm});
}
