/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../helpers'

const PageDataContext = createContext<any>({})

const FilterHeaderDataProvider: FC<WithChildren> = ({children}) => {
  const [filterHeader, setFilterHeader] = useState<any>('')
  const value = {
    filterHeader,
    setFilterHeader,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function useFilterHeaderData() {
  return useContext(PageDataContext)
}

const FilterHeader = ({children}: any) => {
  const {setFilterHeader} = useFilterHeaderData()
  useEffect(() => {
    if (children) {
      setFilterHeader(children)
    }
    return () => {
      setFilterHeader('')
    }
  }, [children])

  return <></>
}

// const PageDescription: FC<WithChildren> = ({ children }) => {
//   const { setPageDescription } = useFilterHeaderData();
//   useEffect(() => {
//     if (children) {
//       setPageDescription(children.toString());
//     }
//     return () => {
//       setPageDescription("");
//     };
//   }, [children]);
//   return <></>;
// };

export {FilterHeader, FilterHeaderDataProvider, useFilterHeaderData}
