import ApexCharts, {ApexOptions} from 'apexcharts'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {useIntl} from 'react-intl'
import {getCSSVariableValue} from '../../../../_metacrew/assets/ts/_utils'
import {formatNumber} from '../../../../_metacrew/helpers'
import {useLang} from '../../../../_metacrew/i18n/MetaCrewi18n'
import {useThemeMode} from '../../../../_metacrew/partials'
import Loading from './Loading/Loading'

export default function NewViewsChart({
  data,
  className = '',
  title = '',
  isLoading = true,
  colors = ['success', 'warning'],
}: any) {
  const [newData, setNewData] = React.useState<any>()

  const dataViews = useMemo(() => {
    return newData && newData.map((item: any) => item.views)
  }, [newData])

  const date = useMemo(() => {
    return newData && newData.map((item: any) => item.date)
  }, [newData])

  const chartRef = useRef<HTMLDivElement | null>(null)

  const {mode} = useThemeMode()

  const lang = useLang()

  const intl = useIntl()

  const refreshMode = useCallback(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(dataViews, date, colors, lang, intl)
    )
    if (chart) {
      chart.render()
    }
    return chart
    // eslint-disable-next-line
  }, [colors, newData && newData])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, newData])

  useEffect(() => {
    if (!data) return
    const cloneData = [...(data as any)]
    cloneData?.sort((a: any, b: any) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
    setNewData(cloneData)
    // eslint-disable-next-line
  }, [data, data && data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'CHART.TITLE_NEW_VIEWS'})}
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {' '}
            {intl.formatMessage({id: 'CHART.DESCRIPTION_NEW_VIEWS'})}
          </span>
        </h3>
        <div>
          <div style={{color: '#3b6dba'}} className='fw-bold fs-1'>
            {data && data[data.length - 1]?.views}{' '}
            <span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'VIEWS'})}</span>
          </div>
        </div>
      </div>

      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column justify-content-end'>
        {isLoading && (
          <Loading
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '30px',
              height: '30px',
              zIndex: 10,
            }}
          />
        )}
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' className='min-h-auto'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

function getChartOptions(
  dataViews: any,
  date: any,
  colors: string[],
  lang: string = 'ko',
  intl: any
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      {
        name: intl.formatMessage({id: 'VIEWS'}),
        type: 'area',
        data: dataViews,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      height: 300,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
        },
        formatter: function (val) {
          return formatNumber(val)
        },
      },

      tickAmount: 4,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: dataViews && dataViews.length > 7 ? 2 : 5,
      },
    },

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1.5,
      curve: 'smooth',
    },
    xaxis: {
      categories: date,
      tickAmount: 2,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return `${
            val
              ? new Intl.DateTimeFormat(lang === 'en' ? 'en-US' : 'ko-KR', {
                  month: 'short',
                  day: '2-digit',
                }).format(dayjs(val, 'YYYY-MM-DD').valueOf())
              : ''
          }`
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.4,
    },

    tooltip: {
      style: {
        fontSize: '12px',
      },

      marker: {
        show: true,
      },
    },
    colors: ['#219ebc', '#3b6dba'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 10,
        bottom: 0,
      },
    },
  }
}
