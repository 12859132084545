/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {AdvertiserGroup} from '../../../../../api/models/_advertiserGroup'
import {Channel} from '../../../../../api/models/_channel'
import {MEMBERSHIP_TYPE, MEMBER_TYPE, MEMBER_TYPE_OPTIONS} from '../../../../../constants/type'
import {getKeyByValue} from '../../../../../helpers'
import {ButtonCancel} from '../../../form/Buttons/ButtonCancel/ButtonCancel'
import {ButtonPrimary} from '../../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../../form/CustomInput/CustomInput'
import Radio from '../../../form/CustomRadio/Radio'
import RadioGroup from '../../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../../form/CustomSelect/CustomSelect'
import {IMembership} from '../../ModalModel'

type Props = {
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listChannel?: Channel[]
  listOptionRole?: Option[]
  listAdvertiserGroup?: AdvertiserGroup[]
  handleSubmit?: (values: IMembership, id?: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const MembershipFormModal: React.FC<Props> = ({
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  listChannel = [],
  listOptionRole = [],
  listAdvertiserGroup = [],
  handleSubmit,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const animatedComponents = makeAnimated()

  const MEMBERSHIP_TYPE_OPTIONS_LANGUAGE = MEMBER_TYPE_OPTIONS?.map((item) => ({
    ...item,
    label: intl.formatMessage({id: `MEMBERSHIP_TYPE_${item?.label}`}),
  }))

  const listOptionChannel = listChannel?.map((item: any) => ({label: item?.name, value: item?.id}))
  const listOptionAdvertiserGroup = listAdvertiserGroup?.map((item: any) => ({
    label: item?.business_name,
    value: item?.id,
  }))

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    password: Yup.string()
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .min(6, `${intl.formatMessage({id: 'VALIDATION_MIN'})}`)
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
      .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    member_type: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    advertiser_group: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        function (value: any) {
          const {member_type} = this.parent
          if (
            (typeof value !== 'object' || !value.label || !value.value) &&
            member_type?.value === getKeyByValue(MEMBER_TYPE, 'ADVERTISER')
          ) {
            return false
          }
          return true
        }
      ),
    broadcasting_channel: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        function (value: any) {
          const {member_type} = this.parent
          if (
            (typeof value !== 'object' || !value.label || !value.value) &&
            member_type?.value === getKeyByValue(MEMBER_TYPE, 'CHANNEL')
          ) {
            return false
          }
          return true
        }
      ),
    roles: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        function (value: any) {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    name_of_person_in_charge: Yup.string().required(
      `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`
    ),
    contact_person_in_charge: Yup.string().required(
      `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`
    ),

    representative_name: Yup.string(),
    company_registration_number: Yup.string(),
    business_type: Yup.string(),
    sectors: Yup.string(),
    zip_code: Yup.string(),
    address: Yup.string(),
    detailed_address: Yup.string(),
    tax_invoice_email: Yup.string(),

    status: Yup.string(),
  })
  const roles: any = listOptionRole[0] || {value: '', label: ''}
  const member_type = MEMBERSHIP_TYPE_OPTIONS_LANGUAGE[0] || {value: '', label: ''}
  const advertiser_group = listOptionAdvertiserGroup[0] || {value: '', label: ''}
  const broadcasting_channel = listOptionChannel[0] || {value: '', label: ''}
  const initialValues: IMembership = {
    name: '',
    password: '',
    email: '',
    member_type: member_type,
    advertiser_group: advertiser_group,
    broadcasting_channel: broadcasting_channel,
    name_of_person_in_charge: '',
    contact_person_in_charge: '',
    roles: roles,

    representative_name: '',
    company_registration_number: '',
    business_type: '',
    sectors: '',
    zip_code: '',
    address: '',
    detailed_address: '',
    tax_invoice_email: '',
    status: 'true',
  }

  const onSubmit = (values: IMembership) => {
    handleSubmit && handleSubmit(values)
  }

  const setValuesCompany = (company: any, setFieldValue: any) => {
    setFieldValue('representative_name', company?.representative_name || '')
    setFieldValue('company_registration_number', company?.business_name || '')
    setFieldValue('business_type', company?.business_type || '')
    setFieldValue('sectors', company?.business_sector || '')
    setFieldValue('zip_code', company?.postal_code || '')
    setFieldValue('address', `[${company?.postal_code || ''}] - ${company?.address || ''}`)
    setFieldValue('tax_invoice_email', company?.email || '')
  }

  const setValuesChannel = (channel: any, setFieldValue: any) => {
    setFieldValue('channel_name', channel?.name || '')
    setFieldValue('channel_manager', channel?.manager || '')
    setFieldValue('channel_description', channel?.description || '')
    setFieldValue('channel_contact', channel?.contact || '')
  }

  const handleChangeMemberType = (val: any, values: any, setFieldValue: any) => {
    if (MEMBERSHIP_TYPE[val?.value] === 'ADVERTISER') {
      const company = listAdvertiserGroup[0]
      setValuesCompany(company, setFieldValue)
    } else if (MEMBERSHIP_TYPE[val?.value] === 'CHANNEL') {
      const channel = listChannel[0]
      setValuesChannel(channel, setFieldValue)
    }
  }

  const handleChangeAdvertiserGroup = (val: any, values: any, setFieldValue: any) => {
    const company =
      listAdvertiserGroup?.filter((item) => String(item?.id) === String(val?.value))[0] ??
      listAdvertiserGroup[0]
    setValuesCompany(company, setFieldValue)
  }

  const handleChangeChannel = (val: any, values: any, setFieldValue: any) => {
    const channel =
      listChannel?.filter((item) => String(item?.id) === String(val?.value))[0] ?? listChannel[0]
    setValuesChannel(channel, setFieldValue)
  }

  return (
    <div className='container-xxl w-lg-1100px'>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, values, setFieldValue}: FormikProps<IMembership>) => {
          return (
            <Form>
              <div className='row'>
                <div className='col-md-6'>
                  <h2 className='mb-15'>기본 정보</h2>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_USERNAME'})}
                      name='name'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_USERNAME'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_PASSWORD'})}
                      name='password'
                      type='password'
                      placeholder={intl.formatMessage({id: 'FORM_PASSWORD'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_EMAIL'})}
                      name='email'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_EMAIL'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MEMBER_TYPE'})}
                      name='member_type'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={member_type}
                      options={MEMBERSHIP_TYPE_OPTIONS_LANGUAGE}
                      component={CustomSelect}
                      isMulti={false}
                      colRight={8}
                      handleOnChange={(_val: any) =>
                        handleChangeMemberType(_val, values, setFieldValue)
                      }
                    />
                  </div>
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'ADVERTISER' && (
                    <div className='row mb-7'>
                      <Field
                        label={intl.formatMessage({id: 'FORM_ADVERTISER'})}
                        name='advertiser_group'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={true}
                        components={{...animatedComponents, DropdownIndicator}}
                        defaultValue={advertiser_group}
                        options={listOptionAdvertiserGroup}
                        component={CustomSelect}
                        isMulti={false}
                        colRight={8}
                        handleOnChange={(_val: any) =>
                          handleChangeAdvertiserGroup(_val, values, setFieldValue)
                        }
                      />
                    </div>
                  )}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'CHANNEL' && (
                    <div className='row mb-7'>
                      <Field
                        label={intl.formatMessage({id: 'FORM_BROADCASTING'})}
                        name='broadcasting_channel'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={true}
                        components={{...animatedComponents, DropdownIndicator}}
                        defaultValue={broadcasting_channel}
                        options={listOptionChannel}
                        component={CustomSelect}
                        isMulti={false}
                        colRight={8}
                        handleOnChange={(_val: any) =>
                          handleChangeChannel(_val, values, setFieldValue)
                        }
                      />
                    </div>
                  )}
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MANAGER_NAME'})}
                      name='name_of_person_in_charge'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_MANAGER_NAME'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_MANAGER_INFO'})}
                      name='contact_person_in_charge'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_MANAGER_INFO'})}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      colRight={8}
                    />
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_ROLE_PERMISSION'})}
                      name='roles'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      closeMenuOnSelect={true}
                      components={{...animatedComponents, DropdownIndicator}}
                      defaultValue={roles}
                      options={listOptionRole}
                      component={CustomSelect}
                      colRight={8}
                      isMulti={false}
                      isFullWidth={false}
                      isRequired={true}
                    />
                  </div>
                  <div className='row mb-7'>
                    <RadioGroup
                      id='status'
                      label={intl.formatMessage({id: 'FORM_STATUS'})}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                      isRequired={true}
                    >
                      <Field
                        component={Radio}
                        name='status'
                        id='status0'
                        valueSet='false'
                        label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                      />
                      <Field
                        component={Radio}
                        name='status'
                        id='status1'
                        valueSet='true'
                        label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div className='col-md-6'>
                  <h2 className='mb-15 '>
                    회사 정보{' '}
                    <span className='text-danger ms-5' style={{fontSize: '1rem'}}>
                      사업자 등록증에 표기된 내용으로 입력해주세요
                    </span>{' '}
                  </h2>
                  {/* Begin::Company */}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'ADVERTISER' && (
                    <div className='row'>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                          name='representative_name'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                          name='company_registration_number'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                          name='business_type'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                          name='sectors'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>

                      <div className='row mb-7'>
                        <div className='col-lg-3 col-md-3 mb-md-0 fs-mc-16 d-flex flex-column justify-content-center align-items-left'>
                          <label className='col-form-label p-0 form-custom-label form-control-solid'>
                            사업장 주소
                          </label>
                        </div>
                        <div className='col-lg-8 col-md-8'>
                          <div className='row mb-7'>
                            <div className='col-md-7'>
                              <Field
                                label=''
                                name='zip_code'
                                type='text'
                                placeholder={intl.formatMessage({id: 'FORM_ZIP_CODE'})}
                                autoComplete='off'
                                className='form-control form-control-solid'
                                isFullWidth={true}
                                component={CustomInput}
                                colRight={8}
                                disabled={true}
                                isRequired={false}
                              />
                            </div>
                            <div className='col-md-5 text-end mt-1'>
                              {/* <ButtonPrimary text={intl.formatMessage({ id: 'BUTTON_LOOKUP_ZIP_CODE' })} type="button" /> */}
                            </div>
                          </div>
                          <div className='row mb-7'>
                            <Field
                              label=''
                              name='address'
                              type='text'
                              placeholder={intl.formatMessage({id: 'FORM_ADDRESS'})}
                              autoComplete='off'
                              className='form-control form-control-solid'
                              isFullWidth={true}
                              component={CustomInput}
                              colRight={8}
                              disabled={true}
                              isRequired={false}
                            />
                          </div>
                          <div className='row'>
                            <Field
                              label=''
                              name='detailed_address'
                              type='text'
                              placeholder={intl.formatMessage({id: 'FORM_STREET_ADDRESS'})}
                              autoComplete='off'
                              className='form-control form-control-solid'
                              isFullWidth={true}
                              component={CustomInput}
                              colRight={8}
                              disabled={true}
                              isRequired={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                          name='tax_invoice_email'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  )}
                  {/* End::Company */}
                  {/* Begin::Channel */}
                  {MEMBERSHIP_TYPE[values?.member_type?.value] === 'CHANNEL' && (
                    <div className='row'>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_NAME'})}
                          name='channel_name'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_NAME'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_MANAGER'})}
                          name='channel_manager'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_MANAGER'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                          name='channel_description'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                      <div className='row mb-7'>
                        <Field
                          label={intl.formatMessage({id: 'FORM_CONTACT'})}
                          name='channel_contact'
                          type='text'
                          placeholder={intl.formatMessage({id: 'FORM_CONTACT'})}
                          autoComplete='off'
                          className='form-control form-control-solid'
                          component={CustomInput}
                          colRight={8}
                          disabled={true}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  )}
                  {/* End::Channel */}
                </div>
                <div className='row mb-7 mt-7'>
                  <div className='col-12 text-center'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        className='me-6'
                        isLoading={isLoading}
                      />
                    )}
                    <ButtonCancel
                      text={intl.formatMessage({id: 'BUTTON_CANCEL'})}
                      type='button'
                      onClick={() => navigate('/membership')}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {MembershipFormModal}
