import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {IUser} from '../../../../_metacrew/api/models/_user'
import {postLogin} from '../../../../_metacrew/api/requests/_auth'
import {useAuth} from '../core/Auth'

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`) // Wrong email format
      .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`) // Minimum 3 symbols
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`) // Maximum 50 symbols
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`), // Email is required
    password: Yup.string()
      .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`) // Minimum 3 symbols
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`) // Maximum 50 symbols
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`), // Password is required
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const params = {
          email: values.email,
          password: values.password,
          remember: 1,
        }
        const {code, data} = await postLogin(params)
        if (code === 1 && !!data?.token) {
          const userDemo: IUser = data
          saveAuth(data)
          setCurrentUser(userDemo)
        } else {
          toast('이메일 또는 비밀번호가 잘못되었습니다.')
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
      }
    },
  })

  const imageSrc = '/media/logos/logo-dark.svg'

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          {/* {intl.formatMessage({ id: "LOG-IN" })} */}
          {/* eslint-disable-next-line */}
          <img src={imageSrc} style={{width: '30%'}} />
        </h1>
      </div>
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'EMAIL.PLACEHOLDER'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0 '>
          {intl.formatMessage({id: 'PASSWORD'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'PASSWORD.PLACEHOLDER'})}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          비밀번호를 잊으 셨나요 ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({id: 'LOG-IN'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
