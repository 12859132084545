import {
  postCreatePartner,
  postCreatePartnerSetting,
  postDeletePartner,
  postDeletePartnerCategory,
  postUpdatePartner,
  postUpdatePartnerCategory,
  postUpdatePartnerCategoryDefault,
  postUpdatePartnerCategoryStatus,
  postUpdatePartnerPressStatus,
  postUpdatePartnerSetting,
  postUpdateStatusPartner,
} from '../../../api/requests/_partner'

const useActionPartner = () => {
  const createPartner = async (formData: any) => {
    const params = {...formData}
    const res = await postCreatePartner(params)
    return res
  }

  const updatePartnerCategory = async (formData: any) => {
    const params = {...formData}

    const res = await postUpdatePartnerCategory(params)
    return res
  }
  const updatePartnerCategoryStatus = async (formData: any) => {
    const params = {...formData}

    const res = await postUpdatePartnerCategoryStatus(params)
    return res
  }

  const updatePartnerPressStatus = async (formData: any) => {
    const params = {...formData}

    const res = await postUpdatePartnerPressStatus(params)
    return res
  }

  const updatePartnerCategoryDefault = async (formData: any) => {
    const params = {...formData}

    const res = await postUpdatePartnerCategoryDefault(params)
    return res
  }

  const updatePartner = async (idPartner: string, formData: any) => {
    const params = {...formData}
    const res = await postUpdatePartner(idPartner, params)
    return res
  }

  const updateStatusPartner = async (idPartner: string, status: boolean | number = false) => {
    const res = await postUpdateStatusPartner(idPartner, status)
    return res
  }

  const deletePartner = async (idPartner: string) => {
    const params = {}
    const res = await postDeletePartner(idPartner, params)
    return res
  }

  const deletePartnerCategory = async (params: any) => {
    const res = await postDeletePartnerCategory(params)
    return res
  }

  const createPartnerSetting = async (formData: any) => {
    const params = {...formData}
    const res = await postCreatePartnerSetting(params)
    return res
  }

  const updatePartnerSetting = async (idPartnerSetting: string, formData: any) => {
    const params = {...formData}
    const res = await postUpdatePartnerSetting(idPartnerSetting, params)
    return res
  }

  return {
    createPartner,
    updatePartner,
    updateStatusPartner,
    deletePartner,
    createPartnerSetting,
    updatePartnerSetting,
    updatePartnerCategory,
    deletePartnerCategory,
    updatePartnerCategoryStatus,
    updatePartnerCategoryDefault,
    updatePartnerPressStatus,
  }
}

export default useActionPartner
