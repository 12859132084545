import {apiService} from '../../helpers/axios'
import {ResponseApi} from '../models/_public'

export function fetchAdMedia(id: any): Promise<ResponseApi<any>> {
  return apiService.get(`/partner-setting/ads?partner_id=${id}`)
}

export function postUpdateAdMedia(dataForm: any, id: string | number): Promise<ResponseApi<any>> {
  return apiService.post(`/partner-setting/ads/${id}`, dataForm)
}
