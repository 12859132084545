import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useCallback, useState} from 'react'
import {IParamsSearch} from '../../../api/models/_public'
import {fetchLanguage} from '../../../api/requests/_language'
import {QUERIES} from '../../crud-helper/consts'

const NUMBER_PAGE_DEFAULT: number = 1
type Props = {
  get_all: 1 | 0
}

const useListLanguage = ({get_all}: Props = {get_all: 0}) => {
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    page: NUMBER_PAGE_DEFAULT,
    keyword: '',
    limit: Number(get_all || 0) === 1 ? '' : 10,
    status: '',
    start_date: '',
    end_date: '',
    get_all: Number(get_all || 0),
  })

  const fetchPaginatedData = async ({queryKey, pageParam = NUMBER_PAGE_DEFAULT}: any) => {
    const res = await fetchLanguage({page: pageParam, ...queryKey[1]})
    return res?.data
  }

  // eslint-disable-next-line
  const {status, data, error, isFetching, isPreviousData, refetch, ...root} = useQuery({
    queryKey: [QUERIES.LANGUAGE_LIST, searchParams],
    queryFn: fetchPaginatedData,
    keepPreviousData: true,
    staleTime: 5000,
  })

  const handleChangeParams = useCallback((params: IParamsSearch) => {
    setSearchParams((prevState) => {
      return {...prevState, ...params}
    })
  }, [])

  const handleSetQueryDataItem = useCallback(
    (itemId: any, isActive: string | number | boolean) => {
      queryClient.setQueryData([QUERIES.LANGUAGE_LIST, searchParams], (_oldData: any) => {
        return {
          ..._oldData,
          items: _oldData?.items?.map((item: any) =>
            item?.id === itemId ? {...item, status: isActive} : item
          ),
        }
      })
    },
    [queryClient, searchParams]
  )

  return {
    data: data,
    //@ts-ignore
    listOptionLanguage:
      Number(get_all || 0) === 1 && data
        ? (data as any)?.map((item: any) => ({label: item?.name, value: item?.code}))
        : [],
    status,
    error,
    isFetching,
    isPreviousData,
    refetch,
    searchParams,
    handleChangeParams,
    handleSetQueryDataItem,
  }
}

export default useListLanguage
