/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  image: string
  title: string
  description: string
  status: 'up' | 'down'
  statusValue: number
  statusDesc: string
  progress: number
  progressType: string
}

const Card6: FC<Props> = ({
  image,
  title,
  description,
  status,
  statusValue,
  statusDesc,
  progress,
  progressType,
}) => {
  return (
    <div className='card h-80'>
      <div className='card-header flex-nowrap border-0 pt-3' style={{minHeight: '50px'}}>
        <div className='card-title m-0'>
          <div className='d-flex align-items-center'>
            <a href='#' className='fs-5 text-gray-600 m-0'>
              {title}
            </a>
            <KTIcon iconName='arrow-up' className='fs-3 text-success ms-2' />
          </div>
        </div>
      </div>

      <div className='card-body d-flex flex-column px-9 pt-0 pb-5'>
        <div className='text-gray-700' style={{fontSize: '1.8rem'}}>
          {Number(description).toLocaleString()}
        </div>
      </div>
    </div>
  )
}

export {Card6}
