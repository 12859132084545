import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metacrew/assets/ts/_utils'
import {WithChildren} from '../../_metacrew/helpers'
import {MasterLayout} from '../../_metacrew/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import CSPage from '../modules/cs/CSPage'

const PrivateRoutes = () => {
  const ContentPage = lazy(() => import('../modules/content/ContentPage'))
  const CategoryPage = lazy(() => import('../modules/category/CategoryPage'))
  const CampaignPage = lazy(() => import('../modules/campaign/CampaignPage'))
  const MaterialPage = lazy(() => import('../modules/material/MaterialPage'))
  const PartnerPage = lazy(() => import('../modules/partner/PartnerPage'))
  const MembershipPage = lazy(() => import('../modules/membership/MembershipPage'))
  const UserPage = lazy(() => import('../modules/user/UserPage'))
  const KeywordPage = lazy(() => import('../modules/keyword/KeywordPage'))
  const AdvertiserGroupPage = lazy(() => import('../modules/advertiserGroup/AdvertiserGroupPage'))
  const PermissionPage = lazy(() => import('../modules/permission/PermissionPage'))
  const RolePage = lazy(() => import('../modules/role/RolePage'))
  const LanguagePage = lazy(() => import('../modules/language/LanguagePage'))
  const SettingPage = lazy(() => import('../modules/setting/SettingPage'))
  const NewsPage = lazy(() => import('../modules/news/index'))
  const UserManagementPage = lazy(() => import('../modules/user-management/index'))
  const PushAnalyticsPage = lazy(() => import('../modules/push-analytics/index'))
  const UserStatisticsPage = lazy(() => import('../modules/user-statistics/index'))
  const PushNotificationPage = lazy(() => import('../modules/push-notification/index'))
  const SettingsManagementPage = lazy(() => import('../modules/setting-management/index'))
  const PartnerManagementPage = lazy(() => import('../modules/partner-management/index'))
  const CRMMediaManagementPage = lazy(() => import('../modules/crm-media/index'))
  const UserActivitiesLog = lazy(() => import('../modules/user-activities-log/index'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}

        <Route
          path='news-management/*'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='partner-management/*'
          element={
            <SuspensedView>
              <PartnerManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='push-analytics/*'
          element={
            <SuspensedView>
              <PushAnalyticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-statistics/*'
          element={
            <SuspensedView>
              <UserStatisticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin-activities-log/*'
          element={
            <SuspensedView>
              <UserActivitiesLog />
            </SuspensedView>
          }
        />
        <Route
          path='push-notification/*'
          element={
            <SuspensedView>
              <PushNotificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings-management/*'
          element={
            <SuspensedView>
              <SettingsManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='content/*'
          element={
            <SuspensedView>
              <ContentPage />
            </SuspensedView>
          }
        />
        <Route
          path='category/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/*'
          element={
            <SuspensedView>
              <CampaignPage />
            </SuspensedView>
          }
        />
        <Route
          path='material/*'
          element={
            <SuspensedView>
              <MaterialPage />
            </SuspensedView>
          }
        />
        <Route
          path='membership/*'
          element={
            <SuspensedView>
              <MembershipPage />
            </SuspensedView>
          }
        />
        <Route
          path='cs/*'
          element={
            <SuspensedView>
              <CSPage />
            </SuspensedView>
          }
        />
        <Route
          path='partners/*'
          element={
            <SuspensedView>
              <PartnerPage />
            </SuspensedView>
          }
        />

        <Route
          path='sales/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='accounts/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='keywords/*'
          element={
            <SuspensedView>
              <KeywordPage />
            </SuspensedView>
          }
        />

        <Route
          path='advertiser-groups/*'
          element={
            <SuspensedView>
              <AdvertiserGroupPage />
            </SuspensedView>
          }
        />

        <Route
          path='permissions/*'
          element={
            <SuspensedView>
              <PermissionPage />
            </SuspensedView>
          }
        />
        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='languages/*'
          element={
            <SuspensedView>
              <LanguagePage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='crm-media/*'
          element={
            <SuspensedView>
              <CRMMediaManagementPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
