/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {SUPPORTED_IMAGE_FORMATS} from '../../../../helpers'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import CustomInputFile from '../../form/CustomInputFile/CustomInputFile'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import {IStock} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  handleClose: () => void
  handleSubmit?: (values: IStock, id: string | undefined) => void
}

const StockFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault,
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    file: initialValuesDefault?.image_path
      ? Yup.string().nullable()
      : Yup.mixed()
          .test(
            'is-supported-format',
            `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
            (value: any) => {
              if (!value) return true
              return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
            }
          )
          .nullable(),
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    code: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    status: Yup.string(),
  })

  const initialValues: IStock = {
    code: initialValuesDefault?.code,
    name: initialValuesDefault?.name,
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }
  const onSubmit = (values: IStock) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className='row mb-15 mt-8'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>
                {intl.formatMessage({
                  id: initialValuesDefault?.id ? 'TITLE_UPDATE_STOCK' : 'TITLE_ADD_STOCK',
                })}
              </h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IStock>) => (
              <Form className='pb-10'>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_LOGO'})}
                    name='file'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_LOGO'})}
                    autoComplete='off'
                    valueUrlDefault={initialValuesDefault?.image_path}
                    className='form-control form-control-solid'
                    component={CustomInputFile}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CODE'})}
                    name='code'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_CODE'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_NAME'})}
                    name='name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_NAME'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div className='row mb-7'>
                  <div className='col-lg-3 col-md-3  fs-mc-16'></div>
                  <div className='col-lg-6 col-md-6 text-center'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        className='me-6'
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {StockFormModal}
