export default function LoadingAdsType() {
  return (
    <div className='col-12 justify-content-between d-flex card-body p-0 placeholder-glow'>
      <div className='w-100 d-flex gap-8'>
        <div className='d-flex flex-column col-2'>
          {/* eslint-disable-next-line */}
          <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-1 '>
            <span className='placeholder bg-secondary w-100 rounded h-35px'></span>
          </a>
          {/* eslint-disable-next-line */}
        </div>
        <div className='d-flex flex-column col-8 justify-content-end align-items-center'>
          <div className='d-flex justify-content-end gap-2 w-100'>
            {/* eslint-disable-next-line */}
            <a href='#' className='card-title fw-bold text-muted w-100 text-hover-primary fs-1 '>
              <span className='placeholder bg-secondary w-100 rounded h-35px'></span>
            </a>
            {/* eslint-disable-next-line */}
            <a href='#' className='card-title fw-bold text-muted w-100 text-hover-primary fs-1 '>
              <span className='placeholder bg-secondary w-100 rounded h-35px'></span>
            </a>
            {/* eslint-disable-next-line */}
          </div>
        </div>
      </div>
    </div>
  )
}
