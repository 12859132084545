import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {fetchDetailCS} from '../../../../_metacrew/api/requests/_cs'
import {ROLES_FOR_APP, isHavePermissionRole} from '../../../../_metacrew/constants/role'
import useActionCS from '../../../../_metacrew/helpers/hooks/cs/useActionCS'
import {CSFormModal, PageTitleInner} from '../../../../_metacrew/partials/tdi-mc'
import {useAuth} from '../../auth'

const CSForm: FC = () => {
  const intl = useIntl()

  const {id = ''} = useParams()
  // Get full permissions of account login
  const {currentUser: {roles: {permissions = []} = {}} = {}} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initialValuesDefault, setInitialValuesDefault] = useState<any>(null)
  const {createCS, updateCS} = useActionCS()

  const handleSubmitForm = async (dataForm: any, idItem: string | undefined) => {
    setIsLoading((_prevState: boolean) => true)
    const params = {
      title: dataForm?.title || '',
      status: String(dataForm?.status).toLowerCase() === 'true',
    }
    const {code, error} = await (idItem ? updateCS(idItem, params) : createCS(params))
    if (code === 1) {
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
    setIsLoading((_prevState: boolean) => false)
  }

  const getListOptionCS = async (idCS: string) => {
    const [resDetail] = await Promise.all([fetchDetailCS(idCS)])
    setInitialValuesDefault((_prev: any) => resDetail?.data)
  }

  useEffect(() => {
    id && getListOptionCS(id)
  }, [id])

  return (
    <>
      <PageTitleInner
        title={intl.formatMessage({
          id: initialValuesDefault?.id ? 'TITLE_UPDATE_INQUIRIES' : 'TITLE_ADD_INQUIRIES',
        })}
        className='col-12'
      ></PageTitleInner>
      {/* begin::Row  */}
      <div className='card col-12 bg-white p-15'>
        <CSFormModal
          isLoading={isLoading}
          initialValuesDefault={initialValuesDefault}
          isShowButton={isHavePermissionRole(
            [ROLES_FOR_APP.INQUIRIES_CREATE, ROLES_FOR_APP.INQUIRIES_UPDATE],
            permissions
          )}
          handleSubmit={handleSubmitForm}
        />
      </div>
    </>
  )
}

export {CSForm}
