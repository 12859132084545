const ROLES_FOR_APP = {
  LANGUAGES_LIST: 'languages.list',
  LANGUAGES_VIEW: 'languages.view',
  LANGUAGES_CREATE: 'languages.create',
  LANGUAGES_UPDATE: 'languages.update',
  LANGUAGES_DELETE: 'languages.delete',
  USERS_LIST: 'users.list',
  USERS_VIEW: 'users.view',
  USERS_CREATE: 'users.create',
  USERS_UPDATE: 'users.update',
  USERS_DELETE: 'users.delete',
  ROLES_LIST: 'roles.list',
  ROLES_VIEW: 'roles.view',
  ROLES_CREATE: 'roles.create',
  ROLES_UPDATE: 'roles.update',
  ROLES_DELETE: 'roles.delete',
  PERMISSIONS_LIST: 'permissions.list',
  CATEGORIES_LIST: 'categories.list',
  CATEGORIES_VIEW: 'categories.view',
  CATEGORIES_CREATE: 'categories.create',
  CATEGORIES_UPDATE: 'categories.update',
  CATEGORIES_DELETE: 'categories.delete',
  CHANNELS_LIST: 'channels.list',
  CHANNELS_VIEW: 'channels.view',
  CHANNELS_CREATE: 'channels.create',
  CHANNELS_UPDATE: 'channels.update',
  CHANNELS_DELETE: 'channels.delete',
  RSS_LIST: 'rss.list',
  RSS_VIEW: 'rss.view',
  RSS_CREATE: 'rss.create',
  RSS_UPDATE: 'rss.update',
  RSS_DELETE: 'rss.delete',
  PARTNERS_LIST: 'partners.list',
  PARTNERS_VIEW: 'partners.view',
  PARTNERS_CREATE: 'partners.create',
  PARTNERS_UPDATE: 'partners.update',
  PARTNERS_DELETE: 'partners.delete',
  DEVICES_LIST: 'devices.list',
  DEVICES_PRESS: 'devices.channel',
  DEVICES_KEYWORD: 'devices.keyword',
  STOCKS_LIST: 'stocks.list',
  STOCKS_VIEW: 'stocks.view',
  STOCKS_CREATE: 'stocks.create',
  STOCKS_UPDATE: 'stocks.update',
  STOCKS_DELETE: 'stocks.delete',
  MEDIA_COMPANIES_LIST: 'media-companies.list',
  MEDIA_COMPANIES_VIEW: 'media-companies.view',
  MEDIA_COMPANIES_CREATE: 'media-companies.create',
  MEDIA_COMPANIES_UPDATE: 'media-companies.update',
  MEDIA_COMPANIES_DELETE: 'media-companies.delete',
  CAMPAIGNS_LIST: 'campaigns.list',
  CAMPAIGNS_VIEW: 'campaigns.view',
  CAMPAIGNS_CREATE: 'campaigns.create',
  CAMPAIGNS_UPDATE: 'campaigns.update',
  CAMPAIGNS_DELETE: 'campaigns.delete',
  ADVERTISER_GROUPS_LIST: 'advertisers.list',
  ADVERTISER_GROUPS_VIEW: 'advertisers.view',
  ADVERTISER_GROUPS_CREATE: 'advertisers.create',
  ADVERTISER_GROUPS_UPDATE: 'advertisers.update',
  ADVERTISER_GROUPS_DELETE: 'advertisers.delete',
  CONTENTS_LIST: 'contents.list',
  CONTENTS_VIEW: 'contents.view',
  CONTENTS_CREATE: 'contents.create',
  CONTENTS_UPDATE: 'contents.update',
  CONTENTS_DELETE: 'contents.delete',
  KEYWORDS_LIST: 'keywords.list',
  KEYWORDS_VIEW: 'keywords.view',
  KEYWORDS_CREATE: 'keywords.create',
  KEYWORDS_UPDATE: 'keywords.update',
  KEYWORDS_DELETE: 'keywords.delete',
  USER_ACTIVITY_LOGS_LIST: 'user-activity-logs.list',
  USER_ACTIVITY_LOGS_VIEW: 'user-activity-logs.view',
  USER_ACTIVITY_LOGS_DELETE: 'user-activity-logs.delete',
  NEWS_LIST: 'news.list',
  NEWS_VIEW: 'news.view',
  NEWS_CREATE: 'news.create',
  NEWS_UPDATE: 'news.update',
  NEWS_DELETE: 'news.delete',
  INQUIRIES_LIST: 'inquiries.list',
  INQUIRIES_VIEW: 'inquiries.view',
  INQUIRIES_CREATE: 'inquiries.create',
  INQUIRIES_UPDATE: 'inquiries.update',
  INQUIRIES_DELETE: 'inquiries.delete',
  NOTIFICATION_SCHEDULE_LIST: 'notifications.get-schedule',
  NOTIFICATION_SCHEDULE_VIEW: 'notifications.get-schedule',
  NOTIFICATION_SCHEDULE_CREATE: 'notifications.create-schedule',
  NOTIFICATION_SCHEDULE_UPDATE: 'notifications.cancel-schedule',
  NOTIFICATION_SCHEDULE_DELETE: 'notifications.delete-schedule',
  NEWS_FILTER_LIST: 'filters.list',
  NEWS_FILTER_VIEW: 'filters.view',
  NEWS_FILTER_CREATE: 'filters.create',
  NEWS_FILTER_UPDATE: 'filters.update',
  NEWS_FILTER_DELETE: 'filters.delete',
  PARTNER_SETTING_LIST: 'partner-setting.list',
  PARTNER_SETTING_VIEW: 'partner-setting.view',
  PARTNER_SETTING_CREATE: 'partner-setting.create',
  PARTNER_SETTING_UPDATE: 'partner-setting.update',
  PARTNER_SETTING_DELETE: 'partner-setting.delete',
  SETTINGS_LIST: 'settings.list',
  SETTINGS_VIEW: 'settings.view',
  SETTINGS_CREATE: 'settings.create',
  SETTINGS_UPDATE: 'settings.update',
  SETTINGS_DELETE: 'settings.delete',
  COMMENT_LIST: 'comments.list',
  COMMENT_DELETE: 'comments.delete',
  COMMENT_REPORT: 'comments.report',
  REPORT_VIOLATIONS_LIST: 'comments.list-report',
  REPORT_VIOLATIONS_VIEW: 'report-violations.view',
  REPORT_VIOLATIONS_CREATE: 'report-violations.create',
  REPORT_VIOLATIONS_UPDATE: 'report-violations.update',
  REPORT_VIOLATIONS_DELETE: 'report-violations.delete',
  DASHBOARD: 'dashboard.view',
  LOGS_USER_LIST: 'logs-user.list',
  LOGS_USER_VIEW: 'logs-user.view',
  LOGS_USER_KEYWORD: 'logs-user.keyword',
  USER_STATISTICS_USER_LIST: 'user-statistic.overall',
  USER_STATISTICS_MEDIA_LIST: 'user-statistic.partner',
  USER_STATISTICS_CHANNEL_LIST: 'user-statistic.channel',
  USER_STATISTICS_DEVICE_LIST: 'user-statistic.device',
  USER_STATISTICS_RETENTION_LIST: 'user-statistic.retention',
  LOGS_CRM_LIST: 'logs-crm.list',
  USER_STATISTICS_USER_LOGS: 'user-statistic.logs',
  USER_STATISTICS_USER_RECEIVE_NOTIFICATION: 'user-statistic.allow-push',
  USER_STATISTICS_USER_STATISTICS: 'user-statistic.overall',
  USER_STATISTICS_MEDIA_STATISTICS: 'user-statistic.partner',
  USER_STATISTICS_CHANNEL_STATISTICS: 'user-statistic.channel',
  USER_STATISTICS_DEVICE_STATISTICS: 'user-statistic.device',
  USER_STATISTICS_RETENTION: 'user-statistic.retention',
  PUSH_USER_INTERESTS_LIST: 'notifications.get-category',
  PUSH_USER_INTERESTS_UPDATE: 'notifications.update-category',
  EMERGENCY_NOTIFICATIONS_LIST: 'emergency-notifications.list',
  EMERGENCY_NOTIFICATIONS_VIEW: 'emergency-notifications.view',
  EMERGENCY_NOTIFICATIONS_CREATE: 'emergency-notifications.create',
  EMERGENCY_NOTIFICATIONS_UPDATE: 'emergency-notifications.update',
  EMERGENCY_NOTIFICATIONS_DELETE: 'emergency-notifications.delete',
  MANUAL_NOTIFICATIONS_LIST: 'manual-notifications.get-schedule',
  CUSTOM_NOTIFICATIONS_SENDING_LIST: 'custom-notifications.list',
  CUSTOM_NOTIFICATIONS_LOGS: 'custom-notifications.logs',
  CUSTOM_NOTIFICATIONS_SEND: 'custom-notifications.send',
  CUSTOM_NOTIFICATIONS_CANCEL: 'custom-notifications.cancel',
  MANUAL_NOTIFICATIONS_CREATE: 'manual-notifications.create-schedule',
  MANUAL_NOTIFICATIONS_CANCEL: 'manual-notifications.cancel-schedule',
  PUSH_STATISTICS_PUSH_LOGS: 'push-statistic.logs',
  PUSH_STATISTICS_PUSH_STATISTICS: 'push-statistic.overall',
  PUSH_STATISTICS_MEDIA_STATISTICS: 'push-statistic.partner',
  PUSH_STATISTICS_CHANNEL_STATISTICS: 'push-statistic.channel',
  PUSH_STATISTICS_CATEGORY_STATISTICS: 'push-statistic.category',
  SETTING_APP_LIST: 'settings.view-app',
  SETTING_APP_UPDATE: 'settings.update-app',
  SETTING_ADS_LIST: 'settings.view-ads',
  SETTING_ADS_UPDATE: 'settings.update-ads',
  SETTING_CSS_LIST: 'settings.view-css',
  SETTING_CSS_UPDATE: 'settings.update-css',
  SETTING_INTEGRATION_LIST: 'settings.view-integration',
  SETTING_INTEGRATION_UPDATE: 'settings.update-integration',
  SDK_LIST: 'sdk.list',
  SDK_VIEW: 'sdk.view',
  SDK_CREATE: 'sdk.create',
  SDK_UPDATE: 'sdk.update',
  SDK_DELETE: 'sdk.delete',
  TICKETS_LIST: 'tickets.list',
  TICKETS_VIEW: 'tickets.view',
  TICKETS_CREATE: 'tickets.create',
  TICKETS_UPDATE: 'tickets.update',
  TICKETS_DELETE: 'tickets.delete',
  TICKETS_CREATE_COMMENT: 'tickets.create-comment',
  TICKETS_UPDATE_COMMENT: 'tickets.update-comment',
  TICKETS_DELETE_COMMENT: 'tickets.delete-comment',
  INTEGRATION_SETTINGS_LIST: 'integration-settings.view',
  INTEGRATION_SETTINGS_UPDATE: 'integration-settings.update',
  ACCOUNT_LIST: 'account.list',
  ACCOUNT_VIEW: 'account.view',
  ACCOUNT_CREATE: 'account.create',
  ACCOUNT_UPDATE: 'account.update',
  ACCOUNT_DELETE: 'account.delete',
  MEDIA_NOTIFICATIONS_LIST: 'media-notifications.list',
  MEDIA_NOTIFICATIONS_UPDATE: 'media-notifications.update',
}

const isHavePermissionRole = (
  keyRoles: string | string[],
  permissions: {id: string | number; name: string}[] = []
): boolean => {
  const roles = typeof keyRoles === 'string' ? [keyRoles] : keyRoles
  return permissions?.some((item) => roles?.includes(item.name))
}

export {ROLES_FOR_APP, isHavePermissionRole}
