import clsx from 'clsx'
import React, {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {IPagination} from '../../../api/models/_public'
import Pagination from '../paginations/Pagination'
import Draggable from './Draggable'
import './style.scss'
import {KTIcon} from '../../../helpers'

type Row = {
  id: string
}

export type Column = {
  title: string | React.ReactNode
  className?: string
  classNameComponent?: string
  width?: number | string
  label?: string
  isNone?: boolean
  component?: (row: Row) => void
  click?: (row: Row) => void
}

type Props = {
  className: string
  classNameHeader?: string
  columns?: Column[]
  data?: any[]
  pagination?: IPagination
  componentBottom?: React.ReactNode
  isShowPagination?: boolean
  isHasColAction?: boolean
  isShowNumber?: boolean
  isLoading?: boolean
  isShowColorStart?: boolean
  widthNo?: number
  dataTextLoadingColAction?: string
  onChangePage?: (numberPage: number) => void
  handleSort?: (key: string, sort_type: SortStateType) => void
  isReSetStateSort?: boolean
}

const PAGINATION_DEFAULT = {currentPage: 1, perPage: 10, total: 0}
const COLORS_START = ['success', 'primary', 'warning', 'info', 'danger', 'secondary', 'dark']
type SortStateType = 'asc' | 'desc' | ''
const Tables: React.FC<Props> = ({
  className,
  classNameHeader = 'text-start text-muted fw-bolder fs-7 text-uppercase gs-0',
  isShowColorStart = false,
  columns = [],
  data = [],
  isLoading = false,
  pagination = PAGINATION_DEFAULT,
  widthNo = 50,
  onChangePage,
  componentBottom,
  isShowPagination = true,
  isHasColAction = false,
  isShowNumber = true,
  dataTextLoadingColAction = '',
  handleSort,
  isReSetStateSort,
}) => {
  const intl = useIntl()
  const {currentPage, perPage, total} = pagination
  const columnsShow = columns?.filter((column) => !column?.isNone)
  const numberPageStart = perPage * Number(currentPage - 1) + 1
  const handleChangePage = (numberPage: number) => {
    onChangePage && onChangePage(numberPage)
  }
  const tableRef = useRef<any>(null)

  const [sortState, setSortState] = React.useState<SortStateType>('')
  const [sortColumn, setSortColumn] = React.useState<string>('')
  useEffect(() => {
    setSortState('')
  }, [isReSetStateSort])
  return (
    <>
      {/* begin::Body */}
      <div className='card-body py-4'>
        {/* begin::Table container */}
        <div className='table-responsive' ref={tableRef}>
          <Draggable>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='fs-7 fw-bold'>
                <tr className={`${classNameHeader} `}>
                  {isShowNumber && (
                    <th
                      className='min-w-50px text-center fs-7 fw-bold  ps-3'
                      style={{width: `${widthNo}px`}}
                      key={0}
                    >
                      no.
                    </th>
                  )}
                  {columnsShow?.map((column: any, index) => (
                    <th
                      className={`pe-3 fs-7 fw-bold  ${column?.className || ''}`}
                      key={index}
                      style={{width: `${column?.width}`}}
                      onClick={() => {
                        if (!column.is_sort) return
                        const sort_type =
                          sortState === '' ? 'asc' : sortState === 'asc' ? 'desc' : 'asc'
                        setSortColumn(column.label)
                        setSortState(sort_type)

                        handleSort && handleSort(column.label, sort_type)
                      }}
                    >
                      <div
                        className={`${
                          column.is_sort ? 'd-flex align-items-center justify-content-center' : ''
                        }  ${column?.className || ''} `}
                      >
                        {column?.title || ''}
                        {column.is_sort && (
                          <div
                            aria-disabled={isLoading}
                            className='ms-2 cursor-pointer d-flex flex-column justify-content-center align-items-end'
                            onClick={() => {
                              const sort_type =
                                sortState === '' ? 'asc' : sortState === 'asc' ? 'desc' : 'asc'
                              setSortColumn(column.label)
                              setSortState(sort_type)

                              handleSort && handleSort(column.label, sort_type)
                            }}
                          >
                            <KTIcon
                              iconName={'up'}
                              className={`fs-7 ${
                                sortState === 'asc' && sortColumn === column.label
                                  ? 'fw-bold'
                                  : 'text-secondary'
                              } mb-n2`}
                            />
                            <KTIcon
                              iconName={'down'}
                              className={`fs-7 ${
                                sortState === 'desc' && sortColumn === column.label
                                  ? 'fw-bold'
                                  : 'text-secondary'
                              } mb-n1`}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th
                    colSpan={columnsShow?.length + Number(isShowNumber)}
                    className='py-0 px-0'
                    style={{borderBottomColor: 'transparent'}}
                  >
                    <div className={clsx(`infinite-loading-outer`, {'is-show-loading': isLoading})}>
                      <div className='infinite-loading-inner'></div>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='text-gray-600'>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row: any, key) => (
                      <tr key={row.id || key} className='bg-hover-light'>
                        {isShowNumber && (
                          <td className={clsx('text-center ps-3')} key={numberPageStart + key}>
                            {isShowColorStart ? (
                              <div className='d-flex align-items-center  justify-content-start'>
                                <span
                                  className={`bullet bullet-vertical h-35px bg-${
                                    COLORS_START[key % COLORS_START?.length]
                                  }  me-3`}
                                ></span>
                                <span>{numberPageStart + key}</span>
                              </div>
                            ) : (
                              numberPageStart + key
                            )}
                          </td>
                        )}
                        {columnsShow?.map((column, index) => (
                          <td
                            className={`fs-6 text-gray-600 fw-bold ${
                              column?.classNameComponent || 'text-center'
                            } pe-3`}
                            key={index + 2}
                            onClick={() => {
                              column?.click && column?.click(row)
                            }}
                          >
                            <>
                              {column?.component
                                ? column?.component(row)
                                : row[column?.label || '']}
                            </>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      className='w-100 text-center pt-6'
                      colSpan={columnsShow?.length + Number(isShowNumber)}
                    >
                      {intl.formatMessage({id: 'TEXT_NO_DATA'})}
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
          </Draggable>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {isShowPagination && (
          <div className='row g-6 g-xl-9'>
            <div className={`${componentBottom ? 'col-xl-10' : 'col-12'} text-center mt-10 mb-5`}>
              <Pagination
                page={currentPage}
                pageSize={perPage}
                totalItem={total}
                onChangePage={handleChangePage}
                className='justify-content-end'
              />
            </div>
            {componentBottom && (
              <div className='col-xl-2 col-md-3 text-end'>
                <>{componentBottom}</>
              </div>
            )}
          </div>
        )}
      </div>
      {/* begin::Body */}
    </>
  )
}

export {Tables}
