import {Skeleton, Space} from 'antd'

export default function LoadingNotification() {
  return (
    <div className='d-flex py-5 gap-8 flex-column'>
      <Space className='px-5'>
        <Skeleton.Avatar active shape='square' style={{borderRadius: 4}} />
        <div className='w-100 d-flex flex-column'>
          <Skeleton.Input active style={{height: 15, width: 180}} size='small' />
          <Skeleton.Input active style={{height: 15, width: 180, marginTop: 1}} size='small' />
        </div>
        <Skeleton.Input
          active
          style={{height: 15, marginTop: 1, marginLeft: 16, width: 70, minWidth: 70}}
          size='small'
        />
      </Space>
      <Space className='px-5'>
        <Skeleton.Avatar active shape='square' style={{borderRadius: 4}} />
        <div className='w-100 d-flex flex-column'>
          <Skeleton.Input active style={{height: 15, width: 180}} size='small' />
          <Skeleton.Input active style={{height: 15, width: 180, marginTop: 1}} size='small' />
        </div>
        <Skeleton.Input
          active
          style={{height: 15, marginTop: 1, marginLeft: 16, width: 70, minWidth: 70}}
          size='small'
        />
      </Space>
      <Space className='px-5'>
        <Skeleton.Avatar active shape='square' style={{borderRadius: 4}} />
        <div className='w-100 d-flex flex-column'>
          <Skeleton.Input active style={{height: 15, width: 180}} size='small' />
          <Skeleton.Input active style={{height: 15, width: 180, marginTop: 1}} size='small' />
        </div>
        <Skeleton.Input
          active
          style={{height: 15, marginTop: 1, marginLeft: 16, width: 70, minWidth: 70}}
          size='small'
        />
      </Space>
      <Space className='px-5'>
        <Skeleton.Avatar active shape='square' style={{borderRadius: 4}} />
        <div className='w-100 d-flex flex-column'>
          <Skeleton.Input active style={{height: 15, width: 180}} size='small' />
          <Skeleton.Input active style={{height: 15, width: 180, marginTop: 1}} size='small' />
        </div>
        <Skeleton.Input
          active
          style={{height: 15, marginTop: 1, marginLeft: 16, width: 70, minWidth: 70}}
          size='small'
        />
      </Space>
      <Space className='px-5'>
        <Skeleton.Avatar active shape='square' style={{borderRadius: 4}} />
        <div className='w-100 d-flex flex-column'>
          <Skeleton.Input active style={{height: 15, width: 180}} size='small' />
          <Skeleton.Input active style={{height: 15, width: 180, marginTop: 1}} size='small' />
        </div>
        <Skeleton.Input
          active
          style={{height: 15, marginTop: 1, marginLeft: 16, width: 70, minWidth: 70}}
          size='small'
        />
      </Space>
    </div>
  )
}
