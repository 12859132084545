import {apiService} from '../../helpers/axios'
import {Partner, PostPartner, PostPartnerSetting} from '../models/_partner'
import {ParamsRequestGetList, ResponseApi, ResponseData} from '../models/_public'

const BASE_URL_UPLOAD_FILE = process.env.REACT_APP_BASE_URL_UPLOAD_FILE || ''

export function fetchPartner(
  params: ParamsRequestGetList
): Promise<ResponseApi<ResponseData<any[]>>> {
  return apiService.get('/partners', {params})
}

export function postCreatePartner(dataForm: Partial<PostPartner>): Promise<ResponseApi<Partner>> {
  const formData = new FormData()
  formData.append('name', dataForm?.name ?? '')
  formData.append('application_id', dataForm?.application_id ?? '')
  formData.append('app_id', String(dataForm?.app_id ?? ''))
  formData.append('app_token', String(dataForm?.app_token ?? ''))
  formData.append('type', String(dataForm?.type ?? ''))
  formData.append('manager', dataForm?.manager ?? '')
  formData.append('contact', dataForm?.contact ?? '')
  formData.append('email', dataForm?.email ?? '')
  formData.append('ads_type', dataForm?.ads_type ?? '')
  formData.append('hide_channel_select', dataForm?.hide_channel_select ? '1' : '0')
  // formData.append('status', String(Number(dataForm?.status )));
  // formData.append('is_user_request', String(0));
  return apiService.post(`/partners`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': 'BlockBlob',
    },
  })
}

export function postUpdatePartner(
  idPartner: string,
  dataForm: Partial<PostPartner>
): Promise<ResponseApi<Partner>> {
  const formData = new FormData()
  formData.append('name', dataForm?.name ?? '')
  formData.append('application_id', dataForm?.application_id ?? '')
  formData.append('app_id', String(dataForm?.app_id ?? ''))
  formData.append('app_token', String(dataForm?.app_token ?? ''))
  formData.append('type', String(dataForm?.type ?? ''))
  formData.append('manager', dataForm?.manager ?? '')
  formData.append('contact', dataForm?.contact ?? '')
  formData.append('email', dataForm?.email ?? '')
  formData.append('ads_type', dataForm?.ads_type ?? '')
  formData.append('hide_channel_select', dataForm?.hide_channel_select ? '1' : '0')
  // formData.append('status', String(Number(dataForm?.status )));
  // formData.append('is_user_request', String(0));
  return apiService.post(`/partners/${idPartner}`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': 'BlockBlob',
    },
  })
}

export function postUpdatePartnerCategory(dataForm: Partial<any>): Promise<ResponseApi<any>> {
  return apiService.post(`/partner-category`, dataForm)
}

export function postUpdatePartnerCategoryStatus(dataForm: Partial<any>): Promise<ResponseApi<any>> {
  return apiService.post(`/partners/category-status`, dataForm)
}

export function postUpdatePartnerPressStatus(dataForm: Partial<any>): Promise<ResponseApi<any>> {
  return apiService.post(`/partner-channel`, dataForm)
}

export function postUpdatePartnerCategoryDefault(
  dataForm: Partial<any>
): Promise<ResponseApi<any>> {
  return apiService.post(`/partners/category-default`, dataForm)
}

export function postUpdateStatusPartner(
  idPartner: string,
  status: boolean | number
): Promise<ResponseApi<Partner>> {
  return apiService.post(`/partners/${idPartner}`, {status: Number(status)})
}

export function postDeletePartner(
  idPartner: string,
  dataForm: Partial<PostPartner> | any = {}
): Promise<ResponseApi<Partner>> {
  return apiService.delete(`/partners/${idPartner}`, {data: dataForm})
}

export function postDeletePartnerCategory(
  dataForm: Partial<any> | any = {}
): Promise<ResponseApi<any>> {
  return apiService.delete(`/partner-category`, {data: dataForm})
}

export function postCreatePartnerSetting(
  dataForm: Partial<PostPartnerSetting>
): Promise<ResponseApi<Partner>> {
  const formData = new FormData()
  formData.append('partner_id', dataForm?.partner_id ?? '')
  formData.append('hide_channel_select', String(Number(dataForm?.hide_channel_select)))

  return apiService.post(`/partner-setting`, formData, {
    baseURL: BASE_URL_UPLOAD_FILE,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-ms-blob-type': 'BlockBlob',
    },
  })
}

export function postUpdatePartnerSetting(
  idPartner: string,
  dataForm: Partial<PostPartnerSetting>
): Promise<ResponseApi<Partner>> {
  const formData = new FormData()
  formData.append('partner_id', dataForm?.partner_id ?? '')
  formData.append('limit_notification', String(Number(dataForm?.limit_notification)))
  formData.append('delivery_time', dataForm?.delivery_time ?? [])

  return apiService.post(`/partner-setting/${idPartner}`, dataForm, {
    baseURL: BASE_URL_UPLOAD_FILE,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    //   'x-ms-blob-type': 'BlockBlob',
    // },
  })
}
