import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { CSForm } from './components/CSForm'
import { CSList } from './components/CSList'

const CSPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <CSList />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <CSForm />
            </>
          }
        />
        <Route
          path=':id'
          element={
            <>
              <CSForm />
            </>
          }
        />
        <Route index element={<Navigate to='/cs/list' />} />
      </Route>
    </Routes>
  )
}

export default CSPage
