export default function LoadingAdSetting() {
  return (
    <div className='row p-0 mt-2'>
      <div className='col-12'>
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '30% auto',
          }}
        >
          {/* begin::Body */}
          <div className='col-12 justify-content-between d-flex card-body pt-0 px-0 px-md-8 px-xl-16 px-xxl-20 placeholder-glow'>
            <div className='w-800px'>
              {/* eslint-disable-next-line */}
              <a href='#' className='card-title  fw-bold text-muted text-hover-primary fs-2'>
                <span className='placeholder bg-secondary col-3 rounded mb-2'></span>
              </a>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='placeholder bg-secondary col-5 rounded'></span>
              </div>
            </div>
            <p className='col-3 text-dark-75 fw-semibold fs-5 m-0 mt-4 text-end'>
              <span className='placeholder bg-secondary col-10 h-30px rounded'></span>
            </p>
          </div>

          {/* end::Body */}
        </div>
      </div>
      <div className='col-12'>
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '30% auto',
          }}
        >
          {/* begin::Body */}
          <div className='col-12 justify-content-between d-flex card-body pt-0 px-0 px-md-8 px-xl-16 px-xxl-20 placeholder-glow'>
            <div className='w-800px'>
              {/* eslint-disable-next-line */}
              <a href='#' className='card-title  fw-bold text-muted text-hover-primary fs-2'>
                <span className='placeholder bg-secondary col-3 rounded mb-2'></span>
              </a>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='placeholder bg-secondary col-5 rounded'></span>
              </div>
            </div>
            <p className='col-3 text-dark-75 fw-semibold fs-5 m-0 mt-4 text-end'>
              <span className='placeholder bg-secondary col-10 h-30px rounded'></span>
            </p>
          </div>

          {/* end::Body */}
        </div>
      </div>
      <div className='col-12'>
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '30% auto',
          }}
        >
          {/* begin::Body */}
          <div className='col-12 justify-content-between d-flex card-body pt-0 px-0 px-md-8 px-xl-16 px-xxl-20 placeholder-glow'>
            <div className='w-800px'>
              {/* eslint-disable-next-line */}
              <a href='#' className='card-title  fw-bold text-muted text-hover-primary fs-2'>
                <span className='placeholder bg-secondary col-3 rounded mb-2'></span>
              </a>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='placeholder bg-secondary col-5 rounded'></span>
              </div>
            </div>
            <p className='col-3 text-dark-75 fw-semibold fs-5 m-0 mt-4 text-end'>
              <span className='placeholder bg-secondary col-10 h-30px rounded'></span>
            </p>
          </div>

          {/* end::Body */}
        </div>
      </div>
    </div>
  )
}
