import {useQuery} from '@tanstack/react-query'
import {useCallback, useState} from 'react'
import {IParamsSearch} from '../../../api/models/_public'
import {fetchDashboardOs} from '../../../api/requests/_dashboardOs'
import {QUERIES} from '../../crud-helper/consts'
import {getStartDateEndDate} from '../../crud-helper/helpers'

const NUMBER_PAGE_DEFAULT: number = 1
type Props = {
  start_date?: string
  end_date?: string
}
const date = getStartDateEndDate(7)

const useDashboardDeviceOs = ({start_date = date[0], end_date = date[1]}: Props) => {
  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    // page: NUMBER_PAGE_DEFAULT,
    // keyword:'',
    // limit: 10,
    // status: '',
    start_date: start_date,
    end_date: end_date,
  })

  const fetchPaginatedData = async ({queryKey, pageParam = NUMBER_PAGE_DEFAULT}: any) => {
    const res = await fetchDashboardOs({...queryKey[1]})
    return res?.data || {}
  }

  // eslint-disable-next-line
  const {status, data, error, isFetching, isPreviousData, refetch, isLoading, ...root} = useQuery({
    queryKey: [QUERIES.DASHBOARD_OS, searchParams],
    queryFn: fetchPaginatedData,
    keepPreviousData: true,
    staleTime: 5000,
  })

  const handleChangeParams = useCallback((params: IParamsSearch) => {
    setSearchParams((prevState) => {
      return {...prevState, ...params}
    })
  }, [])

  return {
    data: data,
    status,
    error,
    isFetching,
    isPreviousData,
    refetch,
    searchParams,
    handleChangeParams,
    isLoading,
  }
}

export default useDashboardDeviceOs
