import clsx from 'clsx'
import {FieldProps} from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './style.scss'
import {Button} from 'antd'
import {KTIcon} from '../../../../helpers'
import {useState} from 'react'
import {useIntl} from 'react-intl'
interface CustomInputProps extends FieldProps {
  label?: string
  className?: string
  placeholder?: string
  isRequired?: boolean
  isFullWidth?: boolean
  numberRow?: number
  isEditor?: boolean
  defaultValue?: any
  colLeft?: number
  colRight?: number
  typeInput?: string
  isSettingTheme?: boolean
  isPassword?: boolean
  handleUpdateTypePassword?: any
  isLabelCenter?: boolean
}

export const CustomInput = ({
  isPassword = false,
  label,
  className,
  placeholder,
  isRequired = true,
  isFullWidth = false,
  numberRow = 1,
  isEditor = false,
  defaultValue,
  colLeft = 3,
  colRight = 6,
  field,
  form,
  typeInput = 'text',
  isSettingTheme = false,
  handleUpdateTypePassword,
  isLabelCenter = false,
  ...props
}: CustomInputProps) => {
  const arrField: any[] = field?.name?.includes('translations') ? field?.name?.split('.') : []
  const [iconEye, setIconEye] = useState('eye-slash')
  const intl = useIntl()
  const onChange = (event: any) => {
    // eslint-disable-next-line
    if (typeInput == 'number') {
      const _value = isEditor ? event : event?.target?.value?.replace(/[^0-9]/g, '')
      form.setFieldValue(field.name, _value)
    } else {
      const _value = isEditor ? event : event?.target?.value
      form.setFieldValue(field.name, _value)
    }
  }

  const getValue = () => {
    return field.value || ''
  }

  const generatePassword = () => {
    let length = 8
    let password = ''
    let hasDigit = false

    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

    for (let i = 0; i < length; i++) {
      let randomChar = charset.charAt(Math.floor(Math.random() * charset.length))
      password += randomChar

      if (/\d/.test(randomChar)) {
        hasDigit = true
      }
    }

    if (!hasDigit) {
      let randomDigit = '0123456789'.charAt(Math.floor(Math.random() * 10))
      password = password.replace(/./, randomDigit)
    }

    return password
  }

  return (
    <>
      <div
        className={`col-lg-${isFullWidth ? 12 : colLeft} ${
          isLabelCenter ? 'd-flex align-items-center' : ''
        } col-md-${isFullWidth ? 12 : colLeft} mb-md-${isFullWidth ? 1 : 0} fs-mc-16 ${
          isSettingTheme ? 'px-0 mx-0 w-100' : ''
        }`}
      >
        {label && (
          <label
            className={clsx(`col-form-label p-0 form-custom-label form-control-solid `, {
              required: isRequired,
            })}
          >
            {label}
          </label>
        )}
      </div>
      <div
        className={`col-lg-${isFullWidth ? 12 : colRight} ${
          isSettingTheme ? 'px-0 mx-0 w-100' : ''
        } col-md-${isFullWidth ? 12 : colRight} ${isPassword ? 'd-flex justify-content-end' : ''}`}
      >
        {numberRow === 1 ? (
          <div className='position-relative w-100'>
            <div className='position-relative w-100'>
              <input
                {...props}
                className={className}
                name={field.name}
                value={getValue()}
                onChange={onChange}
                placeholder={placeholder}
                type={typeInput}
              />

              {isPassword && (
                <div
                  onClick={() => {
                    handleUpdateTypePassword()
                    setIconEye((prev) => (prev === 'eye' ? 'eye-slash' : 'eye'))
                  }}
                >
                  <KTIcon
                    iconName={`${iconEye}`}
                    className='fs-2 position-absolute top-50 end-0 me-2 translate-middle-y cursor-pointer'
                  />
                </div>
              )}
            </div>
            {isPassword
              ? arrField?.length > 0
                ? // @ts-ignore
                  form.errors.translations &&
                  // @ts-ignore
                  form.errors.translations[String(arrField[1] || '')] && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {
                          // @ts-ignore
                          form.errors.translations[String(arrField[1] || '')][
                            String(arrField[2] || '')
                          ]
                        }
                      </div>
                    </div>
                  )
                : form.errors[field.name] && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{form.errors[field.name] as string}</div>
                    </div>
                  )
              : ''}
          </div>
        ) : isEditor ? (
          <ReactQuill
            theme='snow'
            {...props}
            className={className}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={placeholder}
            // formats={[
            //   'header',
            //   'font',
            //   'size',
            //   'bold',
            //   'italic',
            //   'underline',
            //   'strike',
            //   'blockquote',
            //   'list',
            //   'bullet',
            //   'indent',
            //   'link',
            //   'image',
            //   'color',
            // ]}
          />
        ) : (
          <textarea
            rows={numberRow}
            {...props}
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            placeholder={placeholder}
          ></textarea>
        )}
        {!isPassword
          ? arrField?.length > 0
            ? // @ts-ignore
              form.errors.translations &&
              // @ts-ignore
              form.errors.translations[String(arrField[1] || '')] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {
                      // @ts-ignore
                      form.errors.translations[String(arrField[1] || '')][String(arrField[2] || '')]
                    }
                  </div>
                </div>
              )
            : form.errors[field.name] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{form.errors[field.name] as string}</div>
                </div>
              )
          : ''}
        {isPassword && (
          <Button
            onClick={() => form.setFieldValue(field.name, generatePassword())}
            type='link'
            color='primary'
            className='mt-2'
          >
            {intl.formatMessage({id: 'TEXT_GENERATE_PASSWORD'})}
          </Button>
        )}
      </div>
    </>
  )
}

export default CustomInput
