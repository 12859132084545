import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useCallback} from 'react'
import {fetchAdMedia} from '../../../api/requests/_adMedia'
import {QUERIES} from '../../crud-helper/consts'

const NUMBER_PAGE_DEFAULT: number = 1

const useListAdMedia = ({id}: any) => {
  const queryClient = useQueryClient()

  const fetchPaginatedData = async ({queryKey, pageParam = NUMBER_PAGE_DEFAULT}: any) => {
    const res = await fetchAdMedia(id)
    return res?.data
  }

  // eslint-disable-next-line
  const {status, data, error, isFetching, isPreviousData, refetch, ...root} = useQuery({
    queryKey: [QUERIES.AD_MEDIA],
    queryFn: fetchPaginatedData,
    keepPreviousData: false,
    staleTime: 0,
  })

  const handleSetQueryDataItem = useCallback(
    (itemId: any, isActive: string | number | boolean) => {
      queryClient.setQueryData([QUERIES.CHANNEL_LIST], (_oldData: any) => {
        return {
          ..._oldData,
          items: _oldData?.items?.map((item: any) =>
            item?.id === itemId ? {...item, status: isActive} : item
          ),
        }
      })
    },
    [queryClient]
  )

  return {
    data: data?.settings,
    //@ts-ignore
    // listOptionChannel:
    //   Number(get_all || 0) === 1 && data
    //     ? data.map((item) => ({label: item?.name, value: item?.id}))
    //     : [],
    status,
    error,
    isFetching,
    isPreviousData,
    refetch,
    handleSetQueryDataItem,
  }
}

export default useListAdMedia
