/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {ROLES_FOR_APP, isHavePermissionRole} from '../../../../constants/role'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  // Get full permissions of account login
  const {currentUser: {roles: {permissions = []} = {}} = {}} = useAuth()

  const isHavePermissionNewsPage =
    isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.CHANNELS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.CATEGORIES_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.PARTNERS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.NEWS_FILTER_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.COMMENT_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.REPORT_VIOLATIONS_LIST, permissions)

  const isHavePermissionUserPage =
    isHavePermissionRole(ROLES_FOR_APP.DEVICES_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.DEVICES_PRESS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.DEVICES_KEYWORD, permissions)

  const isHavePermissionUserStatisticsPage =
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_USER_LOGS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_USER_RECEIVE_NOTIFICATION, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_USER_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_MEDIA_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_CHANNEL_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_DEVICE_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_RETENTION, permissions)

  const isHavePermissionPushStatisticsPage =
    isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_PUSH_LOGS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_PUSH_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_MEDIA_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_CHANNEL_STATISTICS, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_CATEGORY_STATISTICS, permissions)

  const isHavePermissionSettingPage =
    isHavePermissionRole(ROLES_FOR_APP.SETTING_APP_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.SETTING_CSS_LIST, permissions)
  const isHavePermissionCRMMediaPage =
    isHavePermissionRole(ROLES_FOR_APP.ACCOUNT_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.SDK_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.TICKETS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.INTEGRATION_SETTINGS_LIST, permissions)

  const isHavePermissionPushNotificationPage =
    isHavePermissionRole(ROLES_FOR_APP.PUSH_USER_INTERESTS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.MEDIA_NOTIFICATIONS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.EMERGENCY_NOTIFICATIONS_LIST, permissions) ||
    isHavePermissionRole(ROLES_FOR_APP.MANUAL_NOTIFICATIONS_LIST, permissions)
  return (
    <>
      {isHavePermissionRole(ROLES_FOR_APP.DASHBOARD, permissions) && (
        <SidebarMenuItem
          icon='chart-simple'
          fontIcon='bi-app-indicator'
          to='/dashboard'
          title={intl.formatMessage({id: 'MENU_DASHBOARD'})}
        />
      )}

      {isHavePermissionNewsPage && (
        <SidebarMenuItemWithSub
          to='/news-management'
          title={intl.formatMessage({id: 'MENU_NEWS'})}
          icon='element-1'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/news-list'
              title={intl.formatMessage({id: 'MENU_NEWS.NEWS_CONTENT'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.CHANNELS_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/channel'
              title={intl.formatMessage({id: 'MENU_NEWS.MEDIA_MANAGEMENT'})}
              hasBullet={true}
            />
          )}

          {isHavePermissionRole(ROLES_FOR_APP.CATEGORIES_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/category'
              title={intl.formatMessage({id: 'MENU_NEWS.CATEGORY_MANAGEMENT'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.PARTNERS_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/list-media'
              title={intl.formatMessage({id: 'MENU_NEWS.MEDIA'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.NEWS_FILTER_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/media-filter'
              title={intl.formatMessage({id: 'MENU_NEWS.MEDIA_FILTER'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.COMMENT_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/list-comment'
              title={intl.formatMessage({id: 'MENU_NEWS.COMMENT'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.REPORT_VIOLATIONS_LIST, permissions) && (
            <SidebarMenuItem
              to='/news-management/comment-report'
              title={intl.formatMessage({id: 'MENU_NEWS.COMMENT_REPORT'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {isHavePermissionUserPage && (
        <SidebarMenuItemWithSub
          to='/user-management'
          title={intl.formatMessage({id: 'MENU_USER'})}
          icon='user'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.DEVICES_LIST, permissions) && (
            <SidebarMenuItem
              to='/user-management/user-list'
              title={intl.formatMessage({id: 'MENU_USER.USER_LIST'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.DEVICES_PRESS, permissions) && (
            <SidebarMenuItem
              to='/user-management/user-press'
              title={intl.formatMessage({id: 'MENU_USER.USER_MEDIA'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.DEVICES_KEYWORD, permissions) && (
            <SidebarMenuItem
              to='/user-management/user-keyword'
              title={intl.formatMessage({id: 'MENU_USER.USER_NEWS_KEYWORD'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {isHavePermissionUserStatisticsPage && (
        <SidebarMenuItemWithSub
          to='/user-statistics'
          title={intl.formatMessage({id: 'MENU_USER_STATISTICS'})}
          icon='graph-2'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_USER_LOGS, permissions) && (
            <SidebarMenuItem
              to='/user-statistics/user-log'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.USER_LOG'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(
            ROLES_FOR_APP.USER_STATISTICS_USER_RECEIVE_NOTIFICATION,
            permissions
          ) && (
            <SidebarMenuItem
              to='/user-statistics/user-receive-notification'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.USER_RECEIVE_NOTIFICATION'})}
              hasBullet={true}
            />
          )}

          {isHavePermissionRole(
            ROLES_FOR_APP.USER_STATISTICS_USER_RECEIVE_NOTIFICATION,
            permissions
          ) && (
            <SidebarMenuItem
              to='/user-statistics/user-statistics'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.OVERALL_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_MEDIA_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/user-statistics/media-statistics'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.MEDIA_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_CHANNEL_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/user-statistics/channel-statistics'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.CHANNEL_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_DEVICE_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/user-statistics/device-statistics'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.DEVICE_STATISTICS'})}
              hasBullet={true}
            />
          )}

          {isHavePermissionRole(ROLES_FOR_APP.USER_STATISTICS_RETENTION, permissions) && (
            <SidebarMenuItem
              to='/user-statistics/retention'
              title={intl.formatMessage({id: 'MENU_USER_STATISTICS.RETENTION'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {isHavePermissionRole(ROLES_FOR_APP.LOGS_CRM_LIST, permissions) && (
        <SidebarMenuItem
          to='/admin-activities-log'
          title={intl.formatMessage({id: 'MENU_ADMIN_ACTIVITIES_LOG'})}
          icon='book'
          fontIcon='bi-layers'
        />
      )}
      {isHavePermissionPushNotificationPage && (
        <SidebarMenuItemWithSub
          to='/push-notification'
          title={intl.formatMessage({id: 'MENU_PUSH_NOTIFICATION'})}
          icon='notification'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_USER_INTERESTS_LIST, permissions) && (
            <SidebarMenuItem
              to='/push-notification/user-interests'
              title={intl.formatMessage({id: 'MENU_PUSH_NOTIFICATION.USER_INTERESTS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.MEDIA_NOTIFICATIONS_LIST, permissions) && (
            <SidebarMenuItem
              to='/push-notification/media-settings'
              title={intl.formatMessage({id: 'MENU_PUSH_NOTIFICATION.MEDIA_SETTINGS'})}
              hasBullet={true}
            />
          )}

          {isHavePermissionRole(ROLES_FOR_APP.EMERGENCY_NOTIFICATIONS_LIST, permissions) && (
            <SidebarMenuItem
              to='/push-notification/emergency-settings'
              title={intl.formatMessage({id: 'MENU_PUSH_NOTIFICATION.EMERGENCY_SETTINGS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.MANUAL_NOTIFICATIONS_LIST, permissions) && (
            <SidebarMenuItem
              to='/push-notification/manual-sending'
              title={intl.formatMessage({id: 'MENU_PUSH_NOTIFICATION.MANUAL_SENDING'})}
              hasBullet={true}
            />
          )}
          {/* {isHavePermissionRole(ROLES_FOR_APP.CUSTOM_NOTIFICATIONS_SENDING_LIST, permissions) && (
            <SidebarMenuItem
              to='/push-notification/custom-notifications-sending'
              title={intl.formatMessage({
                id: 'MENU_PUSH_NOTIFICATION.CUSTOM_NOTIFICATIONS_SENDING',
              })}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.CUSTOM_NOTIFICATIONS_LOGS, permissions) && (
            <SidebarMenuItem
              to='/push-notification/custom-logs'
              title={intl.formatMessage({
                id: 'MENU_PUSH_NOTIFICATION.CUSTOM_LOG',
              })}
              hasBullet={true}
            />
          )} */}
        </SidebarMenuItemWithSub>
      )}

      {isHavePermissionPushStatisticsPage && (
        <SidebarMenuItemWithSub
          to='/push-analytics'
          title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS'})}
          icon='notification-on'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_PUSH_LOGS, permissions) && (
            <SidebarMenuItem
              to='/push-analytics/push-logs'
              title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS.PUSH_LOG'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_PUSH_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/push-analytics/push-statistics'
              title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS.OVERALL_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_MEDIA_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/push-analytics/media-statistics'
              title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS.MEDIA_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_CHANNEL_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/push-analytics/channel-statistics'
              title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS.CHANNEL_STATISTICS'})}
              hasBullet={true}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.PUSH_STATISTICS_CATEGORY_STATISTICS, permissions) && (
            <SidebarMenuItem
              to='/push-analytics/category-statistics'
              title={intl.formatMessage({id: 'MENU_PUSH_ANALYTICS.CATEGORY_STATISTICS'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {isHavePermissionRole(ROLES_FOR_APP.USERS_LIST, permissions) && (
        <SidebarMenuItem
          to='/accounts'
          icon='people'
          title={intl.formatMessage({id: 'ACCOUNT_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}

      {isHavePermissionRole(ROLES_FOR_APP.PERMISSIONS_LIST, permissions) && (
        <SidebarMenuItem
          to='/permissions'
          icon='data'
          title={intl.formatMessage({id: 'PERMISSION_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.ROLES_LIST, permissions) && (
        <SidebarMenuItem
          to='/roles'
          icon='chart'
          title={intl.formatMessage({id: 'ROLE_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}

      {isHavePermissionSettingPage && (
        <SidebarMenuItemWithSub
          to='/settings-management'
          title={intl.formatMessage({id: 'MENU_SETTINGS_MANAGEMENT'})}
          icon='setting-4'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.SETTING_APP_LIST, permissions) && (
            <SidebarMenuItem
              to='/settings-management/app-settings'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU_SETTINGS_MANAGEMENT.APP_SETTINGS'})}
            />
          )}

          {isHavePermissionRole(ROLES_FOR_APP.SETTING_CSS_LIST, permissions) && (
            <SidebarMenuItem
              to='/settings-management/css-settings'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU_SETTINGS_MANAGEMENT.CSS_SETTINGS'})}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {isHavePermissionCRMMediaPage && (
        <SidebarMenuItemWithSub
          to='/crm-media'
          title={intl.formatMessage({id: 'CRM_MEDIA_MANAGEMENT'})}
          icon='abstract-26'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.ACCOUNT_LIST, permissions) && (
            <SidebarMenuItem
              to='/crm-media/account-management'
              hasBullet={true}
              title={intl.formatMessage({id: 'CRM_MEDIA_MANAGEMENT.ACCOUNT_MANAGEMENT'})}
            />
          )}

          {isHavePermissionRole(ROLES_FOR_APP.SDK_LIST, permissions) && (
            <SidebarMenuItem
              to='/crm-media/sdk-management'
              hasBullet={true}
              title={intl.formatMessage({id: 'CRM_MEDIA_MANAGEMENT.SDK_MANAGEMENT'})}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.TICKETS_LIST, permissions) && (
            <SidebarMenuItem
              to='/crm-media/ticket-management'
              hasBullet={true}
              title={intl.formatMessage({id: 'CRM_MEDIA_MANAGEMENT.TICKET_MANAGEMENT'})}
            />
          )}
          {isHavePermissionRole(ROLES_FOR_APP.INTEGRATION_SETTINGS_LIST, permissions) && (
            <SidebarMenuItem
              to='/crm-media/integrated-instructions'
              hasBullet={true}
              title={intl.formatMessage({id: 'CRM_MEDIA_MANAGEMENT.INTEGRATE_INSTRUCTIONS'})}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* <SidebarMenuItemWithSub
        to='/content'
        title={intl.formatMessage({id: 'CONTENT_MANAGEMENT'})}
        icon='element-2'
        fontIcon='bi-layers'
      >
        {isHavePermissionRole(ROLES_FOR_APP.NEWS_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/news'
            title={intl.formatMessage({id: 'NEWS_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
        {isHavePermissionRole(ROLES_FOR_APP.CHANNELS_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/channel'
            title={intl.formatMessage({id: 'CHANNEL_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
        {isHavePermissionRole(ROLES_FOR_APP.STOCKS_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/stock'
            title={intl.formatMessage({id: 'STOCK_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
        {isHavePermissionRole(ROLES_FOR_APP.RSS_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/rss'
            title={intl.formatMessage({id: 'RSS_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
        {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_SCHEDULE_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/notification'
            title={intl.formatMessage({id: 'NOTIFICATION_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
        {isHavePermissionRole(ROLES_FOR_APP.NEWS_FILTER_VIEW, permissions) && (
          <SidebarMenuItem
            to='/content/news-filter'
            title={intl.formatMessage({id: 'NEWS_FILTER_MANAGEMENT'})}
            hasBullet={true}
          />
        )}
      </SidebarMenuItemWithSub> */}
      {/* {isHavePermissionRole(ROLES_FOR_APP.PARTNERS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/partners'
          icon='monitor-mobile'
          title={intl.formatMessage({id: 'PARTNER_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.DEVICES_LIST, permissions) && (
        <SidebarMenuItem
          to='/devices'
          icon='phone'
          title={intl.formatMessage({id: 'DEVICE_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )} */}
      {/* {isHavePermissionRole(ROLES_FOR_APP.CATEGORIES_VIEW, permissions) && (
        <SidebarMenuItemWithSub
          to='/category'
          title={intl.formatMessage({id: 'CATEGORY_MANAGEMENT'})}
          icon='category'
          fontIcon='bi-layers'
        >
          {isHavePermissionRole(ROLES_FOR_APP.CATEGORIES_LIST, permissions) && (
            <SidebarMenuItem
              to='/category/list'
              title={intl.formatMessage({id: 'CATEGORY_MANAGEMENT'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )} */}
      {/* {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGNS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/campaign'
          icon='abstract-35'
          title={intl.formatMessage({id: 'CAMPAIGN_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.CONTENTS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/material'
          icon='abstract-25'
          title={intl.formatMessage({id: 'MATERIAL_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.USERS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/membership'
          icon='profile-user'
          title={intl.formatMessage({id: 'MEMBERSHIP_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.INQUIRIES_VIEW, permissions) && (
        <SidebarMenuItem
          to='/cs'
          icon='delivery-24'
          title={intl.formatMessage({id: 'INQUIRIES_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.USERS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/users'
          icon='people'
          title={intl.formatMessage({id: 'USER_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.KEYWORDS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/keywords'
          icon='illustrator'
          title={intl.formatMessage({id: 'KEYWORD_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_GROUPS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/advertiser-groups'
          icon='clipboard'
          title={intl.formatMessage({id: 'ADVERTISER_GROUP_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      // {isHavePermissionRole(ROLES_FOR_APP.PERMISSIONS_LIST, permissions) && (
      //   <SidebarMenuItem
      //     to='/permissions'
      //     icon='data'
      //     title={intl.formatMessage({id: 'PERMISSION_MANAGEMENT'})}
      //     fontIcon='bi-app-indicator'
      //   />
      // )}
      {isHavePermissionRole(ROLES_FOR_APP.ROLES_VIEW, permissions) && (
        <SidebarMenuItem
          to='/roles'
          icon='chart'
          title={intl.formatMessage({id: 'ROLE_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.LANGUAGES_VIEW, permissions) && (
        <SidebarMenuItem
          to='/languages'
          icon='icon'
          title={intl.formatMessage({id: 'LANGUAGE_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHavePermissionRole(ROLES_FOR_APP.SETTINGS_VIEW, permissions) && (
        <SidebarMenuItem
          to='/settings'
          icon='setting-3'
          title={intl.formatMessage({id: 'SETTING_MANAGEMENT'})}
          fontIcon='bi-app-indicator'
        />
      )} */}

      {/* <SidebarMenuItemWithSub to='/sales' title='매출관리' icon='element-7' fontIcon='bi-layers'>
        <SidebarMenuItem to='/sales/undefined' title='미정' hasBullet={true} />
        <SidebarMenuItem to='/sales/undefined' title='미정' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuMain}
