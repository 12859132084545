import clsx from 'clsx';
import React from 'react';
import { IconSpinner } from '../../../loading/IconSpinner/IconSpinner';
import './style.scss';
import { STATUS_INQUIRIES } from '../../../../../constants/status';
import { useIntl } from 'react-intl';

type Props = {
  id?: string,
  text: string,
  isLoading?: boolean,
  disabled?: boolean,
  className?: string,
  status: string,
  type?: 'submit' | 'button',
  onClickButton?: (id:string) => void
}

const ButtonCSStatus: React.FC<Props> = ({ text = '', id = '', status = 'PENDING', className, disabled = false, isLoading = false, type = 'button', onClickButton }) => {
  const intl = useIntl();
  const handleOnClick = (event: any) => {
    event.preventDefault();
    (!disabled && onClickButton) && onClickButton(id);
  };

  return (
    <>
      <button className={clsx(`position-relative btn btn-sm fw-bold justify-content-center btn-danger-mc explore-btn-danger-mc explore-btn-border-radius-40-mc px-10 btn-status-cs-${STATUS_INQUIRIES[status]}`, { 'btn-loading': isLoading}, className)} type={type} onClick={handleOnClick}>
         <IconSpinner className="icon-loading position-absolute" style={{transform: 'translate(-50%, -15%)'}} /> 
         {intl.formatMessage({ id: `STATUS_${STATUS_INQUIRIES[status]}` }) || text}
      </button>
    </>
  )
}

export { ButtonCSStatus };

