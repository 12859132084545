import ApexCharts, {ApexOptions} from 'apexcharts'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {useIntl} from 'react-intl'
import {getCSSVariableValue} from '../../../../_metacrew/assets/ts/_utils'
import {useLang} from '../../../../_metacrew/i18n/MetaCrewi18n'
import {useThemeMode} from '../../../../_metacrew/partials'
import Loading from './Loading/Loading'

interface PushClickCTRChartProps {
  width?: number
  height?: number
  className?: string
  title?: string
  data?: {
    labels: string[]
    items: {
      label: string
      data: number[]
    }[]
  }
  colors?: string[]
  isLoading?: boolean
  numberDay: number
  date?: any
}

export default function PushClickCTRChart({
  data,
  className = '',
  isLoading = true,
  colors = ['success', 'warning'],
  date,
}: PushClickCTRChartProps) {
  const [newData, setNewData] = React.useState<any>()

  const dataCTR = useMemo(() => {
    return newData
  }, [newData])

  const dateCTR = useMemo(() => {
    return date
  }, [date])
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {mode} = useThemeMode()

  const lang = useLang()

  const intl = useIntl()

  const refreshMode = useCallback(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(dataCTR, dateCTR, lang, intl))
    if (chart) {
      chart.render()
    }
    return chart
    // eslint-disable-next-line
  }, [colors, newData && newData])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, newData])

  useEffect(() => {
    if (!data) return
    const cloneData = [...(data as any)]
    cloneData?.sort((a: any, b: any) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
    setNewData(cloneData)
    // eslint-disable-next-line
  }, [data, data && data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'CHART.TITLE_RETENTION'})}
            {/* in the last{' '}
            <span style={{color: '#4d8a91'}}>{numberDay}</span> days */}
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {' '}
            {intl.formatMessage({id: 'CHART.DESCRIPTION_RETENTION'})}
          </span>
        </h3>
      </div>
      {/* <div className='card py-5' style={{backgroundColor: '#69d4e0', borderRadius: 10}}>
        <div className='d-flex justify-content-around'>
          <div>
            <div className='fw-semibold text-white'>{intl.formatMessage({id: 'Push'})}</div>
            <div>
              <div className='fs-4 fw-bold' style={{color: 'rgb(0,67,157)'}}>
                <NumberDelay number={123 || 0} />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className=' text-white fw-semibold'>{intl.formatMessage({id: 'Click'})}</div>
              <div>
                <div className='fs-4 fw-bold' style={{color: 'rgb(141, 162, 194,1)'}}>
                  <NumberDelay number={123 || 0} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className=' text-white fw-semibold'>{intl.formatMessage({id: 'CTR'})}</div>
              <div>
                <div className='fs-4 fw-bold' style={{color: 'rgb(141, 162, 194,1)'}}>
                  <NumberDelay number={123 || 0} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column justify-content-end'>
        {isLoading && (
          <Loading
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '30px',
              height: '30px',
              zIndex: 10,
            }}
          />
        )}
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' className='min-h-auto'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

function getChartOptions(dataCTR: any, date: any, lang: string = 'ko', intl: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      {
        name: intl.formatMessage({id: 'CTR'}),
        type: 'area',
        data: dataCTR,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      height: 300,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
        },
        formatter(val, opts) {
          return `${val}%`
        },
      },
      max: 100,
      tickAmount: 4,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter(val, opts) {
        return `${val}%`
      },
      textAnchor: 'start',
    },
    stroke: {
      width: 1.5,
      curve: 'smooth',
    },
    xaxis: {
      categories: date,
      tickAmount: 2,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return `${
            val
              ? new Intl.DateTimeFormat(lang === 'en' ? 'en-US' : 'ko-KR', {
                  month: 'short',
                  day: '2-digit',
                }).format(dayjs(val, 'YYYY-MM-DD').valueOf())
              : ''
          }`
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },

    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        opacityFrom: 0.8,
        opacityTo: 0.5,
      },
    },

    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + '%'
        },
      },
      marker: {
        show: true,
      },
    },
    colors: ['rgb(7, 172, 255)'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 10,
        bottom: 0,
      },
    },
  }
}
