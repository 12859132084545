/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {WithChildren} from '../../../../helpers'

type Props = {
  show: boolean
  className?: string
  handleClose?: () => void
  dialogClassName?: string
  cancelButtonProps?: any
  okButtonProps?: any
  width?: string
  enforceFocus?: boolean
  minHeight?: string
  handleOnClose?: () => void
  isShowCloseBtn?: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalWrapper: React.FC<Props & WithChildren> = ({
  children,
  show = true,
  handleClose,
  className = '',
  dialogClassName = 'modal-dialog-centered mw-900px',
  okButtonProps,
  width,
  enforceFocus = true,
  minHeight = '',
  handleOnClose,
  isShowCloseBtn,
}) => {
  return createPortal(
    <Modal
      className={className}
      dialogClassName={dialogClassName}
      show={show}
      okButtonProps={okButtonProps}
      width={width}
      handleClose={handleClose}
      enforceFocus={enforceFocus}
    >
      {isShowCloseBtn && (
        <div
          style={{zIndex: 10}}
          className='col-2 col-lg-2 col-md-2 text-end me-4 mt-4 position-absolute top-0 end-0'
        >
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm ms-2' onClick={handleOnClose}>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {/* end::Close */}
        </div>
      )}

      <div
        style={{maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden', minHeight: minHeight}}
        className={`modal-content shadow-none `}
      >
        {children}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalWrapper}
