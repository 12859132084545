const QUERIES = {
  DASHBOARD: 'dashboard',
  USERS_LIST: 'users-list',
  GROUPS_LIST: 'groups-list',
  SETTING_GROUP: 'setting-group',
  APPS_LIST: 'apps-list',
  CATEGORIES_LIST: 'categories-list',
  NEWS_LIST: 'news-list',
  PARSER_LIST: 'parser-list',
  CHANNEL_LIST: 'channel-list',
  STOCK_LIST: 'stock-list',
  COMPANY_LIST: 'company-list',
  USER_LIST: 'user-list',
  ROLE_LIST: 'role-list',
  KEYWORD_LIST: 'keyword-list',
  PERMISSION_LIST: 'permission-list',
  ADVERTISER_GROUP_LIST: 'advertiser-group-list',
  RSS_LIST: 'rss-list',
  PARTNER_LIST: 'partner-list',
  DEVICE_LIST: 'device-list',
  CAMPAIGN_LIST: 'campaign-list',
  MEMBERSHIP_LIST: 'member-list',
  INQUIRIES_LIST: 'cs-list',
  CONTENT_LIST: 'content-list',
  CATEGORIES_REQUEST_LIST: 'categories-request-list',
  CATEGORIES_LIST_NO_PAGINATION: 'categories-list-no-pagination',
  STICKERS_LIST: 'stickers-list',
  LANGUAGE_LIST: 'language-list',
  CONTENT_DETAIL: 'content-detail',
  NEWS_FILTER_LIST: 'news-filter-list',
  NOTIFICATION_SCHEDULE_LIST: 'notification-schedule-list',
  NOTIFICATION_LIST: 'notification-schedule-list',
  PUSH_LOG: 'push-log',
  OVERALL_STATISTICS: 'overall-statistics',
  PARTNER_STATISTICS: 'partner-statistics',
  CHANNEL_STATISTICS: 'channel-statistics',
  CATEGORY_STATISTICS: 'category-statistics',
  LOG_KEYWORDS: 'log-keywords',
  LOG_ARTICLES_BOOKMARK: 'log-articles-bookmark',
  LOG_STOCK_BOOKMARK: 'log-stock-bookmark',
  LOG_OPEN_APP: 'log-open-app',
  LOG_ARTICLES_CATEGORIES: 'log-articles-categories',
  LOG_ARTICLES_VIEW: 'log-articles-views',
  LOG_STOCK_VIEWS: 'log-stock-views',
  LOG_VIEW_TAB: 'log-view-tab',
  USER_INTERESTS: 'log-user-interests',
  USER_LOG: 'log-user',
  USER_RECEIVE_NOTIFICATION: 'user-receive-notification',
  USER_ACTION_LOG: 'user-action-log',
  USER_MEDIA_STATISTICS: 'media-statistics',
  USER_USER_STATISTICS: 'user-statistics',
  USER_CHANNEL_STATISTICS: 'channel-statistics',
  USER_DEVICE_STATISTICS: 'device-statistics',
  USER_RETENTION: 'retention',
  USER_KEYWORD: 'user-keyword',
  USER_COHORT_GRAPH: 'cohort-graph',
  USER_PRESS: 'user-press',
  USER_ACTIVE: 'user-active',
  DASHBOARD_CHANNEL: 'dashboard-channel',
  DASHBOARD_OS: 'dashboard-os',
  REACTION_AND_COMMENT: 'reaction-and-comment',
  DASHBOARD_VIEWS: 'dashboard-views',
  NEWS_COMMENT: 'news-comment',
  COMMENT_REPORT: 'comment-report',
  COMMENT_REPORT_REASON: 'comment-report-reason',
  AD_SETTING: 'ad-setting',
  AD_MEDIA: 'ad-media',
  CSS_SETTING: 'css-setting',
  COMMENT_FILTER: 'comment-filter',
  SDK_VERSION: 'sdk-version',
  USER_PRESS_RANKING: 'user-press-ranking',
  USER_KEYWORD_RANKING: 'user-keyword-ranking',
  USER_MEDIA_RANKING: 'user-media-ranking',
  USER_CHANNEL_RANKING: 'user-channel-ranking',
  USER_DEVICE_RANKING: 'user-device-ranking',
  LIST_REACTION_NEWS: 'list-reaction-news',
  LIST_DETAIL_REACTION_NEWS: 'list-detail-reaction-news',
  LIST_COMMENT_NEWS: 'list-comment-news',
  LIST_ACCOUNT: 'list-account',
  LIST_SDK_VERSION: 'list-sdk-version',
  LIST_TICKET_ISSUE: 'list-ticket-issue',
}

const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/jpg']

const DATE_FORMAT = 'YYYY-MM-DD'

const OS = {
  ANDROID: 'android',
  IOS: 'ios',
}

export {QUERIES, SUPPORTED_IMAGE_FORMATS, DATE_FORMAT, OS}
