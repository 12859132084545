/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import Loading from '../../../../../app/pages/dashboard/components/Loading/Loading'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'
import './style.scss'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  handleClose: () => void
}

const NewsPreviewModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  handleClose,
}) => {
  const intl = useIntl()

  return (
    <ModalWrapper show={show} handleClose={handleClose}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className='row mb-10 mt-8'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>{intl.formatMessage({id: 'TITLE_DETAIL_NEWS'})}</h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <div className='pb-10'>
            <div className='position-relative' style={{minHeight: '60dvh'}}>
              {isLoading ? (
                <Loading
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '30px',
                    height: '30px',
                  }}
                />
              ) : (
                <>
                  <div className='row mb-9 content-preview-news'>
                    <div className='mb-5'>
                      <h1>{initialValuesDefault?.title}</h1>
                    </div>
                    <div className='row mb-5'>
                      <div className='col-lg-6 col-md-6  fs-mc-16'>
                        {intl.formatMessage({id: 'FORM_CHANNEL'})}:{' '}
                        <span className='badge fs-7 m-1 badge-light-primary'>
                          {initialValuesDefault?.channel?.name}
                        </span>
                      </div>
                      <div className='col-lg-6 col-md-6  fs-mc-16'>
                        {intl.formatMessage({id: 'FORM_CATEGORY'})}:{' '}
                        {initialValuesDefault?.categories?.map((item: any) => (
                          <span key={item?.id} className='badge fs-7 m-1 badge-light-primary'>
                            {item?.name}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{__html: initialValuesDefault?.description || ''}}
                    ></div>
                    <div className='col-lg-12 col-md-12  fs-mc-16 mt-3'>
                      {intl.formatMessage({id: 'FORM_LINK'})}:{' '}
                      <a
                        href={initialValuesDefault?.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {initialValuesDefault?.link}
                      </a>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-3 col-md-3  fs-mc-16'></div>
                    <div className='col-lg-6 col-md-6 text-center'>
                      <button
                        className='btn width-auto btn-danger fw-bold justify-content-center '
                        onClick={handleClose}
                      >
                        {intl.formatMessage({id: 'BUTTON_CLOSE'})}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {NewsPreviewModal}
