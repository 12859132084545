import { postCreateMembership, postDeleteMembership, postUpdateMembership, postUpdateStatusMembership, postUpdatePasswordMembership } from '../../../api/requests/_membership';

const useActionMembership = () => {

  const createMembership = async (formData:any) => {
    const params = { ...formData};
    const res =  await postCreateMembership(params);
    return res;
  };

  const updateMembership = async (idMembership:string, formData:any) => {
    const params = { ...formData};
    const res =  await postUpdateMembership(idMembership, params);
    return res;
  };

  const updatePasswordMembership = async (idMembership:string, formData:any) => {
    const params = { ...formData};
    const res =  await postUpdatePasswordMembership(idMembership, params);
    return res;
  };

  const updateStatusMembership = async (idMembership:string, status:boolean | number = false) => {
    const res =  await postUpdateStatusMembership(idMembership, status);
    return res;
  };

  const deleteMembership = async (idMembership:string) => {
    const params = {};
    const res =  await postDeleteMembership(idMembership, params);
    return res;
  };
  
  return {
    createMembership,
    updateMembership,
    updatePasswordMembership,
    updateStatusMembership,
    deleteMembership
  };
};

export default useActionMembership;
