/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useActionCS from '../../../../../helpers/hooks/cs/useActionCS'
import {ButtonCancel} from '../../../form/Buttons/ButtonCancel/ButtonCancel'
import {ButtonPrimary} from '../../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../../form/CustomInput/CustomInput'
import {ICS} from '../../ModalModel'

type Props = {
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  handleSubmit?: (values: ICS, id: string | undefined) => void
}

const CSFormModal: React.FC<Props> = ({
  isLoading = false,
  initialValuesDefault = {},
  isShowButton = false,
  handleSubmit,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {sendEmailCS} = useActionCS()

  const validationSchema = Yup.object().shape({
    inquiry_account: initialValuesDefault?.id
      ? Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .nullable()
      : Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    member_name: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    member_group: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    request_date: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    category: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    email_for_reply: initialValuesDefault?.id
      ? Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .nullable()
      : Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .required('이메일은 필수입니다'),
    subject: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    inquiry_content: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    email_to_be_answered: initialValuesDefault?.id
      ? Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .required('이메일은 필수입니다')
      : Yup.string()
          .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
          .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
          .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
          .nullable(),
    response: initialValuesDefault?.id
      ? Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      : Yup.string().nullable(),
  })

  const initialValues: ICS = {
    inquiry_account: initialValuesDefault?.inquiry_account || '',
    member_name: initialValuesDefault?.member_name || '',
    member_group: initialValuesDefault?.member_group || '',
    request_date: initialValuesDefault?.request_date || '',
    category: initialValuesDefault?.category || '',
    email_for_reply: initialValuesDefault?.email_for_reply || '',
    subject: initialValuesDefault?.subject || '',
    inquiry_content: initialValuesDefault?.inquiry_content || '',
    email_to_be_answered:
      initialValuesDefault?.email_respond || initialValuesDefault?.email_for_reply || '',
    response: initialValuesDefault?.response || '',
    // status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true'
  }

  const onSubmit = (values: ICS) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }

  const handleSendEmail = async (form: any) => {
    const {email_to_be_answered, response} = form?.values
    if (
      !email_to_be_answered ||
      !response ||
      form?.errors?.email_to_be_answered ||
      form?.errors?.response
    ) {
      if (form?.errors?.email_to_be_answered || !email_to_be_answered) {
        form.setFieldError(
          'email_to_be_answered',
          form?.errors?.email_to_be_answered || '이메일은 필수입니다'
        )
      }
      if (form?.errors?.response || !response) {
        form.setFieldError('response', form?.errors?.response || '이메일은 필수입니다')
      }
      return
    }
    setLoading((_prevState: boolean) => true)
    const params = {
      email_respond: email_to_be_answered || '',
      response: response ?? '',
    }
    const {code, error} = await sendEmailCS(initialValuesDefault?.id || '', params)
    if (code === 1) {
      toast(intl.formatMessage({id: 'NOTIFICATION_INQUIRY_RESPONSE_HAS_BEEN_COMPLETED'}))
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
    setLoading((_prevState: boolean) => false)
  }

  return (
    <div className='container-xxl w-lg-1100px'>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({...form}: FormikProps<ICS>) => (
          <Form>
            <div className='row'>
              <div className='col-md-5'>
                <h2 className='mb-15'>{intl.formatMessage({id: 'FORM_MEMBER_INFORMATION'})}</h2>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_INQUIRY_ACCOUNT'})}
                    name='inquiry_account'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_INQUIRY_ACCOUNT'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_MEMBER_THIS'})}
                    name='member_name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_MEMBER_THIS'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_MEMBER_GROUP'})}
                    name='member_group'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_MEMBER_GROUP'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>
                <div className='row mb-7'>
                  <h2>{intl.formatMessage({id: 'FORM_INQUIRY_INFORMATION'})}</h2>
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_REQUEST_DATE'})}
                    name='request_date'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_REQUEST_DATE'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CATEGORY_INQUIRY'})}
                    name='category'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_CATEGORY_INQUIRY'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_REPLY_EMAIL'})}
                    name='email_for_reply'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_REPLY_EMAIL'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>

                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_SUBJECT'})}
                    name='subject'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_SUBJECT'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                  />
                </div>

                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_INQUIRY_CONTENT'})}
                    name='inquiry_content'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_INQUIRY_CONTENT'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    colRight={8}
                    numberRow={10}
                  />
                </div>
              </div>
              <div className='col-md-7'>
                <h2 className='mb-15 '>{intl.formatMessage({id: 'FORM_WRITE_REPLY'})}</h2>
                <div className='row'>
                  <div className='row mb-7'>
                    <div className='col-lg-3 col-md-3 mb-md-0 fs-mc-16 d-flex flex-column align-items-left'>
                      <label className='col-form-label p-0 form-custom-label form-control-solid required'>
                        {' '}
                        {intl.formatMessage({id: 'FORM_EMAIL_FOR_RECEIVING_REPLIES'})}
                      </label>
                    </div>
                    <div className='col-lg-8 col-md-8'>
                      <div className='row'>
                        <div className='col-md-7'>
                          <Field
                            label=''
                            name='email_to_be_answered'
                            type='text'
                            placeholder={intl.formatMessage({id: 'FORM_EMAIL_FOR_REPLY'})}
                            autoComplete='off'
                            className='form-control form-control-solid'
                            isFullWidth={true}
                            component={CustomInput}
                            colRight={8}
                            // disabled={!initialValuesDefault?.id}
                          />
                        </div>
                        <div className='col-md-5 text-end mt-1'>
                          <ButtonPrimary
                            text={intl.formatMessage({id: 'BUTTON_SEND_EMAIL'})}
                            isLoading={loading}
                            type='button'
                            onClick={() => handleSendEmail(form)}
                          />
                        </div>
                      </div>
                      {String(initialValuesDefault?.email_respond || '') !==
                        String(form?.values?.email_to_be_answered || '') && (
                        <div className='mt-3' style={{color: '#888'}}>
                          <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM9 15.1875C7.77623 15.1875 6.57994 14.8246 5.56241 14.1447C4.54488 13.4648 3.75182 12.4985 3.2835 11.3679C2.81518 10.2372 2.69265 8.99314 2.93139 7.79288C3.17014 6.59262 3.75944 5.49011 4.62478 4.62478C5.49012 3.75944 6.59262 3.17014 7.79288 2.93139C8.99314 2.69264 10.2372 2.81518 11.3679 3.2835C12.4985 3.75181 13.4648 4.54488 14.1447 5.56241C14.8246 6.57994 15.1875 7.77623 15.1875 9C15.1856 10.6405 14.5331 12.2132 13.3732 13.3732C12.2132 14.5331 10.6405 15.1856 9 15.1875ZM8.4375 9.5625V5.625C8.4375 5.47582 8.49677 5.33274 8.60226 5.22725C8.70775 5.12176 8.85082 5.0625 9 5.0625C9.14919 5.0625 9.29226 5.12176 9.39775 5.22725C9.50324 5.33274 9.5625 5.47582 9.5625 5.625V9.5625C9.5625 9.71168 9.50324 9.85476 9.39775 9.96025C9.29226 10.0657 9.14919 10.125 9 10.125C8.85082 10.125 8.70775 10.0657 8.60226 9.96025C8.49677 9.85476 8.4375 9.71168 8.4375 9.5625ZM9.84375 12.0938C9.84375 12.2606 9.79427 12.4238 9.70156 12.5625C9.60884 12.7013 9.47707 12.8094 9.32289 12.8733C9.16872 12.9371 8.99907 12.9538 8.8354 12.9213C8.67172 12.8887 8.52138 12.8084 8.40338 12.6904C8.28538 12.5724 8.20502 12.422 8.17247 12.2584C8.13991 12.0947 8.15662 11.925 8.22048 11.7709C8.28434 11.6167 8.39249 11.4849 8.53124 11.3922C8.66999 11.2995 8.83313 11.25 9 11.25C9.22378 11.25 9.43839 11.3389 9.59662 11.4971C9.75486 11.6554 9.84375 11.87 9.84375 12.0938Z'
                              fill='#888888'
                            />
                          </svg>
                          <span className='ms-3'>
                            {intl.formatMessage({
                              id: 'FORM_WARNING_CHANGE_EMAIL_FOR_RECEIVING_REPLIES',
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <Field
                      label=''
                      name='response'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_ACTIVATE_STATUS'})}
                      autoComplete='off'
                      className=''
                      component={CustomInput}
                      isEditor={true}
                      numberRow={10}
                      colLeft={0}
                      colRight={11}
                      // disabled={!initialValuesDefault?.id}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-7 mt-7'>
                <div className='col-12 text-center'>
                  {isShowButton && (
                    <ButtonPrimary
                      text={intl.formatMessage({
                        id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                      })}
                      type='submit'
                      className='me-6'
                      isLoading={isLoading}
                    />
                  )}
                  <ButtonCancel
                    text={intl.formatMessage({id: 'BUTTON_CANCEL'})}
                    type='button'
                    onClick={() => navigate('/cs')}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {CSFormModal}
