/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, {ApexOptions} from 'apexcharts'
import React, {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {getCSSVariableValue} from '../../../../_metacrew/assets/ts/_utils'
import {useLang} from '../../../../_metacrew/i18n/MetaCrewi18n'
import {useThemeMode} from '../../../../_metacrew/partials'
import NumberDelay from '../../../../_metacrew/partials/tdi-mc/common/NumberDelay'
import Loading from './Loading/Loading'

type Props = {
  className: string
  isPageMode?: boolean
  isSummaryPage?: boolean
  data?: any
  isLoading?: boolean
}

const ActiveUserChart: React.FC<Props> = ({
  className,
  isPageMode = false,
  isSummaryPage = false,
  isLoading = false,
  data,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const intl = useIntl()

  const refreshMode = (data: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, formatData(), lang, intl, isSummaryPage)
    )

    if (chart) {
      chart.render()
    }

    return chart
  }

  const dataLine1 =
    data &&
    data.map((item: any) => ({
      x: item.date,
      y: item.dau,
    }))
  const dataLine2 =
    data &&
    data.map((item: any) => ({
      x: item.date,
      y: item.wau,
    }))
  const dataLine3 =
    data &&
    data.map((item: any) => ({
      x: item.date,
      y: item.mau,
    }))

  const formatData = () => {
    if (
      dataLine1 &&
      dataLine1.length <= 0 &&
      dataLine2 &&
      dataLine2.length <= 0 &&
      dataLine3 &&
      dataLine3.length <= 0
    ) {
      return []
    }
    return [dataLine1, dataLine2, dataLine3]
  }

  const lang = useLang()

  useEffect(() => {
    let chart: any = null
    chart = refreshMode(formatData)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'CHART.TITLE_USER_ACTIVE'})}
          </span>
          <span className='text-muted fw-semibold fs-7'>
            {intl.formatMessage({id: 'CHART.DESCRIPTION_USER_ACTIVE'})}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card py-5' style={{backgroundColor: 'rgb(238 247 255)', borderRadius: 10}}>
        <div className='d-flex justify-content-around'>
          <div>
            <div className='fw-semibold fs-7 text-muted'>DAU</div>
            {/* end::Label */}
            {/* begin::Stat */}
            <div>
              <div className='fs-4 fw-bold' style={{color: 'rgb(7, 172, 255)'}}>
                {dataLine1 && dataLine1[dataLine1.length - 1]?.y ? (
                  <NumberDelay number={dataLine1 && dataLine1[dataLine1.length - 1]?.y} />
                ) : false ? (
                  <div className='placeholder-glow' role='status'>
                    <span className='placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px'></span>
                  </div>
                ) : (
                  '0'
                )}{' '}
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className='fw-semibold fs-7 text-muted'>WAU</div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div className='fs-4 fw-bold' style={{color: '#219ebc'}}>
                  {dataLine2 && dataLine2[dataLine2.length - 1]?.y ? (
                    <NumberDelay number={dataLine2 && dataLine2[dataLine2.length - 1]?.y} />
                  ) : false ? (
                    <div className='placeholder-glow' role='status'>
                      <span className='placeholder placeholder-loading bg-#008ffb w-15px h-3px min-h-3px'></span>
                    </div>
                  ) : (
                    '0'
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className='fw-semibold fs-7 text-muted'>MAU</div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div className='fs-4 fw-bold' style={{color: '#8ecae6'}}>
                  {dataLine3 && dataLine3[dataLine3.length - 1]?.y ? (
                    <NumberDelay number={dataLine3 && dataLine3[dataLine3.length - 1]?.y} />
                  ) : false ? (
                    <div className='placeholder-glow' role='status'>
                      <span className='placeholder placeholder-loading bg-#008ffb w-15px h-3px min-h-3px'></span>
                    </div>
                  ) : (
                    '0'
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}

        {/* end::Chart */}
      </div>
      <div className='card-body p-0 d-flex flex-column justify-content-end'>
        {isLoading && (
          <Loading
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '30px',
              height: '30px',
              zIndex: 10,
            }}
          />
        )}
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' className='min-h-auto'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const ActiveUserChartWrapper = ({className, isLoading, data, isPageMode, isSummaryPage}: any) => (
  <ActiveUserChart
    data={data}
    className={className}
    isPageMode={isPageMode}
    isSummaryPage={isSummaryPage}
    isLoading={isLoading}
  />
)

export default ActiveUserChartWrapper

function getChartOptions(
  height: number,
  formattedData: any,
  lang: any,
  intl: any,
  isSummaryPage = false
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseLightColor = getCSSVariableValue('--bs-success-light')
  const secondaryLightColor = getCSSVariableValue('--bs-warning-light')

  return {
    series: [
      {
        type: 'area',
        data: formattedData[0],
        name: intl.formatMessage({
          id: 'DAU',
        }),
      },

      {
        type: 'area',
        data: formattedData[1],
        name: intl.formatMessage({
          id: 'WAU',
        }),
      },
      {
        type: 'area',
        data: formattedData[2],
        name: intl.formatMessage({
          id: 'MAU',
        }),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    // grid: {
    //   show: true,
    //   borderColor: "#F4F6FA",
    //   strokeDashArray: 6,
    // },
    responsive: [
      // {
      //   breakpoint: 780,
      //   options: {
      //     chart: {
      //       height: 200,
      //     },
      //   },
      // },
      // {
      //   breakpoint: 1280,
      //   options: {
      //     chart: {
      //       height: 200,
      //     },
      //   },
      // },
    ],

    fill: {
      type: 'solid',
      opacity: isSummaryPage ? 0.2 : 0.4,
    },

    stroke: {
      curve: 'smooth',
      show: true,
      width: 1,
    },
    xaxis: {
      // type: "datetime",
      tickAmount: 3,

      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: false,
      },

      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },

      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        rotate: 0,

        formatter(value) {
          return new Date(value).toLocaleDateString(lang === 'en' ? 'en-US' : 'ko-KR', {
            month: 'short',
            day: '2-digit',
          })
        },
      },
      tooltip: {
        enabled: false,
      },
    },

    yaxis: {
      tickAmount: 4,
      forceNiceScale: true,
      labels: {
        style: {
          colors: 'gray',
          fontSize: '12px',
        },
        formatter: function (val) {
          return val?.toFixed(0)
        },
      },
      min: 0,
      decimalsInFloat: 0,
    },

    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },

    legend: {
      show: false,
      labels: {
        colors: 'gray',
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: ['rgb(7, 172, 255)', '#219ebc', '#8ecae6'],
    markers: {
      colors: ['rgb(0, 143, 251)', baseLightColor, secondaryLightColor],
      strokeColors: [secondaryLightColor, baseLightColor],
      strokeWidth: 3,
    },
  }
}
