/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {SUPPORTED_IMAGE_FORMATS} from '../../../../helpers'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import CustomInputFile from '../../form/CustomInputFile/CustomInputFile'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../form/CustomSelect/CustomSelect'
import {IChannel} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Option = {
  value: string
  label: string
}

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionParser?: Option[]
  listOptionLanguage?: Option[]
  handleClose: () => void
  handleSubmit?: (values: IChannel, id: string | undefined) => void
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

function convertToDictionary(dynamicValues: any) {
  const dictionary: any = {}

  for (const key in dynamicValues) {
    const value = dynamicValues[key]
    dictionary[key] = {name: value}
  }

  return dictionary
}

function convertToObject(arr: any) {
  return arr?.reduce(
    (result: any, item: any) => {
      result['translations'][item?.value] = ''
      return result
    },
    {translations: {}}
  )
}

const ChannelFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault,
  listOptionParser = [],
  listOptionLanguage = [],
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const animatedComponents = makeAnimated()

  const languages = listOptionLanguage
  const translations = initialValuesDefault?.translations
    ? convertToDictionary(initialValuesDefault?.translations)
    : {}
  const validationSchema = initialValuesDefault?.translations
    ? createValidationSchema(initialValuesDefault)
    : createValidationSchema(convertToObject(languages))
  const parser = initialValuesDefault?.parser_id
    ? listOptionParser?.filter((item) => item?.value === initialValuesDefault?.parser_id)[0]
    : listOptionParser[0]
  const initialValues: IChannel = {
    // name: initialValuesDefault?.name,
    translations: translations,
    manager: initialValuesDefault?.manager,
    contact: initialValuesDefault?.contact,
    email: initialValuesDefault?.email,
    parser: parser,
    is_custom_style: initialValuesDefault?.id
      ? String(initialValuesDefault?.is_custom_style === 1)
      : 'true',
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
    sync_status: initialValuesDefault?.id
      ? String(initialValuesDefault?.sync_status === 1)
      : 'true',
  }
  const onSubmit = (values: IChannel) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }

  function createValidationSchema(initialValuesDefault: any) {
    const schema: any = {}
    const {translations = {}, image_path = ''} = initialValuesDefault

    for (const language in translations) {
      if (translations.hasOwnProperty(language)) {
        schema[language] = Yup.object().shape({
          name: Yup.string().required('언어에서 이름은 필수입니다.'),
        })
      }
    }

    return Yup.object().shape({
      file: image_path
        ? Yup.string().nullable()
        : Yup.mixed()
            .test(
              'is-supported-format',
              `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
              (value: any) => {
                if (!value) return true
                return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
              }
            )
            .nullable(),
      logo_extra: image_path
        ? Yup.string().nullable()
        : Yup.mixed()
            .test(
              'is-supported-format',
              `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
              (value: any) => {
                if (!value) return true
                return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
              }
            )
            .nullable(),
      logo_extra_dark: image_path
        ? Yup.string().nullable()
        : Yup.mixed()
            .test(
              'is-supported-format',
              `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
              (value: any) => {
                if (!value) return true
                return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
              }
            )
            .nullable(),
      // name: Yup.string().required(`${intl.formatMessage({ id: 'VALIDATION_REQUIRED' })}`),

      parser: Yup.object({
        label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
        value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      })
        .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
        .test(
          'validate-select-option',
          `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
          (value: any) => {
            if (typeof value !== 'object' || !value.label || !value.value) {
              return false
            }
            return true
          }
        ),
      status: Yup.string(),
      is_custom_style: Yup.string(),
      translations: Yup.object().shape(schema),
      sync_status: Yup.string(),
    })
  }

  return (
    <ModalWrapper show={show}>
      <div className='container-xxl'>
        <div className='modal-header bg-white row z-index-2 position-sticky top-0'>
          <div className='col-10 col-lg-10 col-md-10'>
            <h1 className='mt-2'>
              {intl.formatMessage({
                id: initialValuesDefault?.id ? 'TITLE_UPDATE_CHANNEL' : 'TITLE_ADD_CHANNEL',
              })}
              {initialValuesDefault?.name && (
                <span className='fs-8 text-primary ms-2'>{`( ${initialValuesDefault?.name} )`}</span>
              )}
            </h1>
          </div>
          <div className='col-2 col-lg-2 col-md-2 text-end'>
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            {/* end::Close */}
          </div>
        </div>
        <div className='modal-body pt-0'>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IChannel>) => (
              <Form className='pb-4 p-8'>
                <div className='row mb-9'>
                  <div className='col-md-4'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_LOGO'})}
                      name='file'
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_LOGO'})}
                      autoComplete='off'
                      valueUrlDefault={initialValuesDefault?.image_path}
                      className='form-control form-control-solid'
                      component={CustomInputFile}
                      numberBorderRadius={0}
                      isRequired={false}
                      isFullWidth={true}
                    />
                  </div>
                  <div className='col-md-4'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_LOGO_EXTRA'})}
                      name='logo_extra'
                      numberBorderRadius={0}
                      type='text'
                      placeholder={intl.formatMessage({id: 'FORM_LOGO_EXTRA'})}
                      autoComplete='off'
                      valueUrlDefault={initialValuesDefault?.logo_extra}
                      className='form-control form-control-solid'
                      component={CustomInputFile}
                      isRequired={false}
                      isFullWidth={true}
                    />
                  </div>
                  <div className='col-md-4'>
                    <Field
                      label={intl.formatMessage({id: 'FORM_LOGO_EXTRA_DARK'})}
                      name='logo_extra_dark'
                      type='text'
                      numberBorderRadius={0}
                      placeholder={intl.formatMessage({id: 'FORM_LOGO_EXTRA_DARK'})}
                      autoComplete='off'
                      valueUrlDefault={initialValuesDefault?.logo_extra_dark}
                      className='form-control form-control-solid'
                      component={CustomInputFile}
                      isRequired={false}
                      isFullWidth={true}
                      isDarkBgr={true}
                    />
                  </div>
                </div>
                {/* <div className="row mb-9">
                  <Field
                    label='언론사명'
                    name='name'
                    type='text'
                    placeholder='언론사명'
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div> */}
                {languages?.map((item: any) => (
                  <div className='row mb-7' key={item?.value}>
                    <Field
                      label={item?.label || ''}
                      name={`translations.${item?.value}.name`}
                      type='text'
                      placeholder={item?.label || ''}
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                    />
                  </div>
                ))}
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_MANAGER'})}
                    name='manager'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_MANAGER'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CONTACT'})}
                    name='contact'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_CONTACT'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'EMAIL'})}
                    name='email'
                    type='text'
                    placeholder={intl.formatMessage({id: 'EMAIL'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_PARSER'})}
                    name='parser'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={parser}
                    options={listOptionParser}
                    component={CustomSelect}
                    isMulti={false}
                  />
                </div>
                <div
                  className='row mb-7'
                  style={{minHeight: 44, display: 'flex', alignItems: 'center'}}
                >
                  <RadioGroup
                    id='is_custom_style'
                    label={intl.formatMessage({id: 'FORM_CUSTOM_STYLE'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='is_custom_style'
                      id='is_custom_style0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'STATUS_DISALLOW'})}
                    />
                    <Field
                      component={Radio}
                      name='is_custom_style'
                      id='is_custom_style1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'STATUS_ALLOW'})}
                    />
                  </RadioGroup>
                </div>
                <div
                  className='row mb-7'
                  style={{minHeight: 44, display: 'flex', alignItems: 'center'}}
                >
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div
                  className='row mb-7'
                  style={{minHeight: 44, display: 'flex', alignItems: 'center'}}
                >
                  <RadioGroup
                    id='sync_status'
                    label={intl.formatMessage({id: 'FORM_SYNC_STATUS'})}
                    value={values.sync_status}
                    error={errors.sync_status}
                    touched={touched.sync_status}
                  >
                    <Field
                      component={Radio}
                      name='sync_status'
                      id='sync_status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='sync_status'
                      id='sync_status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div>
                  <div className='text-end'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {ChannelFormModal}
