import {useIntl} from 'react-intl'
import {IParamsSearch} from '../../../api/models/_public'
import './style.scss'

type Props = {
  handleSearch?: (s: IParamsSearch) => void
  value?: string | number
  name?: string
  title?: string
  options?: {
    value: string
    label: string
  }[]
  hideAllOption?: boolean
  isSmallFont?: boolean
}

const SelectCustom: React.FC<Props> = ({
  handleSearch,
  value = '',
  title = '상태',
  name = 'status',
  options = [],
  hideAllOption = false,
  isSmallFont = false,
}) => {
  const intl = useIntl()
  const handleChange = (e: any) => {
    const val = e.target.value || ''
    handleSearch && handleSearch({[name]: val})
  }

  return (
    <div className='card-toolbar align-items-center d-flex'>
      <div className='bullet bg-secondary h-35px w-1px me-5 d-none d-md-block'></div>
      <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>{title}:</span>
      <div className='d-flex align-items-center position-relative'>
        <select
          value={value}
          onChange={handleChange}
          className={`form-select form-select-solid form-select-md select2-hidden-accessible ${
            isSmallFont ? 'small-font' : ''
          }`}
        >
          {!hideAllOption && <option value=''>{intl.formatMessage({id: 'STATUS_ALL'})}</option>}

          {options?.map((option) => (
            <option value={option?.value} key={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export {SelectCustom}
