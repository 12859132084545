/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import {IAdvertiserGroup} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  initialValuesDefault?: any
  listOptionRoles?: any
  handleClose: () => void
  handleSubmit?: (values: IAdvertiserGroup, id: string | undefined) => void
}

const AdvertiserGroupFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  initialValuesDefault,
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    business_code: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    business_name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    business_sector: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    business_type: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATION_EMAIL'})}`)
      .min(3, `${intl.formatMessage({id: 'VALIDATION_EMAIL_MIN'})}`)
      .max(50, `${intl.formatMessage({id: 'VALIDATION_MAX'})}`)
      .required('이메일은 필수입니다'),
    postal_code: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    address: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    status: Yup.string(),
  })

  const initialValues: IAdvertiserGroup = {
    business_code: initialValuesDefault?.business_code,
    business_name: initialValuesDefault?.business_name,
    business_sector: initialValuesDefault?.business_sector,
    business_type: initialValuesDefault?.business_type,
    email: initialValuesDefault?.email,
    postal_code: initialValuesDefault?.postal_code,
    address: initialValuesDefault?.address,
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }
  const onSubmit = (values: IAdvertiserGroup) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className='row mb-15 mt-8'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>
                {intl.formatMessage({
                  id: initialValuesDefault?.id
                    ? 'TITLE_UPDATE_ADVERTISER_GROUP'
                    : 'TITLE_ADD_ADVERTISER_GROUP',
                })}
              </h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IAdvertiserGroup>) => (
              <Form className='pb-10'>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                    name='business_code'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_BUSINESS_NUMBER'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                    name='business_name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_REPRESENTATIVE_NAME'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                    name='business_type'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_BUSINESS_TYPE'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                    name='business_sector'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_INDUSTRY'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_ZIP_CODE'})}
                    name='postal_code'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_ZIP_CODE'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_BUSINESS_ADDRESS'})}
                    name='address'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_BUSINESS_ADDRESS'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>

                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                    name='email'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_TAX_INVOICE_EMAIL'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div className='row mb-7'>
                  <div className='col-lg-3 col-md-3  fs-mc-16'></div>
                  <div className='col-lg-6 col-md-6 text-center'>
                    <ButtonPrimary
                      text={intl.formatMessage({
                        id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                      })}
                      type='submit'
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {AdvertiserGroupFormModal}
