/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import {ICategory} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Option = {
  value: string
  label: string
}

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionLanguage?: Option[]
  handleClose: () => void
  handleSubmit?: (values: ICategory, id: string | undefined) => void
  categoryFocus?: any
  handleShowConfirmDelete?: (idKey: string) => void
  isLoadingReset?: boolean
}

function convertToDictionary(dynamicValues: any) {
  const dictionary: any = {}
  for (const key in dynamicValues) {
    const value = dynamicValues[key]
    dictionary[key] = {name: value}
  }

  return dictionary
}
function convertToObject(arr: any) {
  return arr?.reduce(
    (result: any, item: any) => {
      result['translations'][item?.value] = ''
      return result
    },
    {translations: {}}
  )
}

function createValidationSchema(initialValuesDefault: any, intl: any) {
  const schema: any = {}
  const {translations = {}} = initialValuesDefault
  for (const language in translations) {
    if (translations.hasOwnProperty(language)) {
      schema[language] = Yup.object().shape({
        name: Yup.string(),
      })
    }
  }

  return Yup.object().shape({
    translations: Yup.object().shape(schema),
  })
}

const PartnerCategoryFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  listOptionLanguage = [],
  handleClose,
  handleSubmit,
  categoryFocus,
  initialValuesDefault,
  handleShowConfirmDelete,
  isLoadingReset = false,
}) => {
  const intl = useIntl()

  const languages = listOptionLanguage
  const translations = categoryFocus?.custom_name
    ? convertToDictionary(categoryFocus?.custom_name)
    : {}

  const validationSchema = categoryFocus?.custom_name
    ? createValidationSchema(categoryFocus, intl)
    : createValidationSchema(convertToObject(languages), intl)

  const initialValues: any = {
    translations: translations,
    partner_id: initialValuesDefault?.settings?.partner_id,
    category_id: categoryFocus?.id,
  }
  const onSubmit = (values: any) => {
    handleSubmit && handleSubmit(values, categoryFocus?.id)
  }

  return (
    <ModalWrapper show={show} dialogClassName='modal-dialog-centered mw-400px'>
      <div className='container-xxl'>
        <div className='modal-body pt-0 px-0'>
          <div className='modal-header row'>
            <div className='col-10 col-lg-10 col-md-10'>
              <h1 className='mt-2'>
                {intl.formatMessage({
                  id: 'TITLE_UPDATE_PARTNER_CATEGORY',
                })}
              </h1>
            </div>
            <div className='col-2 col-lg-2 col-md-2 text-end'>
              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm ms-2'
                onClick={() => {
                  handleClose()
                }}
              >
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<any>) => (
              <Form className='p-6'>
                {languages?.map((item: any) => (
                  <div className='row mb-9' key={item?.value}>
                    <Field
                      isRequired={false}
                      label={
                        item?.label ? intl.formatMessage({id: item?.label.toUpperCase()}) : '' || ''
                      }
                      name={`translations.${item?.value}.name`}
                      type='text'
                      placeholder={
                        item?.label ? intl.formatMessage({id: item?.label.toUpperCase()}) : ''
                      }
                      autoComplete='off'
                      className='form-control form-control-solid'
                      component={CustomInput}
                      isFullWidth
                      value={values?.translations?.[item?.value]?.name}
                    />
                  </div>
                ))}

                <div
                  className='d-flex w-100 justify-content-between'
                  style={{marginBottom: '-3rem'}}
                >
                  <div className='d-flex gap-2 w-100 justify-content-end'>
                    <ButtonPrimary
                      text={intl.formatMessage({
                        id: 'BUTTON_RESET',
                      })}
                      isLoading={isLoadingReset}
                      disabled={isLoadingReset}
                      className='mb-0 btn-secondary text-white'
                      onClick={() =>
                        handleShowConfirmDelete && handleShowConfirmDelete(categoryFocus?.id)
                      }
                    />
                    <ButtonPrimary
                      text={intl.formatMessage({
                        id: 'BUTTON_UPDATE',
                      })}
                      type='submit'
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {PartnerCategoryFormModal}
