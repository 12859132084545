/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, {ApexOptions} from 'apexcharts'
import React, {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {getCSS} from '../../../../_metacrew/assets/ts/_utils'
import {useThemeMode} from '../../../../_metacrew/partials'
import NumberDelay from '../../../../_metacrew/partials/tdi-mc/common/NumberDelay'
import Loading from './Loading/Loading'

const DeviceOsChart: React.FC<any> = ({className, data, total = 0, isLoading = false}: any) => {
  const intl = useIntl()

  // const FLATFORM = [
  //   {title: 'google', label: intl.formatMessage({id: 'PLATFORM-GOOGLE'})},
  //   {title: 'kakao', label: intl.formatMessage({id: 'PLATFORM-KAKAO'})},

  //   {
  //     title: 'wishnote',
  //     label: intl.formatMessage({id: 'PLATFORM-WISHNOTE'}),
  //   },
  //   {title: 'amazon', label: intl.formatMessage({id: 'PLATFORM-AMAZON'})},
  //   {title: 'apple', label: intl.formatMessage({id: 'PLATFORM-APPLE'})},
  //   {title: 'naver', label: intl.formatMessage({id: 'PLATFORM-NAVER'})},
  //   {
  //     title: 'facebook',
  //     label: intl.formatMessage({id: 'PLATFORM-FACEBOOK'}),
  //   },
  // ]
  const labels = Object.keys(data || []).filter((key) => key !== 'total')

  const dataLabelsWithoutTotal: any = labels.map((osKey) => data[osKey])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labels, dataLabelsWithoutTotal, intl)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    let chart: any = null
    if (data) {
      chart = refreshMode()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'COL_DEVICE_OS'})}
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {intl.formatMessage({id: 'COL_DEVICE_OS'})}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap-reverse',
          height: '100%',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div className='mx-auto' style={{minHeight: 180, width: '100%'}}>
          {isLoading && (
            <Loading
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30px',
                height: '30px',
                zIndex: 10,
              }}
            />
          )}
          <div className='mx-auto' ref={chartRef} id='kt_charts_widget_3_chart'></div>
        </div>

        <div
          className='card py-5 w-100'
          style={{backgroundColor: 'rgb(238 247 255)', borderRadius: 10, minHeight: 70}}
        >
          <div className='d-flex justify-content-around'>
            <div>
              {/* begin:: Avatar */}

              <div className='d-flex align-items-center text-muted fw-semibold'>
                {' '}
                {/* eslint-disable-next-line */}
                <img src={'/media/svg/os/android.svg'} className='w-20px me-2' />
                {intl.formatMessage({id: 'ANDROID'})}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div className='fs-4 fw-bold text-center' style={{color: '#5ea2c3'}}>
                <NumberDelay number={(data && data.android) || 0} />
              </div>
            </div>
            <div>
              <div className='d-flex align-items-center text-muted fw-semibold '>
                {/* eslint-disable-next-line */}
                <img src={'/media/svg/os/apple-black.svg'} className='w-15px me-2 mb-1' />

                {intl.formatMessage({id: 'IOS'})}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div className='fs-4 fw-bold text-center' style={{color: '#219ebc'}}>
                <NumberDelay number={(data && data.ios) || 0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DeviceOsChart}

function getChartOptions(
  height: number,
  labels: string[] | undefined,
  dataLabel: number[] | undefined,
  intl: any
): ApexOptions {
  const labelsLanguage: any = labels?.map((osKey) =>
    intl.formatMessage({id: `${osKey.toUpperCase()}`})
  )
  return {
    series: dataLabel,

    chart: {
      // width: "100%",
      height: 200,
      type: 'pie',
    },
    labels: labelsLanguage,

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    // theme: {
    //   mode: "dark",
    //   monochrome: {
    //     enabled: true,
    //   },
    // },

    colors: ['#5ea2c3', '#219ebc'],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },

    legend: {
      show: false,
    },
  }
}
