import React, {useMemo} from 'react'
import clsx from 'clsx'
import {FieldProps} from 'formik'
import './style.scss'
import {useIntl} from 'react-intl'
interface CustomInputFileProps extends FieldProps {
  label?: string
  className?: string
  placeholder?: string
  isRequired?: boolean
  valueUrlDefault?: string
  isFullWidth?: boolean
  isFullWidthImage?: boolean
  colLeft?: number
  colRight?: number
  numberBorderRadius?: number
  isSetting?: boolean
  isDarkBgr?: boolean
}

export const CustomInputFile = ({
  label,
  className,
  placeholder,
  isRequired = true,
  isFullWidth = false,
  isFullWidthImage = false,
  valueUrlDefault = '',
  colLeft = 3,
  colRight = 6,
  numberBorderRadius = 50,
  field,
  form,
  isSetting = false,
  isDarkBgr = false,
  ...props
}: CustomInputFileProps) => {
  const intl = useIntl()
  const onChange = (event: any) => {
    form.setFieldValue(field.name, event.target.files[0])
  }

  const urlFile = useMemo(() => {
    return field.value ? URL.createObjectURL(field.value) : ''
  }, [field.value])

  return (
    <>
      <div
        className={`col-lg-${isFullWidth ? 12 : colLeft} ${
          isSetting ? 'd-flex align-items-center' : ''
        } col-md-${isFullWidth ? 12 : colLeft} mb-md-${isFullWidth ? 1 : 0} fs-mc-16`}
      >
        {label && (
          <label className={clsx('col-form-label p-0 form-custom-label', {required: isRequired})}>
            <h4 className='fw-bold text-dark'>{label}</h4>
          </label>
        )}
      </div>
      <div
        className={`col-lg-${isFullWidth ? 12 : colRight} ${
          isSetting ? 'text-center' : ''
        } col-md-${isFullWidth ? 12 : colRight}`}
      >
        <div
          className={clsx('image-input image-input-outline', {'w-100': isFullWidthImage})}
          data-kt-image-input='true'
        >
          {/* begin::Preview existing avatar */}
          <div
            className={clsx(
              'image-input-wrapper d-flex justify-content-center align-items-center p-1',
              {
                // 'w-full-image': isFullWidthImage,
                // 'w-125px h-125px': !isFullWidthImage,
                'bg-dark': isDarkBgr,
              }
            )}
            // style={{
            //   backgroundSize: 'cover',
            //   backgroundImage: `url(${urlFile || valueUrlDefault || ''})`,
            //   backgroundPosition: 'center',
            //   borderRadius: `${numberBorderRadius}%`,
            //   overflow: 'hidden',
            //   backgroundRepeat: 'no-repeat',
            // }}
          >
            {urlFile ? (
              <img
                src={urlFile}
                alt='icom'
                style={{width: 100, height: '100%', objectFit: 'cover', margin: 'auto 0'}}
              />
            ) : valueUrlDefault ? (
              <img
                src={valueUrlDefault || ''}
                alt='icom'
                style={{width: '100%', height: 'auto', objectFit: 'cover'}}
              />
            ) : (
              ''
            )}
          </div>
          {/* end::Preview existing avatar */}

          {/* begin::Label */}
          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
            data-bs-toggle='tooltip'
            title='배너 변경'
          >
            <i className='bi bi-pencil-fill fs-7 ps-9'></i>

            <input
              {...props}
              className={className}
              name={field.name}
              onChange={onChange}
              placeholder={placeholder}
              type='file'
              accept='.png, .jpg, .jpeg'
            />
          </label>
          {/* end::Label */}
        </div>

        <div className='form-text'>{intl.formatMessage({id: 'FORM_ALLOWED_FILE'})}</div>
        {form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomInputFile
