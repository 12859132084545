import { postCreateCS, postDeleteCS, postSendEmailCS, postUpdateCS, postUpdateStatusCS } from '../../../api/requests/_cs';

const useActionCS = () => {

  const createCS = async (formData:any) => {
    const params = { ...formData};
    const res =  await postCreateCS(params);
    return res;
  };

  const updateCS = async (idCS:string, formData:any) => {
    const params = { ...formData};
    const res =  await postUpdateCS(idCS, params);
    return res;
  };

  const updateStatusCS = async (idCS:string, status:boolean | number = false) => {
    const res =  await postUpdateStatusCS(idCS, status);
    return res;
  };

  const sendEmailCS = async (idCS:string, formData:any) => {
    const params = { ...formData};
    const res =  await postSendEmailCS(idCS, params);
    return res;
  };
  

  const deleteCS = async (idCS:string) => {
    const params = {};
    const res =  await postDeleteCS(idCS, params);
    return res;
  };
  
  return {
    createCS,
    updateCS,
    updateStatusCS,
    sendEmailCS,
    deleteCS
  };
};

export default useActionCS;
