/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, TimePicker} from 'antd'
import en from 'antd/es/date-picker/locale/en_US'
import ko from 'antd/es/date-picker/locale/ko_KR'
import dayjs from 'dayjs'
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {KTIcon} from '../../../../helpers'
import {useLang} from '../../../../i18n/MetaCrewi18n'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import {IPartnerSetting} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  handleClose: () => void
  handleSubmit?: (values: IPartnerSetting, item: any | undefined) => void
}
const PartnerSettingFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()
  const lang = useLang()
  const validationSchema = Yup.object().shape({
    hide_channel_select: Yup.string(),
    limit_notification: Yup.string(),
  })
  const delivery_time = initialValuesDefault?.settings
    ? JSON.parse(initialValuesDefault?.settings?.delivery_time)
    : []

  const [numberOfDateTime, setNumberOfDateTime] = useState<any>(
    delivery_time && delivery_time.length
  )

  const settings = initialValuesDefault?.settings
    ? JSON.parse(initialValuesDefault?.settings?.settings)
    : []

  const hide_channel_select = settings?.hide_channel_select ?? false
  const limit_notification = settings?.limit_notification ?? 0
  const initialValues: IPartnerSetting = {
    hide_channel_select: initialValuesDefault?.id ? String(Boolean(hide_channel_select)) : 'true',
    limit_notification: limit_notification || '',
    delivery_time: delivery_time || [],
  }
  const onSubmit = (values: IPartnerSetting) => {
    handleSubmit && handleSubmit(values, initialValuesDefault)
  }

  const handleAddMoreTime = (values: any) => {
    if (numberOfDateTime <= 0) {
      setNumberOfDateTime((prev: any) => prev + 1)
    }
    if (values.delivery_time.length === numberOfDateTime && values.delivery_time[0]) {
      setNumberOfDateTime((prev: any) => prev + 1)
    }
  }

  const handleOnChanceTime = (time: any, setFieldValue: any, index: any, values: any) => {
    const existTime = values.delivery_time[index]
    if (existTime) {
      const cloneDelivery = [...values.delivery_time]
      cloneDelivery[index] = time ? time : ''
      setFieldValue(`delivery_time`, cloneDelivery)
    } else {
      const cloneDelivery = [...values.delivery_time, time ? time : '']
      setFieldValue(`delivery_time`, cloneDelivery)
    }
  }

  const handleDeleteTime = (setFieldValue: any, index: any, values: any) => {
    const existTime = values.delivery_time[index]
    if (existTime !== undefined) {
      const cloneDelivery = [...values.delivery_time]
      cloneDelivery.splice(index, 1)
      setFieldValue(`delivery_time`, cloneDelivery)
    }
    setNumberOfDateTime((prev: any) => prev - 1)
  }

  useEffect(() => {
    setNumberOfDateTime(delivery_time && delivery_time.length)
    //eslint-disable-next-line
  }, [initialValuesDefault])

  return (
    <ModalWrapper show={show} dialogClassName='modal-dialog-centered mw-600px'>
      <div className='container-xxl'>
        <div className='modal-header row '>
          <div className='col-10 d-flex col-lg-10 col-md-10'>
            <h1 className='mt-2'>{intl.formatMessage({id: 'TITLE_SETTING_MEDIA'})}</h1>
            {initialValuesDefault?.name && (
              <span className='fs-8 text-primary ms-2 mt-5'>{`( ${initialValuesDefault?.name} )`}</span>
            )}
          </div>
          <div className='col-2 col-lg-2 col-md-2 text-end'>
            {/* begin::Close */}
            <div
              className='btn btn-icon btn-sm ms-2'
              onClick={() => {
                setNumberOfDateTime(delivery_time && delivery_time.length)
                handleClose()
              }}
            >
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            {/* end::Close */}
          </div>
        </div>
        <div className='modal-body'>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values, setFieldValue}: FormikProps<IPartnerSetting>) => (
              <Form className='p-6 pb-0'>
                <div className='row mb-7 px-0 mx-0 d-flex w-100 align-items-center'>
                  <Field
                    label={intl.formatMessage({id: 'LABEL_LIMIT_NOTIFICATION'})}
                    name='limit_notification'
                    id='limit_notification'
                    type='text'
                    isSettingTheme
                    placeholder={intl.formatMessage({id: 'LABEL_LIMIT_NOTIFICATION'})}
                    autoComplete='off'
                    isFullWidth
                    className='form-control form-control-solid '
                    component={CustomInput}
                  />
                </div>
                <div className='row gap-4 mx-0'>
                  <span className='col-form-label p-0 form-custom-label form-control-solid required'>
                    {intl.formatMessage({id: 'DELIVERY_TIME'})}
                  </span>
                  {Array(numberOfDateTime)
                    .fill('')
                    .map((_, index) => {
                      return (
                        <div
                          key={index}
                          className='d-flex px-0 align-items-center position-relative'
                        >
                          <TimePicker
                            locale={lang === 'ko' ? ko : en}
                            format={'HH:mm'}
                            onChange={(time, timeString) => {
                              handleOnChanceTime(timeString, setFieldValue, index, values)
                            }}
                            value={
                              values.delivery_time[index]
                                ? dayjs(
                                    values.delivery_time && values.delivery_time[index],
                                    'HH:mm'
                                  )
                                : null
                            }
                          />
                          <Button
                            // disabled={isDisableButtonAddMore}
                            type='text'
                            className=' position-absolute  btn btn-icon btn-bg-light d-flex justify-content-center h-auto w-auto btn-color-danger btn-active-color-danger btn-sm'
                            onClick={() => handleDeleteTime(setFieldValue, index, values)}
                            style={{right: -20}}
                          >
                            <div className='button-edit'>
                              <KTIcon iconName='trash' className='fs-3' />
                            </div>
                          </Button>
                        </div>
                      )
                    })}
                </div>
                <Button
                  // disabled={isDisableButtonAddMore}
                  type='text'
                  className='mt-4 mx-0 px-0'
                  onClick={() => handleAddMoreTime(values)}
                >
                  <KTIcon
                    className='fs-1 cursor-pointer '
                    iconType='outline'
                    iconName='add-notepad'
                  />
                </Button>

                <div>
                  <div className='text-end mt-6'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({id: 'BUTTON_UPDATE'})}
                        type='submit'
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {PartnerSettingFormModal}
