/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {SUPPORTED_IMAGE_FORMATS} from '../../../../helpers'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import CustomInputFile from '../../form/CustomInputFile/CustomInputFile'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../form/CustomSelect/CustomSelect'
import {IUser} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionRoles?: any
  handleClose: () => void
  handleSubmit?: (values: IUser, id: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const UserFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault,
  listOptionRoles = [],
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const animatedComponents = makeAnimated()
  const validationSchema = Yup.object().shape({
    file: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.mixed()
          .test(
            'is-supported-format',
            `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
            (value: any) => {
              if (!value) return true
              return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
            }
          )
          .nullable(),
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    email: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    password: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    phone: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    // roles: Yup.
    status: Yup.string(),
  })

  const roles: any =
    initialValuesDefault?.roles?.map((item: any) => ({value: item?.id, label: item?.name})) || []

  const initialValues: IUser = {
    name: initialValuesDefault?.name,
    email: initialValuesDefault?.email,
    password: initialValuesDefault?.password,
    phone: initialValuesDefault?.phone,
    roles: roles,
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }
  const onSubmit = (values: IUser) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-900px'>
        <div className='modal-header bg-white row z-index-2 position-sticky top-0'>
          <div className='col-10 col-lg-10 col-md-10'>
            <h1 className='mt-2'>
              {intl.formatMessage({
                id: initialValuesDefault ? 'EDIT_ACCOUNT_MODAL_TITLE' : 'ADD_ACCOUNT_MODAL_TITLE',
              })}
            </h1>
          </div>
          <div className='col-2 col-lg-2 col-md-2 text-end'>
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            {/* end::Close */}
          </div>
        </div>
        <div className='modal-body mx-8'>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<IUser>) => (
              <Form className='pb-10'>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'AVATAR'})}
                    name='file'
                    type='text'
                    placeholder='화신'
                    autoComplete='off'
                    valueUrlDefault={initialValuesDefault?.avatar}
                    className='form-control form-control-solid'
                    component={CustomInputFile}
                    isRequired={!initialValuesDefault?.id}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'NAME'})}
                    name='name'
                    type='text'
                    placeholder='이름'
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'EMAIL'})}
                    name='email'
                    type='text'
                    placeholder='관리자 이름'
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'PHONE'})}
                    name='phone'
                    type='text'
                    placeholder='연락처'
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={!initialValuesDefault?.id}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'PASSWORD'})}
                    name='password'
                    type='password'
                    placeholder='연락처'
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={!initialValuesDefault?.id}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'ROLES'})}
                    name='roles'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={roles}
                    options={listOptionRoles}
                    component={CustomSelect}
                    isMulti={true}
                    isFullWidth={false}
                    isRequired={true}
                  />
                </div>
                <div className='row mb-7'>
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                </div>
                <div className='row float-end mb-7'>
                  {isShowButton && (
                    <ButtonPrimary
                      text={intl.formatMessage({
                        id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                      })}
                      type='submit'
                      className='me-6'
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {UserFormModal}
