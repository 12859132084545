import {NOTIFICATION_SCHEDULE_TYPE_OPTIONS} from '../../../constants/type'
import {apiService} from '../../../helpers/axios'
import {PostNotification} from '../../models/_notification'
import {
  PaginationResponse,
  ParamsRequestGetList,
  ResponseApi,
  ResponseData,
} from '../../models/_public'

type Option = {
  value: string
  label: string
}

const OPTION_SCHEDULE_TYPE_CUSTOM: Option | any =
  NOTIFICATION_SCHEDULE_TYPE_OPTIONS?.find((option: Option) => option.value === 'custom') || {}

export function fetchNotification(
  params: ParamsRequestGetList
): Promise<ResponseApi<ResponseData<any[]> & PaginationResponse>> {
  return apiService.get('/crm-notification', {params})
}

export function fetchDetailNotification(id: any): Promise<ResponseApi<any>> {
  return apiService.get(`/crm-notification/${id}`)
}
export function postMarkAllAsRead(params: any): Promise<ResponseApi<any>> {
  return apiService.post(`/crm-notification/read`, params)
}

export function postCreateNotification(
  dataForm: Partial<PostNotification>
): Promise<ResponseApi<Notification>> {
  const formData = new FormData()
  formData.append('news_id', dataForm?.news_id || '')
  formData.append('type', dataForm?.type || '')
  formData.append('is_user_request', String(0))
  dataForm?.partner_ids?.map((partner) => {
    formData.append('partner_ids[]', `${partner}`)
    return partner
  })
  if (dataForm?.type === OPTION_SCHEDULE_TYPE_CUSTOM?.value) {
    formData.append('date', dataForm?.date || '')
    formData.append('time', dataForm?.time || '')
  }
  return apiService.post(`/notifications/schedule`, formData)
}

export function postUpdateNotification(
  idNotification: string,
  dataForm: Partial<PostNotification>
): Promise<ResponseApi<Notification>> {
  const formData = new FormData()
  formData.append('news_id', dataForm?.news_id || '')
  formData.append('type', dataForm?.type || '')
  formData.append('is_user_request', String(0))
  dataForm?.partner_ids?.map((partner) => {
    formData.append('partner_ids[]', `${partner}`)
    return partner
  })
  if (dataForm?.type === OPTION_SCHEDULE_TYPE_CUSTOM?.value) {
    formData.append('date', dataForm?.date || '')
    formData.append('time', dataForm?.time || '')
  }
  return apiService.post(`/notifications/${idNotification}`, formData)
}

export function postCancelNotification(idNotification: string): Promise<ResponseApi<Notification>> {
  return apiService.post(`/notifications/schedule/${idNotification}`, {})
}

export function postUpdateStatusNotification(
  idNotification: string,
  status: boolean | number
): Promise<ResponseApi<Notification>> {
  return apiService.post(`/notifications/${idNotification}`, {status: Number(status)})
}

export function postDeleteNotification(
  idNotification: string,
  dataForm: Partial<PostNotification> | any = {}
): Promise<ResponseApi<Notification>> {
  return apiService.delete(`/notifications/${idNotification}`, {data: dataForm})
}
