/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React from 'react'
import {useIntl} from 'react-intl'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {SUPPORTED_IMAGE_FORMATS} from '../../../../helpers'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import CustomInputFile from '../../form/CustomInputFile/CustomInputFile'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import CustomSelect from '../../form/CustomSelect/CustomSelect'
import {INews} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionCategory: Option[]
  listOptionChannel: Option[]
  handleClose: () => void
  handleSubmit?: (values: INews, id: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const NewsFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  listOptionCategory = [],
  listOptionChannel = [],
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()
  const animatedComponents = makeAnimated()
  const validationSchema = Yup.object().shape({
    file: initialValuesDefault?.id
      ? Yup.string().nullable()
      : Yup.mixed()
          .test(
            'is-supported-format',
            `${intl.formatMessage({id: 'VALIDATION_ONLY_ALLOW_TYPE_FILE'})}`,
            (value: any) => {
              if (!value) return true
              return SUPPORTED_IMAGE_FORMATS?.includes(value.type)
            }
          )
          .nullable(),
    link: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    title: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    description: Yup.string().nullable(),
    content: Yup.string().nullable(),
    channel: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    category: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    status: Yup.string(),
  })
  const channel = initialValuesDefault?.channel
    ? {value: initialValuesDefault?.channel?.id, label: initialValuesDefault?.channel?.name}
    : listOptionChannel[0]
  const category = initialValuesDefault?.categories
    ? {
        value: initialValuesDefault?.categories[0]?.id,
        label: initialValuesDefault?.categories[0]?.name,
      }
    : listOptionCategory[0]
  const initialValues: INews = {
    title: initialValuesDefault?.title,
    link: initialValuesDefault?.link,
    description: initialValuesDefault?.description,
    content: initialValuesDefault?.content,
    channel: channel,
    category: category,
    status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }

  const onSubmit = (values: INews) => {
    handleSubmit && handleSubmit(values, initialValuesDefault?.id)
  }
  return (
    <ModalWrapper show={show}>
      <div className='modal-header w-100 position-sticky top-0 bg-white z-index-1'>
        <div className='d-flex justify-content-between w-100 '>
          <h1 className='mt-2'>
            {intl.formatMessage({
              id: initialValuesDefault?.id ? 'TITLE_UPDATE_NEWS' : 'TITLE_ADD_NEWS',
            })}
          </h1>
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {/* end::Close */}
        </div>
      </div>
      <div className='container-xxl w-lg-800px h-100'>
        <div className='modal-body h-100'>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({touched, errors, values}: FormikProps<INews>) => (
              <Form>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_IMAGE'})}
                    name='file'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_IMAGE'})}
                    autoComplete='off'
                    valueUrlDefault={initialValuesDefault?.image_path}
                    className='form-control form-control-solid'
                    isFullWidthImage={true}
                    isFullWidth={true}
                    component={CustomInputFile}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CHANNEL'})}
                    name='channel'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={channel}
                    options={listOptionChannel}
                    component={CustomSelect}
                    isMulti={false}
                    isFullWidth={true}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CATEGORY'})}
                    name='category'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={category}
                    options={listOptionCategory}
                    component={CustomSelect}
                    isMulti={false}
                    isFullWidth={true}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_LINK'})}
                    name='link'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_LINK'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_TITLE'})}
                    name='title'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_TITLE'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    isFullWidth={true}
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                    name='description'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                    autoComplete='off'
                    defaultValue={initialValuesDefault?.description || ''}
                    className=''
                    isFullWidth={true}
                    isEditor={true}
                    isRequired={false}
                    numberRow={12}
                    component={CustomInput}
                  />
                </div>
                {/* <div className="row mb-9">
                  <Field
                    label={intl.formatMessage({ id: 'FORM_CONTENT' })}
                    name='content'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'FORM_CONTENT' })}
                    autoComplete='off'
                    className=''
                    defaultValue={initialValuesDefault?.content || ''}
                    isFullWidth={true}
                    numberRow={4}
                    isEditor={true}
                    isRequired={false}
                    component={CustomInput}
                  />
                </div> */}
                <div className='row'>
                  <RadioGroup
                    id='status'
                    label={intl.formatMessage({id: 'FORM_STATUS'})}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name='status'
                      id='status0'
                      valueSet='false'
                      label={intl.formatMessage({id: 'FORM_DEACTIVATION'})}
                    />
                    <Field
                      component={Radio}
                      name='status'
                      id='status1'
                      valueSet='true'
                      label={intl.formatMessage({id: 'FORM_ACTIVATION'})}
                    />
                  </RadioGroup>
                  <div className='d-flex justify-content-end align-items-center w-100 mt-10'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export {NewsFormModal}
