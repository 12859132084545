/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useFilterHeaderData} from '../../../../partials/tdi-mc/filter-header/FilterHeaderData'

const ToolbarAccounting: FC = () => {
  const {filterHeader} = useFilterHeaderData()

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])
  return filterHeader ? filterHeader : null
}

export {ToolbarAccounting}
