import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {IParamsSearch} from '../../../../_metacrew/api/models/_public'
import {ROLES_FOR_APP, isHavePermissionRole} from '../../../../_metacrew/constants/role'
import {STATUS_INQUIRIES_OPTIONS} from '../../../../_metacrew/constants/status'
import {INQUIRIES_TYPE_OPTIONS} from '../../../../_metacrew/constants/type'
import {KTIcon} from '../../../../_metacrew/helpers'
import {formatDate} from '../../../../_metacrew/helpers/formatDate'
import useListCS from '../../../../_metacrew/helpers/hooks/cs/useListCS'
import {Card6} from '../../../../_metacrew/partials/content/cards/Card6'
import {
  ButtonEdit,
  ButtonLight,
  PageTitleInner,
  RangePickerCustom,
  Search,
  SelectCustom,
  Tables,
} from '../../../../_metacrew/partials/tdi-mc'
import {DropdownLimit} from '../../../../_metacrew/partials/tdi-mc/dropdowns/DropdownLimit'
import {ButtonCSStatus} from '../../../../_metacrew/partials/tdi-mc/form/Buttons/ButtonCSStatus/ButtonCSStatus'
import {useAuth} from '../../auth'

const CSList: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  // Get full permissions of account login
  const {currentUser: {roles: {permissions = []} = {}} = {}} = useAuth()
  const timeoutRef = useRef<any>(null)

  const INQUIRIES_TYPE_OPTIONS_LANGUAGE = INQUIRIES_TYPE_OPTIONS?.map((item) => ({
    ...item,
    label: intl.formatMessage({id: `INQUIRIES_TYPE_${item?.label}`}),
  }))

  const INQUIRIES_STATUS_OPTIONS_LANGUAGE = STATUS_INQUIRIES_OPTIONS?.map((item) => ({
    ...item,
    label: intl.formatMessage({id: `STATUS_${item?.label}`}),
  }))

  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    keyword: '',
    limit: 10,
    start_date: '',
    end_date: '',
    status: '',
    type: '',
  })

  const {data, isFetching, handleChangeParams} = useListCS()

  const columns = useMemo(
    () => [
      {
        label: 'title',
        title: intl.formatMessage({id: 'COL_CS_TITLE'}),
        className: 'min-w-150px  text-center',
        classNameComponent: 'text-center',
        click: (row: any) =>
          isHavePermissionRole(
            [ROLES_FOR_APP.INQUIRIES_VIEW, ROLES_FOR_APP.INQUIRIES_UPDATE],
            permissions
          ) && navigate(`/cs/${row?.id}`),
      },
      {
        label: 'inquiry_type',
        title: intl.formatMessage({id: 'COL_CS_INQUIRY_TYPE'}),
        width: '200px',
        className: 'min-w-200px text-center',
        classNameComponent: 'text-center',
      },
      {
        label: 'email',
        title: intl.formatMessage({id: 'COL_CS_INQUIRY_EMAIL'}),
        className: 'min-w-150px text-center',
        classNameComponent: 'text-center',
      },
      {
        label: 'status',
        title: intl.formatMessage({id: 'COL_CS_REPLY_STATUS'}),
        className: 'min-w-80px text-center',
        classNameComponent: 'text-center',
        component: (row: any) => (
          <ButtonCSStatus text='' type='button' status={row?.status} id={row?.id} />
        ),
      },
      {
        label: 'registration_date',
        title: intl.formatMessage({id: 'COL_CS_INQUIRY_DATE'}),
        width: '150px',
        className: 'min-w-150px text-center',
        classNameComponent: 'text-center',
        component: (row: any) => formatDate(row?.created_at),
      },
      {
        label: 'reply_date',
        title: intl.formatMessage({id: 'COL_CS_REPLY_DATE'}),
        className: 'min-w-200px text-center',
        classNameComponent: 'text-center',
        component: (row: any) => formatDate(row?.created_at),
      },
      {
        label: 'answer',
        title: intl.formatMessage({id: 'COL_CS_REPLY'}),
        width: '150px',
        className: 'min-w-100px text-center',
        classNameComponent: 'text-center',
        isNone: !isHavePermissionRole(ROLES_FOR_APP.INQUIRIES_UPDATE, permissions),
        component: (row: any) => (
          <ButtonEdit data={row} onClickButton={() => navigate(`/cs/${row?.id}`)} />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  )

  // =======Begin::search and pagination table=======
  const handleSearch = (params: IParamsSearch) => {
    setSearchParams((_prev) => ({
      ..._prev,
      ...params,
      page: 1,
    }))
  }

  const handleChangePage = (numberPage: number) => {
    setSearchParams((_prev) => ({
      ..._prev,
      page: numberPage,
    }))
  }

  const handleOnClickReset = () => {
    setSearchParams((_prev) => ({
      keyword: '',
      limit: 10,
      start_date: '',
      end_date: '',
      status: '',
      type: '',
    }))
  }

  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current)
    }
    timeoutRef.current = setTimeout(() => handleChangeParams(searchParams), 600)
  }, [handleChangeParams, searchParams])

  // =======End::search and pagination table=======

  const handleRedirectCSForm = () => {
    navigate('/cs/create')
  }

  return (
    <>
      <PageTitleInner
        title={intl.formatMessage({id: 'INQUIRIES_MANAGEMENT'})}
        className='col-12'
      ></PageTitleInner>
      {/* begin::Row */}
      <div className='row g-6 g-xl-9'>
        <div className='col-sm-6 col-md-3 col-xl-2 mb-5 mb-xl-10'>
          <Card6
            image=''
            title={intl.formatMessage({id: 'TITLE_CARD_ALL_INQUIRIES'})}
            description={String(data?.extra?.total || 0)}
            status='up'
            statusValue={100}
            statusDesc=''
            progress={0.5}
            progressType='MRR'
          />
        </div>
        <div className='col-sm-6 col-md-3 col-xl-2 mb-5 mb-xl-10'>
          <Card6
            image=''
            title={intl.formatMessage({id: 'TITLE_CARD_COMPLETED_INQUIRIES'})}
            description={String(data?.extra?.completed || 0)}
            status='up'
            statusValue={100}
            statusDesc=''
            progress={0.5}
            progressType='MRR'
          />
        </div>
        <div className='col-sm-6 col-md-3 col-xl-2 mb-5 mb-xl-10'>
          <Card6
            image=''
            title={intl.formatMessage({id: 'TITLE_CARD_PENDING_INQUIRIES'})}
            description={String(data?.extra?.pending || 0)}
            status='up'
            statusValue={100}
            statusDesc=''
            progress={0.5}
            progressType='MRR'
          />
        </div>
        {isHavePermissionRole(ROLES_FOR_APP.INQUIRIES_CREATE, permissions) && (
          <div className='col-sm-6 col-md-6 col-xl-6 mb-5 mb-xl-10 d-flex align-items-center justify-content-end'>
            <button
              className='btn btn-md btn-icon w-auto fw-bold btn-primary explore-btn-mc px-3'
              onClick={handleRedirectCSForm}
            >
              <KTIcon iconName='plus' className='fs-2 me-2' />
              <span style={{paddingTop: '2px'}}>
                {intl.formatMessage({id: 'TITLE_ADD_INQUIRIES'})}
              </span>
            </button>
          </div>
        )}
      </div>
      {/* e nd::Row */}
      {/* begin::Row  */}
      {isHavePermissionRole(ROLES_FOR_APP.INQUIRIES_LIST, permissions) && (
        <div className='row g-6 g-xl-9'>
          <div className='col-sm-12 col-xl-12 mb-5 mb-xl-5'>
            <div className='card'>
              <div className='card-header border-0 pt-6'>
                <div className='col-12'>
                  {/* begin::Row  */}
                  <div className='row g-6 g-xl-9 pt-5'>
                    <div className='col-sm-12 col-md-12  col-xl-1 col-lg-1 mb-5 mb-xl-8 mt-5 mt-xl-8'>
                      <DropdownLimit handleSearch={handleSearch} value={searchParams?.limit} />
                    </div>
                    <div className='col-sm-12 col-md-12  col-xl-3 col-lg-4 mb-5 mb-xl-8 mt-5 mt-xl-8'>
                      <Search handleSearch={handleSearch} value={searchParams?.keyword} />
                    </div>
                    <div className='col-sm-12 col-md-6  col-xl-3 col-lg-3 mb-5 mb-xl-8 mt-5 mt-xl-8'>
                      <div className='d-flex'>
                        <div className='w-100'>
                          <RangePickerCustom
                            handleSearch={handleSearch}
                            value={[searchParams?.start_date || '', searchParams?.end_date || '']}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-4  col-xl-2 col-lg-2 mb-5 mb-xl-8 mt-5 mt-xl-8'>
                      <div className='d-flex flex-column'>
                        <div className='w-100'>
                          <SelectCustom
                            handleSearch={handleSearch}
                            title={intl.formatMessage({id: 'SEARCH_INQUIRY_TYPE'})}
                            name='type'
                            options={INQUIRIES_TYPE_OPTIONS_LANGUAGE}
                            value={searchParams?.type || ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-4  col-xl-2 col-lg-2 mb-5 mb-xl-8 mt-5 mt-xl-8'>
                      <div className='d-flex flex-column'>
                        <div className='w-100'>
                          <SelectCustom
                            handleSearch={handleSearch}
                            title={intl.formatMessage({id: 'SEARCH_STATUS'})}
                            name='status'
                            options={INQUIRIES_STATUS_OPTIONS_LANGUAGE}
                            value={searchParams?.status || ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-end justify-content-start col-sm-12 col-md-2  col-xl-1 col-lg-1 mb-5 mb-xl-8 mt-5 mt-xl-8 '>
                      <ButtonLight handleOnClick={handleOnClickReset} />
                    </div>
                  </div>
                  {/* end::Row  */}
                </div>
                <div></div>
              </div>
              <Tables
                className='table-background-gray'
                columns={columns}
                isLoading={isFetching}
                data={data?.items || []}
                pagination={data?.meta}
                onChangePage={handleChangePage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {CSList}
