import { apiService } from '../../helpers/axios';
import { Membership, PostMembership } from '../models/_membership';
import { PaginationResponse, ParamsRequestGetList, ResponseApi, ResponseData } from '../models/_public';

const BASE_URL_UPLOAD_FILE = process.env.REACT_APP_BASE_URL_UPLOAD_FILE || ''

export function fetchMembership(params:ParamsRequestGetList): Promise<ResponseApi<ResponseData<Membership[]> & PaginationResponse>> {
  return apiService.get('/users', { params })
}


export function fetchDetailMembership(id:string, params:any = {}): Promise<ResponseApi<Membership>> {
  return apiService.get(`/users/${id}`, { params })
}


export function postCreateMembership(dataForm: Partial<PostMembership>): Promise<ResponseApi<Membership>> {
    const formData = new FormData();
    formData.append('avatar', dataForm?.avatar ? dataForm?.avatar as File : '');
    formData.append('name', dataForm?.name ?? '');
    formData.append('email', dataForm?.email ?? '');
    formData.append('password', dataForm?.password ?? '');
    formData.append('phone', dataForm?.phone ?? '');
    formData.append('type', dataForm?.type ?? '');
    formData.append('advertiser_id', dataForm?.advertiser_id ?? '');
    formData.append('channel_id', dataForm?.channel_id ?? '');
    formData.append('roles[]', String(dataForm?.roles ?? ''));
    formData.append('manager_name', dataForm?.manager_name ?? '');
    formData.append('manager_contact', dataForm?.manager_contact ?? '');
    formData.append('status', String(Number(dataForm?.status )));
    formData.append('is_user_request', String(0));
    return apiService.post(`/users`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdateMembership(idMembership:string, dataForm: Partial<PostMembership>): Promise<ResponseApi<Membership>> {
  const formData = new FormData();
    formData.append('avatar', dataForm?.avatar ? dataForm?.avatar as File : '');
    formData.append('name', dataForm?.name ?? '');
    formData.append('email', dataForm?.email ?? '');
    formData.append('password', dataForm?.password ?? '');
    formData.append('phone', dataForm?.phone ?? '');
    formData.append('type', dataForm?.type ?? '');
    formData.append('advertiser_id', dataForm?.advertiser_id ?? '');
    formData.append('channel_id', dataForm?.channel_id ?? '');
    formData.append('roles[]', String(dataForm?.roles ?? ''));
    formData.append('manager_name', dataForm?.manager_name ?? '');
    formData.append('manager_contact', dataForm?.manager_contact ?? '');
    formData.append('status', String(Number(dataForm?.status )));
    formData.append('is_user_request', String(0));
    return apiService.post(`/users/${idMembership}`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdatePasswordMembership(idMembership:string, dataForm: Partial<PostMembership>): Promise<ResponseApi<Membership>> {
  const formData = new FormData();
    formData.append('password', dataForm?.password ?? '');
    return apiService.post(`/users/${idMembership}`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdateStatusMembership(idMembership:string, status: boolean | number): Promise<ResponseApi<Membership>> {
  return apiService.post(`/users/${idMembership}`, {status: Number(status)})
}

export function postDeleteMembership(idMembership:string, dataForm: Partial<PostMembership> | any = {}): Promise<ResponseApi<Membership>> {
  return apiService.delete(`/users/${idMembership}`, { data: dataForm});
}