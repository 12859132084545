function padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(dateString: string = '') {
    const date = dateString ? new Date(dateString) :  new Date();
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

export { formatDate }