/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {IParamsSearch} from '../../../_metacrew/api/models/_public'
import {getStartDateEndDate} from '../../../_metacrew/helpers'
import useActiveUser from '../../../_metacrew/helpers/hooks/dashboard/useActiveUser'
import useDashboard from '../../../_metacrew/helpers/hooks/dashboard/useDashboard'
import useDashboardChannel from '../../../_metacrew/helpers/hooks/dashboard/useDashboardChannel'
import useDashboardDeviceOs from '../../../_metacrew/helpers/hooks/dashboard/useDashboardDeviceOs'
import useDashboardReaction from '../../../_metacrew/helpers/hooks/dashboard/useDashboardReaction'
import useDashboardViews from '../../../_metacrew/helpers/hooks/dashboard/useDashboardViews'
import useListOverallStatistics from '../../../_metacrew/helpers/hooks/push-analytics/useListOverallStatistics'
import useListCohortGraph from '../../../_metacrew/helpers/hooks/user-statistics/useListCohortGraph'
import {PageTitle} from '../../../_metacrew/layout/core'
import {RangePickerCustom} from '../../../_metacrew/partials/tdi-mc'
import {FilterHeader} from '../../../_metacrew/partials/tdi-mc/filter-header/FilterHeaderData'
import ActiveUserChart from './components/ActiveUserChart'
import {ChannelChart} from './components/ChannelChart'
import {DeviceOsChart} from './components/DeviceOsChart'
import MediaAndDeviceChart from './components/MediaAndDeviceChart'
import NewViewsChart from './components/NewViewsChart'
import PushClickCTRChart from './components/PushClickCTRChart'
import ReactionAndCommentChart from './components/ReactionAndCommentChart'

const DashboardPage: FC = () => {
  const intl = useIntl()
  const timeoutRef = useRef<any>(null)
  const date = getStartDateEndDate(7)

  const {
    data: dataUserActive,
    // eslint-disable-next-line
    isFetching: isFetchingUserActive,
    handleChangeParams: handleChangeParamsUserActive,
  } = useActiveUser({
    start_date: date[0],
    end_date: date[1],
  })

  const {
    data: dataDashboardChannel,

    isFetching: isFetchingDashboardChannel,
    // eslint-disable-next-line
    handleChangeParams: handleChangeParamsDashboardChannel,
  } = useDashboardChannel({
    start_date: date[0],
    end_date: date[1],
  })

  const {
    data: dataDashboardReactionAndComment,

    isFetching: isFetchingDashboardReactionAndComment,
    // eslint-disable-next-line
    handleChangeParams: handleChangeParamsDashboardReactionAndComment,
  } = useDashboardReaction({
    start_date: date[0],
    end_date: date[1],
  })

  const {
    data: dataDashboardOs,

    isFetching: isFetchingDashboardOs,
    // eslint-disable-next-line
    handleChangeParams: handleChangeParamsDashboardOs,
  } = useDashboardDeviceOs({
    start_date: date[0],
    end_date: date[1],
  })
  const {
    data: dataCohort,
    isFetching: isFetchingCohort,
    handleChangeParams: handleChangeParamsCohort,
  } = useListCohortGraph({
    start_date: date[0],
    end_date: date[1],
  })
  const {
    data: dataDashboardViews,

    isFetching: isFetchingDashboardViews,
    // eslint-disable-next-line
    handleChangeParams: handleChangeParamsDashboardViews,
  } = useDashboardViews({
    start_date: date[0],
    end_date: date[1],
  })

  const {
    data: dataList,
    // eslint-disable-next-line
    isFetching: isFetchingChart,
    handleChangeParams: handleChangeParamsChart,
  } = useListOverallStatistics({
    start_date: date[0],
    end_date: date[1],
  })
  // eslint-disable-next-line
  const {data, handleChangeParams, isFetching} = useDashboard({
    start_date: date[0],
    end_date: date[1],
  })
  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    start_date: date[0],
    end_date: date[1],
  })
  const handleSearch = useCallback((params: IParamsSearch) => {
    setSearchParams((_prev) => ({
      ..._prev,
      ...params,
    }))
  }, [])

  const dataRange = useMemo(() => {
    const date1 = new Date(searchParams.start_date || '0')
    const date2 = new Date(searchParams.end_date || '0')

    const differenceInMilliseconds = date2.getTime() - date1.getTime()

    return differenceInMilliseconds / (1000 * 60 * 60 * 24)
  }, [searchParams])

  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current)
    }
    timeoutRef.current = setTimeout(() => {
      handleChangeParams(searchParams)
      handleChangeParamsChart(searchParams)
      handleChangeParamsUserActive(searchParams)
      handleChangeParamsDashboardOs(searchParams)
      handleChangeParamsDashboardChannel(searchParams)
      handleChangeParamsDashboardReactionAndComment(searchParams)
      handleChangeParamsDashboardViews(searchParams)
      handleChangeParamsCohort(searchParams)
    }, 600)
  }, [
    handleChangeParams,
    handleChangeParamsDashboardOs,
    handleChangeParamsDashboardChannel,
    searchParams,
    handleChangeParamsChart,
    handleChangeParamsUserActive,
    handleChangeParamsDashboardReactionAndComment,
    handleChangeParamsDashboardViews,
    handleChangeParamsCohort,
  ])
  // return <div className='w-100 d-flex justify-content-center fs-2 fw-bold'>개발 중인 기능</div>
  // eslint-disable-next-line

  return (
    <>
      <FilterHeader>
        <div className='w-100 px-0 py-3 rounded d-flex align-items-center flex-wrap me-5 filter-header gap-4'>
          <RangePickerCustom
            isHiddenLine
            isBgWhite={true}
            isLabel={false}
            handleSearch={handleSearch}
            value={[searchParams?.start_date || '', searchParams?.end_date || '']}
          />
        </div>
      </FilterHeader>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10'>
        <div className='col-xxl-12 p-4'>
          <div className='row'>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <ChannelChart
                className='h-375px'
                isLoading={isFetchingDashboardChannel}
                data={dataDashboardChannel && dataDashboardChannel.items}
                total={dataDashboardChannel && dataDashboardChannel.total}
              />
            </div>
            {/* {dataUserActive && ( */}
            <div className='col-md-6 col-xxl-4 mb-5'>
              <ActiveUserChart
                numberDay={dataRange}
                className='h-375px'
                isLoading={isFetchingUserActive}
                title={intl.formatMessage({id: 'DASHBOARD_NUMBER_OF_NEWS_CRAWLED_EACH_DAY'})}
                data={dataUserActive}
                colors={['info', 'warning']}
              />
            </div>
            {/* )} */}

            <div className='col-md-6 col-xxl-4 mb-5'>
              <DeviceOsChart
                className='h-375px'
                isLoading={isFetchingDashboardOs}
                data={dataDashboardOs && dataDashboardOs}
                total={dataDashboardOs && dataDashboardOs}
              />
            </div>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <ReactionAndCommentChart
                className='h-375px'
                isLoading={isFetchingDashboardReactionAndComment}
                data={dataDashboardReactionAndComment}
              />
            </div>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <NewViewsChart
                className='h-375px'
                isLoading={isFetchingDashboardViews}
                data={dataDashboardViews as any}
              />
            </div>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <MediaAndDeviceChart
                className='h-375px'
                isLoading={isFetching}
                data={dataList && (dataList.items as any)}
              />
            </div>

            {/* begin::Col */}

            <div className='col-md-6 col-xxl-4 mb-5'>
              {dataCohort && dataCohort?.data && (
                <PushClickCTRChart
                  numberDay={dataRange}
                  className='h-375px'
                  isLoading={isFetchingCohort}
                  title={intl.formatMessage({id: 'DASHBOARD_NUMBER_OF_NEWS_CRAWLED_EACH_DAY'})}
                  data={dataCohort && dataCohort.avg}
                  colors={['info', 'warning']}
                  date={Object.keys(dataCohort && dataCohort?.data)}
                />
              )}
            </div>
            {/* end::Col */}

            {/* <div className='col-md-6 col-xxl-4 mb-5'>
              <CloudWords
                className='h-375px'
                isLoading={isFetching}
                title={intl.formatMessage({id: 'DASHBOARD_INTEREST_KEYWORD_STATUS'})}
                words={data?.interested_keyword || []}
              />
            </div> */}

            {/* <div className='col-md-6 col-xxl-4 mb-5'>
              <LineChart
                className='h-375px'
                isLoading={isFetching}
                title={intl.formatMessage({id: 'DASHBOARD_CLICKS_BY_CAMPAIGN_TYPE'})}
                data={data?.click_by_campaign_type}
                colors={['info', 'primary']}
              />
            </div>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <LineChart
                className='h-375px'
                isLoading={isFetching}
                title={intl.formatMessage({id: 'DASHBOARD_IMPRESSIONS_BY_CAMPAIGN_TYPE'})}
                data={data?.show_by_campaign_type}
                colors={['warning', 'danger']}
              />
            </div>
            <div className='col-md-6 col-xxl-4 mb-5'>
              <BarChart
                className=' h-375px'
                isLoading={isFetching}
                title={intl.formatMessage({id: 'DASHBOARD_CTR_BY_CAMPAIGN_TYPE'})}
                data={data?.ctr_by_campaign_type}
                colors={['success', 'warning']}
              />

            </div> */}
          </div>
        </div>

        {/* <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
        <BarChartHorizontal className='h-100' title="관심 언론사 현황" data={data?.news_channel || []}  colors={['info', 'primary']}  />
        <TableCompany className='card-bordered h-auto' title="관심 언론사 현황" data={data?.media_company || []} />
      </div> */}

        {/* <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 h-500px'>
        <CloudWords className='h-md-100' title="관심 키워드 현황" words={data?.interested_keyword || []} />  
        <LineChart className='card-bordered' title="캠페인 유형별 CTR" data={data?.ctr_by_campaign_type} colors={['success', 'warning']} />
      </div> */}
        {/* begin::Col */}
        {/* <div className='col-xxl-12'>
        <EngageWidget10 className='h-md-100' />
      </div> */}
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU_DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
