import { apiService } from '../../helpers/axios';
import { Content, PostContent } from '../models/_content';
import { PaginationResponse, ParamsRequestGetList, ResponseApi, ResponseData } from '../models/_public';

const BASE_URL_UPLOAD_FILE = process.env.REACT_APP_BASE_URL_UPLOAD_FILE || ''

export function fetchContent(params:ParamsRequestGetList): Promise<ResponseApi<ResponseData<Content[]> & PaginationResponse>> {
  return apiService.get('/contents', { params })
}

export function fetchDetailContent(id:string, params:any = {}): Promise<ResponseApi<Content>> {
  return apiService.get(`/contents/${id}`, { params })
}

export function postCreateContent(dataForm: Partial<PostContent>): Promise<ResponseApi<Content>> {
    const formData = new FormData();
    formData.append('title', dataForm?.title ?? '');
    formData.append('file', dataForm?.file ? dataForm?.file as File : '');
    // formData.append('member_type', dataForm?.member_type ?? '');
    // formData.append('channel_id', dataForm?.channel_id ?? '');
    formData.append('advertiser_id', dataForm?.advertiser_id ?? '');
    formData.append('campaign_type', dataForm?.campaign_type ?? '');
    formData.append('type', dataForm?.material_type ?? '');
    formData.append('status', String(Number(dataForm?.status )));
    // formData.append('reason', dataForm?.reason ?? '');
    formData.append('is_user_request', String(0));
    return apiService.post(`/contents`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}

export function postUpdateContent(idContent:string, dataForm: Partial<PostContent>): Promise<ResponseApi<Content>> {
  const formData = new FormData();
    formData.append('title', dataForm?.title ?? '');
    formData.append('file', dataForm?.file ? dataForm?.file as File : '');
    // formData.append('member_type', dataForm?.member_type ?? '');
    // formData.append('channel_id', dataForm?.channel_id ?? '');
    formData.append('advertiser_id', dataForm?.advertiser_id ?? '');
    formData.append('campaign_type', dataForm?.campaign_type ?? '');
    formData.append('type', dataForm?.material_type ?? '');
    formData.append('status', String(dataForm?.status ?? ''));
    formData.append('reason', dataForm?.reason ?? '');
    formData.append('is_user_request', String(0));
    return apiService.post(`/contents/${idContent}`, formData ,{ 
      baseURL: BASE_URL_UPLOAD_FILE,
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
}


export function postUpdateStatusContent(idContent:string, status: boolean | number): Promise<ResponseApi<Content>> {
  return apiService.post(`/contents/${idContent}`, {status: Number(status)})
}

export function postDeleteContent(idContent:string, dataForm: Partial<PostContent> | any = {}): Promise<ResponseApi<Content>> {
  return apiService.delete(`/contents/${idContent}`, { data: dataForm});
}