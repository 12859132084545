type Option = {
  value: string
  label: string
}

export const STATUS_CATEGORY: any = {
  APPROVAL: '승인',
  REJECTION: '거절',
  UNCONFIRMED: '미확인',
  PENDING: '보류',
}

const STATUS_RUN: any = {
  error: 'ERROR',
  success: 'SUCCESS',
  pending: 'PENDING',
}

const STATUS_CONTENT: any = {
  pending: 'PENDING',
  approved: 'APPROVAL',
  declined: 'REJECTION',
}

const STATUS_CAMPAIGN: any = {
  pending: 'PENDING',
  live: 'LIVE',
  finished: 'FINISHED',
}

const STATUS_INQUIRIES: any = {
  completed: 'COMPLETED',
  pending: 'UNANSWERED',
}

const STATUS_NORMAL: any = {
  '0': 'INACTIVE',
  '1': 'ACTIVE',
}

const STATUS_MATCHING_CRITERIA: any = {
  '': 'ALL',
  title: 'TITLE',
  body: 'BODY',
}

const STATUS_NOTIFICATION_SCHEDULE: any = {
  pending: 'PENDING',
  success: 'SUCCESS',
  error: 'ERROR',
  cancel: 'CANCEL',
}

const IS_READ_OPTIONS: any = {
  '0': 'NO',
  '1': 'YES',
}

const OS_OPTIONS: any = {
  android: 'ANDROID',
  ios: 'IOS',
}

const EVENT_OPTIONS: any = {
  '0': 'SENDING',
  '1': 'CLICK',
}

const TYPE_OF_PUSH__OPTIONS: any = {
  partner: 'PARTNER',
  auto: 'AUTO',
  manual: 'MANUAL',
  emergency: 'EMERGENCY',
}

const TICKET_TYPE_OPTIONS: any = {
  error: 'ERROR',
  support: 'SUPPORT',
}

const TICKET_STATUS_OPTIONS: any = {
  open: 'OPEN',
  closed: 'CLOSED',
}

const TICKET_PRIORITY_OPTIONS: any = {
  critical: 'CRITICAL',
  high: 'HIGH',
  normal: 'NORMAL',
  low: 'LOW',
}

const TO_OPTIONS: any = {
  partner: 'PARTNER',
  device: 'DEVICE',
  platform: 'PLATFORM',
}

const KEY_TYPE_USER_LOG: any = {
  '0': 'OPEN_APP',
  '1': 'REGISTER',
  '2': 'VIEW_NEWS_TAB',
  '3': 'VIEW_STOCK_TAB',
  '4': 'VIEW_WEATHER_TAB',
  '5': 'VIEW_ARTICLE_CATEGORY',
  '6': 'CLICK_ARTICLE_KEYWORD',
  '7': 'VIEW_ARTICLE',
  '8': 'BOOKMARK_ARTICLE',
  '9': 'VIEW_STOCK',
  '10': 'BOOKMARK_STOCK',
  // '3': 'VIEW_ARTICLE_CATEGORY',
  // '4': 'CLICK_ARTICLE_KEYWORD',
  // '5': 'VIEW_ARTICLE',
  // '6': 'BOOKMARK_ARTICLE',
  // '7': 'VIEW_STOCK',
  // '8': 'BOOKMARK_STOCK',
}

const STATUS_CONTENT_OPTIONS = Object.entries(STATUS_CONTENT).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]
const STATUS_CAMPAIGN_OPTIONS = Object.entries(STATUS_CAMPAIGN).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]
const STATUS_INQUIRIES_OPTIONS = Object.entries(STATUS_INQUIRIES).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]
const STATUS_RUN_OPTIONS = Object.entries(STATUS_RUN).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const STATUS_NORMAL_OPTIONS = Object.entries(STATUS_NORMAL).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const STATUS_MATCHING_CRITERIA_OPTIONS = Object.entries(STATUS_MATCHING_CRITERIA).map(
  ([key, value]) => ({
    label: value ?? '',
    value: key ?? '',
  })
) as Option[]

const STATUS_NOTIFICATION_SCHEDULE_OPTIONS = Object.entries(STATUS_NOTIFICATION_SCHEDULE).map(
  ([key, value]) => ({label: value ?? '', value: key ?? ''})
) as Option[]

const STATUS_IS_READ_NORMAL_OPTIONS = Object.entries(IS_READ_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const STATUS_OS_NORMAL_OPTIONS = Object.entries(OS_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const OPTIONS_KEY_TYPE_USER_LOG = Object.entries(KEY_TYPE_USER_LOG).map(([key, value]) => ({
  label: value ?? '',
  value: value ?? '',
})) as Option[]

const OPTIONS_TYPE_OF_PUSH = Object.entries(TYPE_OF_PUSH__OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: value ?? '',
})) as Option[]

const STATUS_EVENT_OPTIONS = Object.entries(EVENT_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: value ?? '',
})) as Option[]

const STATUS_TICKET_TYPE_OPTIONS = Object.entries(TICKET_TYPE_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const STATUS_TICKET_STATUS_OPTIONS = Object.entries(TICKET_STATUS_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const STATUS_TICKET_PRIORITY_OPTIONS = Object.entries(TICKET_PRIORITY_OPTIONS).map(
  ([key, value]) => ({
    label: value ?? '',
    value: key ?? '',
  })
) as Option[]

const NOTIFICATION_TO_OPTIONS = Object.entries(TO_OPTIONS).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

export {
  STATUS_CONTENT_OPTIONS,
  STATUS_CONTENT,
  STATUS_CAMPAIGN,
  STATUS_CAMPAIGN_OPTIONS,
  STATUS_INQUIRIES,
  STATUS_INQUIRIES_OPTIONS,
  STATUS_RUN,
  STATUS_RUN_OPTIONS,
  STATUS_NORMAL,
  STATUS_NORMAL_OPTIONS,
  STATUS_NOTIFICATION_SCHEDULE,
  STATUS_NOTIFICATION_SCHEDULE_OPTIONS,
  STATUS_IS_READ_NORMAL_OPTIONS,
  STATUS_OS_NORMAL_OPTIONS,
  OPTIONS_KEY_TYPE_USER_LOG,
  STATUS_EVENT_OPTIONS,
  STATUS_MATCHING_CRITERIA_OPTIONS,
  OPTIONS_TYPE_OF_PUSH,
  STATUS_TICKET_TYPE_OPTIONS,
  STATUS_TICKET_STATUS_OPTIONS,
  STATUS_TICKET_PRIORITY_OPTIONS,
  NOTIFICATION_TO_OPTIONS,
}
