/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {DropdownIndicatorProps, components} from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'
import {Content} from '../../../../../api/models/_content'
import {fetchContent} from '../../../../../api/requests/_content'
import {STATUS_CONTENT} from '../../../../../constants/status'
import {CAMPAIGN_TYPE_OPTIONS, MATERIAL_TYPE} from '../../../../../constants/type'
import {extractWidthAndHeightFromURL, getKeyByValue} from '../../../../../helpers'
import {ButtonCancel} from '../../../form/Buttons/ButtonCancel/ButtonCancel'
import {ButtonPrimary} from '../../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../../form/CustomInput/CustomInput'
import CustomInputRangePicker from '../../../form/CustomInputRangePicker/CustomInputRangePicker'
import CustomSelect from '../../../form/CustomSelect/CustomSelect'
import {ICampaign} from '../../ModalModel'
import './style.scss'
import Loading from '../../../../../../app/pages/dashboard/components/Loading/Loading'

type Props = {
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionChannel: Option[]
  listOptionAdvertiserGroup?: Option[]
  listOptionUser?: Option[]
  handleSubmit?: (values: ICampaign, id?: string | undefined) => void
}

type Option = {
  value: string
  label: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z' fill='#111111' />
      </svg>
    </components.DropdownIndicator>
  )
}

const CampaignFormModal: React.FC<Props> = ({
  isLoading = false,
  isShowButton = false,
  initialValuesDefault = {},
  listOptionChannel = [],
  listOptionUser = [],
  listOptionAdvertiserGroup = [],
  handleSubmit,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [listOptionContent, setListOptionContent] = useState<Content[]>([])
  const animatedComponents = makeAnimated()
  const [contentIdChecked, setContentIdChecked] = useState<string>('')

  const CAMPAIGN_TYPE_OPTIONS_LANGUAGE = CAMPAIGN_TYPE_OPTIONS?.map((item) => ({
    ...item,
    label: intl.formatMessage({id: `CAMPAIGN_TYPE_${item?.label}`}),
  }))

  // const member_type = MEMBER_TYPE_OPTIONS_LANGUAGE[0];
  const advertiser_group = listOptionAdvertiserGroup[0] || {value: '', label: ''}
  const broadcasting_channel = listOptionChannel[0] || {value: '', label: ''}
  const campaign_type = CAMPAIGN_TYPE_OPTIONS_LANGUAGE[0] || {value: '', label: ''}
  const contact_person_account = listOptionUser[0] || {value: '', label: ''}
  const campaign_schedule = ['', '']
  const initialValues: ICampaign = {
    campaign_name: '',
    // member_type: member_type,
    advertiser_group: advertiser_group,
    contact_person_account: contact_person_account,
    broadcasting_channel: broadcasting_channel,
    campaign_type: campaign_type,
    campaign_schedule: campaign_schedule,
    // status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true'
  }

  const [filterContent, setFilterContent] = useState({
    advertiser_id: advertiser_group?.value,
    campaign_type: campaign_type?.value,
  })

  const validationSchema = Yup.object().shape({
    campaign_name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    advertiser_group: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    contact_person_account: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    broadcasting_channel: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    campaign_type: Yup.object({
      label: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
      value: Yup.string().notOneOf([''], `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    })
      .required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`)
      .test(
        'validate-select-option',
        `${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`,
        (value: any) => {
          if (typeof value !== 'object' || !value.label || !value.value) {
            return false
          }
          return true
        }
      ),
    campaign_schedule: Yup.array()
      .of(Yup.string())
      .test(
        'start-date-required',
        `${intl.formatMessage({id: 'VALIDATION_START_DATE_REQUIRED'})}`,
        (values: any) => {
          return !!values[0]
        }
      )
      .test(
        'end-date-required',
        `${intl.formatMessage({id: 'VALIDATION_END_DATE_REQUIRED'})}`,
        (values: any) => {
          return !!values[1]
        }
      ),
  })

  const onSubmit = (values: ICampaign) => {
    handleSubmit && handleSubmit({...values, content_id: contentIdChecked})
  }

  const getListOptionContent = async (params = {}) => {
    setLoading((_prev) => true)
    const res = await fetchContent(params)
    const options = res?.data || []
    // @ts-ignore
    const optionsFilter = options?.filter(
      (item: any) => item?.status === getKeyByValue(STATUS_CONTENT, 'APPROVAL')
    )
    setContentIdChecked((_prev) => optionsFilter[0]?.id ?? '')
    // @ts-ignore
    setListOptionContent((_prev) => optionsFilter)
    setLoading((_prev) => false)
  }

  const handleOnChangeContentId = (itemId: string) => {
    setContentIdChecked((_prev) => itemId)
  }

  const handleOnChangeFilter = (filter = {}) => {
    setFilterContent((_prev) => ({..._prev, ...filter}))
  }

  useEffect(() => {
    getListOptionContent(filterContent)
  }, [filterContent])

  return (
    <div className='container-xxl w-lg-1100px'>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, values}: FormikProps<ICampaign>) => (
          <Form>
            <div className='row'>
              <div className='col-md-7'>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CAMPAIGN_NAME'})}
                    name='campaign_name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_CAMPAIGN_NAME'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_ADVERTISER'})}
                    name='advertiser_group'
                    placeholder={intl.formatMessage({id: 'FORM_ADVERTISER'})}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={advertiser_group}
                    options={listOptionAdvertiserGroup}
                    component={CustomSelect}
                    handleOnChange={(val: any) => handleOnChangeFilter({advertiser_id: val?.value})}
                    isMulti={false}
                  />
                </div>

                {/* <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'FORM_MEMBER_TYPE' })}
                    name='member_type'
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={{ ...animatedComponents, DropdownIndicator }}
                    defaultValue={member_type}
                    options={MEMBER_TYPE_OPTIONS_LANGUAGE}
                    component={CustomSelect}
                    isMulti={false}
                  />
                </div> */}
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_ACCOUNT_MANAGER'})}
                    name='contact_person_account'
                    placeholder={intl.formatMessage({id: 'FORM_ACCOUNT_MANAGER'})}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={contact_person_account}
                    options={listOptionUser}
                    component={CustomSelect}
                    isMulti={false}
                  />
                </div>

                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_BROADCASTING'})}
                    name='broadcasting_channel'
                    placeholder={intl.formatMessage({id: 'FORM_BROADCASTING'})}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={broadcasting_channel}
                    options={listOptionChannel}
                    component={CustomSelect}
                    isMulti={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CAMPAIGN_TYPE'})}
                    name='campaign_type'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    components={{...animatedComponents, DropdownIndicator}}
                    defaultValue={campaign_type}
                    options={CAMPAIGN_TYPE_OPTIONS_LANGUAGE}
                    component={CustomSelect}
                    handleOnChange={(val: any) => handleOnChangeFilter({campaign_type: val?.value})}
                    isMulti={false}
                  />
                </div>
                <div className='row mb-7'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_CAMPAIGN_SCHEDULE'})}
                    name='campaign_schedule'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    closeMenuOnSelect={true}
                    defaultValue={campaign_schedule}
                    component={CustomInputRangePicker}
                    isRequired={true}
                  />
                </div>
                <div className='row mb-7 mt-12'>
                  <div className='col-12 text-center'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        className='me-6'
                        isLoading={isLoading}
                      />
                    )}
                    <ButtonCancel
                      text={intl.formatMessage({id: 'BUTTON_CANCEL'})}
                      type='button'
                      onClick={() => navigate('/campaign')}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <div className='row'>
                  <div className='col-md-3'>
                    {intl.formatMessage({id: 'FORM_CONTENT_CAMPAIGN'})}
                  </div>
                  <div
                    className='col-md-9  py-6'
                    style={{
                      border: '1px solid var(--bs-gray-200)',
                      borderRadius: '16px',
                      position: 'relative',
                    }}
                  >
                    {loading && (
                      <Loading
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 9,
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    )}
                    <div
                      className=' px-2'
                      style={{maxHeight: '430px', minHeight: '350px', overflowY: 'scroll'}}
                    >
                      {listOptionContent?.length > 0 &&
                        listOptionContent?.map((item) => (
                          <div key={item?.id} className={clsx('d-flex flex-column mb-3 card')}>
                            <div
                              className='text-center py-0'
                              style={{
                                border: '0px solid var(--bs-gray-400)',
                                borderRadius: '12px 12px 0px 0px',
                                overflow: 'hidden',
                                background: 'var(--bs-gray-300)',
                              }}
                            >
                              {item?.type === getKeyByValue(MATERIAL_TYPE, 'IMAGE') && (
                                <img
                                  src={item?.file || ''}
                                  alt='banner'
                                  width='100%'
                                  onClick={() => handleOnChangeContentId(item?.id)}
                                  style={{minHeight: '50px'}}
                                />
                              )}
                              {item?.type === getKeyByValue(MATERIAL_TYPE, 'VIDEO') && (
                                <video width='100%' height='auto' controls src={item?.file || ''}>
                                  {intl.formatMessage({
                                    id: 'VALIDATION_BROWSER_DOES_NOT_SUPPORT_THE_VIDEO_TAG',
                                  })}
                                </video>
                              )}
                            </div>
                            <div
                              className='d-flex cursor-pointer'
                              style={{
                                border: '0px solid var(--bs-gray-400)',
                                background: 'var(--bs-gray-300)',
                                borderRadius: ' 0px 0px 12px 12px',
                              }}
                              onClick={() => handleOnChangeContentId(item?.id)}
                            >
                              <span className='form-check form-check-danger form-check-custom form-check-solid ms-2'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='content_id'
                                  value={item?.id}
                                  checked={contentIdChecked === item?.id}
                                />
                              </span>
                              <div className='text-start p-2'>
                                {intl.formatMessage({id: 'FORM_MATERIAL_NAME'})} : {item?.title}
                                <br />
                                {intl.formatMessage({id: 'FORM_STANDARD'})} :{' '}
                                {extractWidthAndHeightFromURL(item?.file || '', ' * ')}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3  py-5">
                <div className="preview-banner-on-app text-end">
                  <img src={toAbsoluteUrl('/media/phone/iphone.png')} alt="phone" height="400px" />
                </div>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {CampaignFormModal}
