/* eslint-disable react-hooks/exhaustive-deps */
import {Input} from 'antd'
import {useIntl} from 'react-intl'
import {IParamsSearch} from '../../../api/models/_public'
import {KTIcon} from '../../../helpers'
import './style.scss'
import {useEffect, useRef, useState} from 'react'

type Props = {
  handleSearch?: (s: IParamsSearch) => void
  value?: string
  placeholder?: string
}
const Search: React.FC<Props> = ({handleSearch, value = '', placeholder = ''}) => {
  const [currentText, setCurrentText] = useState<any>(value || '')
  const timeoutRef = useRef<any>(null)
  const intl = useIntl()
  const handleChange = (e: any) => {
    setCurrentText(e.target.value)
  }

  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current)
    }
    timeoutRef.current = setTimeout(
      () => handleSearch && handleSearch({keyword: currentText || ''}),
      600
    )
  }, [currentText])

  useEffect(() => {
    setCurrentText(value)
  }, [value])

  return (
    <div className='card-toolbar align-items-end min-w-100 min-w-sm-250px'>
      <div className='card-title d-flex align-items-center'>
        <div className='bullet bg-secondary h-35px w-1px me-5 d-none d-md-block'></div>

        {/* begin::Search */}
        <div className='d-flex position-relative align-items-center position-relative min-w-100 min-w-sm-250px'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-4 start-0 z-index-2' />
          <Input
            allowClear
            className='d-flex form-control bg- form-control-solid ps-14 min-w-100 min-w-sm-250px'
            placeholder={placeholder || intl.formatMessage({id: 'PLACEHOLDER_SEARCH_KEYWORD'})}
            value={currentText}
            onChange={handleChange}
          />
        </div>
        {/* end::Search */}
      </div>
    </div>
  )
}

export {Search}
