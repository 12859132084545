import clsx from 'clsx'
import {FC} from 'react'
import {IParamsSearch} from '../../../api/models/_public'
import {KTIcon} from '../../../helpers'

type Props = {
  handleSearch?: (s: IParamsSearch) => void
  value?: string | number
  placeholder?: string
}
const DropdownLimit: FC<Props> = ({handleSearch, value = ''}) => {
  const handleChange = (num: number = 10) => {
    handleSearch && handleSearch({limit: num})
  }

  return (
    <div>
      <div
        className='d-flex align-items-center btn btn-light btn-active-light-primary'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <span style={{paddingRight: '0.35rem'}}>{value}</span>
        <KTIcon iconName='down' className='fs-5 m-0' />
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
        data-popper-placement='bottom-end'
      >
        {/* <div className="menu-item px-3"><a onClick={() => handleChange(500)} className={clsx('menu-link px-3', { active: Number(value) === 500 })}>500</a></div>
        <div className="menu-item px-3"><a onClick={() => handleChange(100)} className={clsx('menu-link px-3', { active: Number(value) === 100 })}>100</a></div> */}
        <div className='menu-item px-3'>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => handleChange(10)}
            className={clsx('menu-link px-3', {active: Number(value) === 10})}
          >
            10
          </a>
        </div>
        <div className='menu-item px-3'>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => handleChange(15)}
            className={clsx('menu-link px-3', {active: Number(value) === 15})}
          >
            15
          </a>
        </div>
        <div className='menu-item px-3'>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => handleChange(20)}
            className={clsx('menu-link px-3', {active: Number(value) === 20})}
          >
            20
          </a>
        </div>
        <div className='menu-item px-3'>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => handleChange(50)}
            className={clsx('menu-link px-3', {active: Number(value) === 50})}
          >
            50
          </a>
        </div>
      </div>
    </div>
  )
}

export {DropdownLimit}
