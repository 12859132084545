import {Button, Modal} from 'antd'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metacrew/helpers'

const ModalConfirmChangeSwitch = ({
  isModalOpen = false,
  handleOk,
  handleCancel,
  isLoadingUpdateSetting,
  arrayData,
  arrayDefault,
}: any) => {
  const intl = useIntl()

  return (
    <>
      <Modal
        open={isModalOpen}
        confirmLoading={isLoadingUpdateSetting}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className='d-flex justify-content-end'>
            <Button onClick={handleCancel}>{intl.formatMessage({id: 'CANCEL'})}</Button>
            <Button
              onClick={handleOk}
              type='primary'
              style={{
                minWidth: '100px',
                display: 'inline-flex',
                justifyContent: 'center',
              }}
              loading={isLoadingUpdateSetting}
            >
              {intl.formatMessage({id: 'UPDATE'})}
            </Button>
          </div>
        }
      >
        <p>{intl.formatMessage({id: 'CONFIRM-CHANGE-SETTING'})}</p>
        {arrayData && arrayData.length > 0 && (
          <div className='w-100 d-flex justify-content-center my-6 align-items-center'>
            <div className='d-flex gap-2 col-4 flex-wrap'>
              {arrayDefault &&
                arrayDefault.map((item: any) => (
                  <div
                    style={{
                      backgroundColor: '#009ef7',
                      color: '#fff',
                      padding: '1px 7px',
                      height: 24,
                      width: 'auto',
                    }}
                  >
                    {item}
                  </div>
                ))}
            </div>
            <KTIcon iconName='arrow-right' className='mx-4 fs-1' />
            <div className='d-flex gap-2 col-4 flex-wrap'>
              {arrayData &&
                arrayData.map((item: any) => (
                  <div
                    style={{
                      backgroundColor: '#009ef7',
                      color: '#fff',
                      padding: '1px 7px',
                      height: 24,
                      width: 'auto',
                    }}
                  >
                    {item}
                  </div>
                ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ModalConfirmChangeSwitch
