/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik, FormikProps} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ButtonPrimary} from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInput from '../../form/CustomInput/CustomInput'
import {IRole} from '../ModalModel'
import {ModalWrapper} from '../ModalWrapper/ModalWrapper'

type Props = {
  show: boolean
  isLoading?: boolean
  isShowButton?: boolean
  initialValuesDefault?: any
  listOptionPermissions?: any
  handleClose: () => void
  handleSubmit?: (values: IRole, id: string | undefined) => void
}

const RoleFormModal: React.FC<Props> = ({
  show = true,
  isLoading = false,
  isShowButton = false,
  initialValuesDefault,
  listOptionPermissions = [],
  handleClose,
  handleSubmit,
}) => {
  const intl = useIntl()

  const handleCloseModal = () => {
    const valuePermissions: any =
      initialValuesDefault?.permissions?.map((item: any) => Number(item?.id)) || []
    setPermissions(valuePermissions)
    handleClose()
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${intl.formatMessage({id: 'VALIDATION_REQUIRED'})}`),
    description: Yup.string().nullable(),
    // status: Yup.string(),
  })

  const valuePermissions: any =
    initialValuesDefault?.permissions?.map((item: any) => Number(item?.id)) || []

  const [permissions, setPermissions] = useState<number[]>(valuePermissions)

  const fullPermission = useMemo(() => {
    const full = listOptionPermissions?.reduce((arr: number[], item: any) => {
      const ps = item?.permissions?.map((p: any) => p?.id)
      return arr.concat(ps)
    }, [])
    return full || []
  }, [listOptionPermissions])

  const handleChangePermission = (idPermission: number | string) => {
    if (permissions?.includes(Number(idPermission))) {
      setPermissions((_prev) => _prev?.filter((item) => item !== idPermission))
    } else {
      setPermissions((_prev) => [..._prev, Number(idPermission)])
    }
  }

  const handleSelectAllPermission = (e: any) => {
    if (e.target.checked) {
      setPermissions((_prev) => fullPermission)
    } else {
      setPermissions((_prev) => [])
    }
  }

  const initialValues: IRole = {
    name: initialValuesDefault?.name,
    description: initialValuesDefault?.description,
    // status: initialValuesDefault?.id ? String(initialValuesDefault?.status === 1) : 'true',
  }

  const onSubmit = (values: IRole) => {
    handleSubmit && handleSubmit({...values, permissions}, initialValuesDefault?.id)
  }

  useEffect(() => {
    const valuePermissions: any =
      initialValuesDefault?.permissions?.map((item: any) => Number(item?.id)) || []
    setPermissions((_prev) => valuePermissions)
  }, [initialValuesDefault?.permissions])
  useEffect(() => {
    return () => {
      setPermissions([])
    }
  }, [])
  return (
    <ModalWrapper show={show} dialogClassName='h-auto modal-dialog-centered mw-1000px'>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, values}: FormikProps<IRole>) => (
          <Form>
            <div className='container-xxl w-lg-1000px'>
              <div className='modal-header bg-white row z-index-2 position-sticky top-0'>
                <div className='col-10 col-lg-10 col-md-10'>
                  <h1 className='mt-2'>
                    {intl.formatMessage({
                      id: initialValuesDefault?.id ? 'TITLE_UPDATE_ROLE' : 'TITLE_ADD_ROLE',
                    })}
                    {initialValuesDefault?.version_name && (
                      <span className='fs-8 text-primary ms-2'>{`( ${initialValuesDefault?.version_name} )`}</span>
                    )}
                  </h1>
                </div>
                <div className='col-2 col-lg-2 col-md-2 text-end'>
                  {/* begin::Close */}
                  <div className='btn btn-icon btn-sm ms-2' onClick={handleCloseModal}>
                    <svg
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5'
                        stroke='black'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  {/* end::Close */}
                </div>
              </div>
              <div className='modal-body position-relative '>
                {/* begin::Search */}

                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_NAME'})}
                    name='name'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_NAME'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                  />
                </div>
                <div className='row mb-9'>
                  <Field
                    label={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                    name='description'
                    type='text'
                    placeholder={intl.formatMessage({id: 'FORM_DESCRIPTION'})}
                    autoComplete='off'
                    className='form-control form-control-solid'
                    component={CustomInput}
                    isRequired={false}
                  />
                </div>
                <div className='row mb-7'>
                  <div className='fv-row'>
                    {/* begin::Label */}
                    <label className='fs-5 fw-bold form-label mb-2'>
                      {intl.formatMessage({id: 'FORM_ROLE_PERMISSION'})}
                    </label>
                    {/* end::Label */}

                    {/* begin::Table wrapper */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table align-middle table-row-dashed fs-6 gy-5'>
                        {/* begin::Table body */}
                        <tbody className='text-gray-600 fw-semibold'>
                          {/* begin::Table row */}
                          <tr>
                            <td className='text-gray-800'>
                              {intl.formatMessage({id: 'FORM_ADMINISTRATOR_ACCESS'})}
                              <span
                                className='ms-1'
                                data-bs-toggle='tooltip'
                                aria-label='Allows a full access to the system'
                                data-bs-original-title='Allows a full access to the system'
                                data-kt-initialized='1'
                              >
                                <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                </i>
                              </span>{' '}
                            </td>
                            <td>
                              {/* begin::Checkbox */}
                              <label
                                className='form-check form-check-sm form-check-custom form-check-solid me-9'
                                htmlFor='kt_roles_select_all'
                              >
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  checked={
                                    fullPermission?.length === permissions?.length &&
                                    permissions?.length > 0
                                  }
                                  name='select_all'
                                  onChange={handleSelectAllPermission}
                                  id='kt_roles_select_all'
                                />
                                <span className='form-check-label'>
                                  {intl.formatMessage({id: 'FORM_SELECT_ALL'})}
                                </span>
                              </label>
                              {/* end::Checkbox */}
                            </td>
                          </tr>
                          {/* end::Table row */}
                          {/* begin::Table row */}
                          {listOptionPermissions?.map((item: any, index: number) => (
                            <tr key={item?.id ?? index}>
                              {/* begin::Label */}
                              <td className='text-gray-800'>{item?.name || ''}</td>
                              {/* end::Label */}

                              {/* begin::Input group */}
                              <td>
                                {/* begin::Wrapper */}
                                <div className='d-flex justify-content-start'>
                                  {item?.permissions?.map((perss: any, ix: number) => (
                                    <label
                                      className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-10'
                                      key={perss?.id ?? ix}
                                      style={{width: '20%'}}
                                    >
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        onChange={() => handleChangePermission(perss?.id)}
                                        checked={permissions?.includes(Number(perss?.id || 0))}
                                        value={perss?.id}
                                        name='permissions'
                                      />
                                      <span className='form-check-label'>{perss?.name}</span>
                                    </label>
                                  ))}
                                </div>
                                {/* end::Wrapper */}
                              </td>
                              {/* end::Input group */}
                            </tr>
                          ))}
                          {/* end::Table row */}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table wrapper */}
                  </div>
                </div>
              </div>
              <div className='modal-footer py-0  bg-white row z-index-2 position-sticky bottom-0'>
                <div className='row'>
                  <div className='text-center'>
                    {isShowButton && (
                      <ButtonPrimary
                        text={intl.formatMessage({
                          id: initialValuesDefault?.id ? 'BUTTON_UPDATE' : 'BUTTON_CREATE',
                        })}
                        type='submit'
                        isLoading={isLoading}
                        className='mt-8'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export {RoleFormModal}
