/* eslint-disable react-hooks/exhaustive-deps */
import {KTIcon} from '../../../../helpers'
import './style.scss'

type Props = {
  id?: string
  data?: any
  text?: string
  isLoading?: boolean
  disabled?: boolean
  className?: string
  type?: 'submit' | 'button'
  onClickButton?: (id: string) => void
}
const ButtonEdit: React.FC<Props> = ({
  text = '',
  id = '',
  data = {},
  className,
  disabled = false,
  isLoading = false,
  type = 'button',
  onClickButton,
}) => {
  const handleOnClick = (event: any) => {
    event.preventDefault()
    !disabled && onClickButton && onClickButton(data)
  }

  return (
    <div className='button-edit'>
      <button
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        type={type}
        onClick={handleOnClick}
      >
        <KTIcon iconName='pencil' className='fs-3' />
      </button>
    </div>
  )
}

export {ButtonEdit}
