type Option = {
  value: string
  label: string
}

const MEMBER_TYPE: any = {
  advertiser: 'ADVERTISER',
  channel: 'CHANNEL',
}

const MATERIAL_TYPE: any = {
  image: 'IMAGE',
  video: 'VIDEO',
}

const CAMPAIGN_TYPE: any = {
  banner: 'BANNER',
  admob: 'ADMOB',
}

const ADS_TYPE: any = {
  admob: 'ADMOB',
  meta: 'META',
  unity: 'UNITY',
}

const MEMBERSHIP_TYPE: any = {
  advertiser: 'ADVERTISER',
  channel: 'CHANNEL',
  admin: 'ADMIN',
  partner: 'PARTNER',
  client: 'CLIENT',
  user: 'USER',
}

const INQUIRIES_TYPE: any = {
  account: 'ACCOUNT',
  content: 'CONTENT',
  other: 'OTHER',
}

const NOTIFICATION_SCHEDULE_TYPE: any = {
  now: 'NOW',
  custom: 'CUSTOM',
}

const OPTIONS_ADS_TYPE: Option[] = [
  {
    value: 'admob',
    label: 'admob',
  },
  {
    value: 'meta',
    label: 'meta',
  },
  {
    value: 'unity',
    label: 'unity',
  },
]
const MEMBER_TYPE_OPTIONS = Object.entries(MEMBER_TYPE).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const MATERIAL_TYPE_OPTIONS = Object.entries(MATERIAL_TYPE).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const CAMPAIGN_TYPE_OPTIONS = Object.entries(CAMPAIGN_TYPE).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const MEMBERSHIP_TYPE_OPTIONS = Object.entries(MEMBERSHIP_TYPE).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const ADS_TYPE_OPTIONS = Object.entries(ADS_TYPE).map(([key, value]) => ({
  label: key ?? '',
  value: key ?? '',
})) as Option[]

const INQUIRIES_TYPE_OPTIONS = Object.entries(INQUIRIES_TYPE).map(([key, value]) => ({
  label: value ?? '',
  value: key ?? '',
})) as Option[]

const TYPE_STOCK_OPTIONS: Option[] = [
  {label: 'kosdaq', value: 'kosdaq'},
  {label: 'kospi', value: 'kospi'},
]

const NOTIFICATION_SCHEDULE_TYPE_OPTIONS = Object.entries(NOTIFICATION_SCHEDULE_TYPE).map(
  ([key, value]) => ({label: value ?? '', value: key ?? ''})
) as Option[]

export {
  MEMBER_TYPE,
  MEMBER_TYPE_OPTIONS,
  MATERIAL_TYPE,
  MATERIAL_TYPE_OPTIONS,
  CAMPAIGN_TYPE,
  CAMPAIGN_TYPE_OPTIONS,
  MEMBERSHIP_TYPE,
  MEMBERSHIP_TYPE_OPTIONS,
  INQUIRIES_TYPE,
  INQUIRIES_TYPE_OPTIONS,
  TYPE_STOCK_OPTIONS,
  NOTIFICATION_SCHEDULE_TYPE,
  NOTIFICATION_SCHEDULE_TYPE_OPTIONS,
  ADS_TYPE_OPTIONS,
  OPTIONS_ADS_TYPE,
}
