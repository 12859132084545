/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              style={{maxWidth: '100%', maxHeight: 50, objectFit: 'cover'}}
              alt='Logo'
              src={(currentUser?.avatar as any) || toAbsoluteUrl('/media/avatars/blank.png')}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name || (currentUser as any)?.user?.name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {intl.formatMessage({id: String(currentUser?.roles?.name || '').toUpperCase()})}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'TEXT_LOGOUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
