/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: any) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  notificationCount: number
  updateNotificationCount: (count: number) => void
  triggerLoadListNotification?: number
  updateTriggerLoadListNotification?: (count: number) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: any) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  notificationCount: 0,
  updateNotificationCount: (count: number) => {},
  triggerLoadListNotification: 0,
  updateTriggerLoadListNotification: (count: number) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<any>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [notificationCount, setNotificationCount] = useState(0)
  const [triggerLoadListNotification, setTriggerLoadListNotification] = useState(0)

  const updateNotificationCount = (count: number) => {
    setNotificationCount(count)
  }
  const updateTriggerLoadListNotification = (count: number) => {
    setTriggerLoadListNotification((prev: any) => prev + 1)
  }
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    notificationCount,
    updateNotificationCount,
    triggerLoadListNotification,
    updateTriggerLoadListNotification,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()

  useEffect(() => {
    if (children) {
      setPageTitle(children)
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDataProvider, PageDescription, PageTitle, usePageData}
