import {Button, Divider, Input, Space, Switch, Tabs, TabsProps} from 'antd'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import LoadingAdSetting from '../../../../../../app/modules/setting-management/components/LoadingAdSetting'
import ModalConfirmChangeSwitch from '../../../../../../app/modules/setting-management/components/ModalConfirmChangeSwitch'
import {OPTIONS_ADS_TYPE} from '../../../../../constants/type'
import useActionMedia from '../../../../../helpers/hooks/partner/useActionMedia'
import useListAdMedia from '../../../../../helpers/hooks/partner/useListAdMedia'
import {useLang} from '../../../../../i18n/MetaCrewi18n'
import LoadingAdsType from './LoadingAdsType'

const AdsTab = ({initialValuesDefault}: any) => {
  const intl = useIntl()
  // Get full permissions of account login
  // eslint-disable-next-line
  const lang = useLang()
  const initDefaultForm = {
    type: OPTIONS_ADS_TYPE[0]?.value,
    status: 1,
    partner_id: initialValuesDefault?.id,
    enable: 1,
  }
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
  const [isLoadingUpdateGlobal, setIsLoadingUpdateGlobal] = useState<boolean>(false)
  const [isLoadingUpdateItem, setIsLoadingUpdateItem] = useState<boolean>(false)
  const [isShowModalConfirmSwitch, setIsShowModalConfirmSwitch] = useState<boolean>(false)
  const [isShowModalConfirmInput, setIsShowModalConfirmInput] = useState<boolean>(false)
  const [showData, setShowData] = useState<any>()
  const [settingFocus, setSettingFocus] = useState<any>()
  const [formData, setFormData] = useState<any>(initDefaultForm)
  // eslint-disable-next-line
  const {data, isFetching, refetch} = useListAdMedia({id: initialValuesDefault?.id})
  const {updateSettingAdsMedia, updateSettingItemAdsMedia} = useActionMedia()
  const isEnableInput = (id: number) => {
    return (
      (showData &&
        showData.items &&
        showData.items?.find((item: any) => item.id === id)?.content) !==
      (data && data.items && data.items?.find((item: any) => item.id === id)?.content)
    )
  }
  const initDefaultFormData = {
    type: data?.type || OPTIONS_ADS_TYPE[0]?.value,
    partner_id: initialValuesDefault?.id,
    enable: data?.enable_ads || 1,
    app_id: data?.app_id,
  }

  const updateData = (value: string, index: number, id: number) => {
    setSettingFocus(id)
    setShowData((prevState: any) => {
      return {
        ...prevState,
        items:
          data &&
          data.items.map((item: any, i: number) => {
            if (id === item.id) {
              return {
                ...item,
                content: value,
              }
            }
            return item
          }),
      }
    })
  }

  const handleUpdateEnableSetting = async (id: number) => {
    const params = {
      // eslint-disable-next-line
      enable: showData?.items?.find((item: any) => item.id === id)?.enable == '1' ? '0' : '1',
      partner_id: initialValuesDefault?.id,
      content: showData?.items?.find((item: any) => item.id === id)?.content || null,
    }
    setIsLoadingUpdate(true)
    const {code, error} = await updateSettingItemAdsMedia(params, id)
    if (code === 1) {
      setIsLoadingUpdate(false)
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
      refetch()
      setIsShowModalConfirmSwitch(false)
    } else {
      setIsLoadingUpdate(false)
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const handleUpdateContentSetting = async (id: number) => {
    setIsLoadingUpdateItem(true)
    const {code, error} = await updateSettingItemAdsMedia(
      {
        content: showData?.items?.find((item: any) => item.id === id)?.content || null,
        enable: showData?.items?.find((item: any) => item.id === id)?.enable || 0,
        partner_id: initialValuesDefault?.id,
      },
      id
    )
    if (code === 1) {
      setIsLoadingUpdateItem(false)
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
      refetch()
      setIsShowModalConfirmInput(false)
    } else {
      setIsLoadingUpdateItem(false)
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const handleUpdateGlobalSetting = async () => {
    setIsLoadingUpdateGlobal(true)
    const {code, error} = await updateSettingAdsMedia({
      ...formData,
      // eslint-disable-next-line
      enable: showData.enable_ads == '1' ? 0 : 1,
    })
    if (code === 1) {
      setIsLoadingUpdateGlobal(false)
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
      refetch()
      setIsShowModalConfirmSwitch(false)
      setSettingFocus(null)
    } else {
      setIsLoadingUpdateGlobal(false)
      setSettingFocus(null)
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const settingAndroid =
    showData && showData.items?.filter((item: any) => item.os_type === 'android')

  const settingIos = showData && showData.items?.filter((item: any) => item.os_type === 'ios')

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className='fs-5 fw-bold'>{intl.formatMessage({id: 'ANDROID'})}</div>,
      children: (
        <div className='card'>
          <div className='card-body p-0'>
            {settingAndroid &&
              settingAndroid.map((item: any, index: number) => (
                <div key={index} className='card-body pt-0 px-0 px-md-8'>
                  <div className='tab-content'>
                    <div className='form-group d-flex flex-stack flex-column flex-sm-row'>
                      <div className='d-flex flex-column  w-250px'>
                        <div className='fs-6 fw-bold text-gray'>
                          {lang === 'en' ? item.label?.en : item.label?.kr}
                        </div>
                      </div>
                      <div className='d-flex ms-auto justify-content-sm-end mt-5 mt-sm-0'>
                        <div className='d-flex justify-content-end align-items-center'>
                          <Switch
                            className='me-6'
                            // eslint-disable-next-line eqeqeq
                            checked={item.enable == '1' ? true : false}
                            onChange={() => {
                              setSettingFocus(item.id)
                              setIsShowModalConfirmSwitch(true)
                            }}
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          <Space.Compact style={{width: '100%'}}>
                            <Input
                              value={item.content}
                              onChange={(e) => {
                                updateData(e.target.value, index, item.id)
                              }}
                            />
                            <Button
                              onClick={() => setIsShowModalConfirmInput(true)}
                              type='primary'
                              disabled={!isEnableInput(item.id)}
                            >
                              {intl.formatMessage({id: 'UPDATE'})}
                            </Button>
                          </Space.Compact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {isFetching && !showData ? <LoadingAdSetting /> : null}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: <div className='fs-5 fw-bold'>{intl.formatMessage({id: 'IOS'})}</div>,
      children: (
        <div className='card'>
          <div className='card-body p-0'>
            {settingIos &&
              settingIos.map((item: any, index: number) => (
                <div key={index} className='card-body pt-0 px-0 px-md-8 '>
                  <div className='tab-content'>
                    <div className='form-group d-flex flex-stack flex-column flex-sm-row'>
                      <div className='d-flex flex-column w-250px'>
                        <div className='fs-6 fw-bold text-gray'>
                          {lang === 'en' ? item.label?.en : item.label?.kr}
                        </div>
                      </div>
                      <div className='d-flex col-sm-8 col-12 justify-content-sm-end mt-5 mt-sm-0'>
                        <div className='d-flex justify-content-end align-items-center'>
                          <Switch
                            className='me-6'
                            // eslint-disable-next-line eqeqeq
                            checked={item.enable == '1' ? true : false}
                            onChange={() => {
                              setSettingFocus(item.id)
                              setIsShowModalConfirmSwitch(true)
                            }}
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          <Space.Compact style={{width: '100%'}}>
                            <Input
                              value={item.content}
                              onChange={(e) => {
                                updateData(e.target.value, index, item.id)
                              }}
                            />
                            <Button
                              onClick={() => setIsShowModalConfirmInput(true)}
                              type='primary'
                              disabled={!isEnableInput(item?.id)}
                            >
                              {intl.formatMessage({id: 'UPDATE'})}
                            </Button>
                          </Space.Compact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {isFetching && !showData ? <LoadingAdSetting /> : null}
          </div>
        </div>
      ),
    },
  ]

  const handleChangeAdsType = (value: any) => {
    setFormData((prev: any) => {
      return {...prev, type: value}
    })
  }

  const handleUpdateAppId = (type: any, value: any) => {
    const app_id = {...formData.app_id}
    if (type === 'ios') {
      app_id.ios = value
    }
    if (type === 'aos') {
      app_id.aos = value
    }
    setFormData((prev: any) => {
      return {...prev, app_id: app_id}
    })
  }

  const handleUpdate = async () => {
    setIsLoadingUpdate(true)
    const {code, error} = await updateSettingAdsMedia(formData)
    if (code === 1) {
      toast(`${intl.formatMessage({id: 'NOTIFICATION_ACTION_SUCCESSFULLY'})}`)
      refetch()
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
    setIsLoadingUpdate(false)
  }

  useEffect(() => {
    setShowData(data)
    setFormData(initDefaultFormData)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    return () => {
      setShowData(undefined)
      setFormData(undefined)
    }
  }, [])

  return (
    <>
      {/* begin::Row  */}
      <div className='card min-h-650px'>
        <div className='card-body px-0 pt-4 pb-0 position-relative'>
          <div className='form-group w-100 d-flex flex-column align-items-start position-absolute'>
            <div className='d-flex w-100 px-8 justify-content-between'>
              <div className='fs-3 fw-bold text-dark my-auto '>
                {intl.formatMessage({id: 'GLOBAL_ADS_MEDIA_SETTINGS'})}{' '}
                {initialValuesDefault?.name && (
                  <span className='fs-8 text-primary'>{`( ${initialValuesDefault?.name} )`}</span>
                )}
              </div>
              <div>
                <div className='d-flex me-4'>
                  <div className='fs-6 fw-bold text-muted my-auto'>
                    {intl.formatMessage({id: 'ENABLE_ADS'})}
                  </div>
                </div>
                <div className='d-flex mt-2'>
                  <div className='d-flex align-items-center'>
                    <Switch
                      className='me-6'
                      // eslint-disable-next-line eqeqeq
                      checked={showData && showData.enable_ads}
                      onChange={() => {
                        setSettingFocus('global_setting')
                        setIsShowModalConfirmSwitch(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-8 align-items-center filter-header mt-4 col-12 gap-5 d-flex align-items-end justify-content-start w-100'>
              {!isFetching && (
                <>
                  <div className='d-flex'>
                    <div className='d-flex me-4'>
                      <div className='fs-6 fw-bold my-auto'>
                        {intl.formatMessage({id: 'ADS_TYPE'})}
                      </div>
                    </div>
                    <select
                      value={formData?.type}
                      onChange={(e) => handleChangeAdsType(e?.target?.value)}
                      className={`form-select w-auto col-4 form-select-solid select2-hidden-accessible `}
                    >
                      {OPTIONS_ADS_TYPE?.map((option) => (
                        <option value={option?.value} key={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {formData?.type === 'admob' && (
                    <div className='col-4 d-flex gap-2'>
                      <Input
                        allowClear
                        className='d-flex media-form-input form-control-solid min-w-100 min-w-sm-250px'
                        placeholder={intl.formatMessage({id: 'APP_ID_IOS'})}
                        value={formData?.app_id?.ios}
                        onChange={(e) => handleUpdateAppId('ios', e.target.value)}
                      />
                      <Input
                        allowClear
                        className='d-flex media-form-input form-control-solid min-w-100 min-w-sm-250px'
                        placeholder={intl.formatMessage({id: 'APP_ID_ANDROID'})}
                        value={formData?.app_id?.aos}
                        onChange={(e) => handleUpdateAppId('aos', e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}
              {isFetching && <LoadingAdsType />}

              <Button
                onClick={handleUpdate}
                className='ms-auto'
                style={{height: 36, borderRadius: 8}}
                type='primary'
                disabled={
                  (formData?.type === data?.type &&
                    formData?.app_id?.ios === data?.app_id?.ios &&
                    formData?.app_id?.aos === data?.app_id?.aos) ||
                  isLoadingUpdate
                }
                loading={isLoadingUpdate}
              >
                {intl.formatMessage({id: 'UPDATE'})}
              </Button>
            </div>

            <Divider className='my-8' />
            <div className='ant-tabs-custom'>
              <Tabs defaultActiveKey='1' tabPosition='left' items={items} className='px-4' />
            </div>
          </div>

          {/* {isFetching && !showData ? <LoadingAdSetting /> : null} */}
        </div>
      </div>
      <ModalConfirmChangeSwitch
        isLoadingUpdateSetting={isLoadingUpdate || isLoadingUpdateGlobal}
        isModalOpen={isShowModalConfirmSwitch}
        handleOk={() => {
          settingFocus === 'global_setting'
            ? handleUpdateGlobalSetting()
            : handleUpdateEnableSetting(settingFocus)
        }}
        handleCancel={() => setIsShowModalConfirmSwitch(false)}
      />
      <ModalConfirmChangeSwitch
        isLoadingUpdateSetting={isLoadingUpdate || isLoadingUpdateItem}
        isModalOpen={isShowModalConfirmInput}
        handleOk={() => {
          handleUpdateContentSetting(settingFocus)
        }}
        handleCancel={() => setIsShowModalConfirmInput(false)}
      />
      {/* end::Row */}
    </>
  )
}

export {AdsTab}
