import {Outlet} from 'react-router-dom'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan, useThemeMode} from '../partials'
import {Content} from './components/content'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Sidebar} from './components/sidebar'
import {ToolbarWrapper} from './components/toolbar'

import {ConfigProvider, theme} from 'antd'
import {ThemeModeComponent} from '../assets/ts/layout'
// import { useThemeMode } from "_metronic/partials";
// import { ThemeModeComponent } from "_metronic/assets/ts/layout";
const {darkAlgorithm, defaultAlgorithm} = theme

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const LayoutContent = () => {
  const {mode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode

  return (
    <ConfigProvider
      theme={{
        // eslint-disable-next-line
        algorithm: calculatedMode == 'dark' ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </ConfigProvider>
  )
}

export {LayoutContent}
